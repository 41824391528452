import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Home from "../Home";
import * as actions from "../../actions";
import { IMAGE_URL } from "../../config/configs";
import $ from "jquery";
//import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class PropertyDetails extends Component {
  state = {
    isLoader: false,
    propertyDetails: {},
    property_id: "",
    category_slug: "",
    photoIndex: 0,
    isOpen: false
  };  

  componentDidMount() {
    let property_id = this.props.match.params.property_id;
    let category_slug = this.props.match.params.c_slug;
    this.setState({ property_id: property_id, category_slug:category_slug }, () => this.getDetails());
  }

  getDetails = () => {
    let { property_id, category_slug } = this.state;
    if (property_id) {
      let url = "admin/getPropertyDetails";
      let method = "post";
      var body = { property_id };
      var token = localStorage.getItem("token");
      this.setState({ isLoader: true });
      this.props.commonApiCall(
        url,
        method,
        body,
        token,
        null,
        this.props,
        response => {
          let responsedata = response.data;
          let data = response.data.data;
          this.setState({ isLoader: false });
          if (responsedata.status == 0) {
            this.setState({ propertyDetails: [] });
            toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
            this.props.history.push('/properties/'+category_slug);//category_slug
          } else {
            this.setState({
              propertyDetails: data
            });
          }
        }
      );
    } else {
      return false;
    }
  };

  // handleChange = event => {
  //   let { name, value } = event.target;
  //   this.setState({ [name]: value });
  //   if (value) {
  //     this.setState({
  //       errors: Object.assign(this.state.errors, { [name]: "" })
  //     });
  //   }
  // };

  renderImages = () => {
    let {
      propertyDetails
    } = this.state;
    let images = propertyDetails.image;
    let photoIndex = -1;
    return images.map((imageSrc, i )=> {
      photoIndex++;
      const privateKey = i;

      let filesplit = imageSrc.image.split('.');
      let thumbimage = filesplit[0]+'_thumb.'+filesplit[1];
      if(i > 4 ) {
        return (
          <figure className="property last-image" key={i}>
            <img src={IMAGE_URL+thumbimage} alt='Property Image' width='400' height='300' onError={this.addDefaultSrcForProperty} onClick={()=> this.setState({ photoIndex: privateKey, isOpen: true })}/> 
            <div className='overlay-wrap' onClick={() => this.setState({ isOpen: true })}>
              <span>+ View More</span>
            </div>
          </figure>
        );
        return true;
      } else {
        return (
          <figure className="property" key={i}>
            <img src={IMAGE_URL+thumbimage} alt='Property Image' width='400' height='300' onError={this.addDefaultSrcForProperty} onClick={()=> this.setState({ photoIndex: privateKey, isOpen: true })}/> 
          </figure>
        );
      }
    })
  }

  ratingsClassname = (rating) => {

    const prefix = 'fa fa-star';
    let classname = (rating > 0 && rating <= 0.5) ? "-half-full checked" : "-o";
    classname = (rating > 0.5 && rating <= 1) ? " checked" : "-o";
    classname = (rating > 1 && rating <= 1.5) ? "-half-full checked" : "-o";
    classname = (rating > 1.5 && rating <= 2) ? " checked" : "-o";
    classname = (rating > 2 && rating <= 2.5) ? "-half-full checked" : "-o";
    classname = (rating > 2.5 && rating <= 3) ? " checked" : "-o";
    classname = (rating > 3 && rating <= 3.5) ? "-half-full checked" : "-o";
    classname = (rating > 3.5 && rating <= 4) ? " checked" : "-o";
    classname = (rating > 4 && rating <= 4.5) ? "-half-full checked" : "-o";
    classname = (rating > 4.5 && rating <= 5) ? " checked" : "-o";

    return prefix+classname;
  }

  addDefaultSrcForUser(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  addDefaultSrcForProperty(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render() {
    let {
      propertyDetails
    } = this.state;
    // let {
    //   item_info,
    //   owner_info,
    //   customer_info,
    //   agent_info
    // } = propertyDetails;
    // let {
    //   item_name,
    //   address,
    //   description,
    //   item_price,
    //   images: item_images,
    //   attributes
    // } = item_info || {};
    const { photoIndex, isOpen } = this.state;
    let owner_info = { owner_name: propertyDetails.owner_name, owner_email: propertyDetails.owner_name, owner_image: propertyDetails.owner_image };
    let images = propertyDetails.image;
    return (
      <Home>
        <div className='row'>
          <div className='col-md-12'>
              <div className='page-header border-bottom'>
                <div className='row align-items-center'>
                  <div className='col-md-8'>
                    <h3>{propertyDetails.property_name}</h3>
                    <ul className='breadcrumb '>
                    <li className='breadcrumb-item' onClick={()=>this.props.history.push('/dashboard')}>Home</li>
                    <li className='breadcrumb-item' onClick={()=>this.props.history.push('/properties/'+propertyDetails.category_slug)}>{propertyDetails.category_name}</li>
                    <li className='breadcrumb-item active'>{propertyDetails.category_name} Details</li>
                    </ul>
                  </div>
                  <div className='col-md-4 text-right'>
                  { propertyDetails.status != '4' ? <a href='Javascript:;' onClick={()=>this.props.history.push('/edit/'+propertyDetails.category_slug+'/'+propertyDetails.id)} className='btn btn-primary btn-image'><img src='/assets/images/edit.svg' alt='Edit' width='14' height='14' /> Edit</a> : null }
                  </div>
                </div>
                  
              </div>
          </div>
        </div>

        <div className='card transparent no-shadow'>
            <div className='card-body'>
                <div className='white-bg border br-4'>
                    <div className="mdb-lightbox">
                        <div className='card-body border-bottom pb-30 pt-30'>
                            <div className='available-propertyheader pb-2 d-flex flex-wrap'>
                                <div className='property-details'>
                                    <h4>{propertyDetails.property_name} <img src='/assets/images/verify.png' className='ml-2' alt='Verify' width='30' height='30' /></h4>
                                    <p><img src='/assets/images/map.png' className='mr-2' alt='Verify' width='30' height='30' />{propertyDetails.property_address}</p>
                                </div>
                                <div className='property-label text-right'>
                                    <span className='badge badge-primary badge-pill'>Available for {propertyDetails.available_for}</span>
                                </div>
                            </div>
                            <div className='property-gallery mb-2'>
                            { propertyDetails.image ? this.renderImages() : '' }
                            </div>
                            <div className='property-contentblock'>
                                <p>{propertyDetails.property_description}</p>
                            </div>
                        </div>
                        <div className='card-body pt-30'>
                            <div className='property-info row'>
                                    <div className='col-xl-4'>
                                        <h4 className='info-title'>Owner Information</h4>
                                        <div className='owner-info d-flex flex-wrap align-items-center'>
                                            <div className='owner-image'>
                                                <img src={ propertyDetails.owner_image ? IMAGE_URL + propertyDetails.owner_image : "assets/images/no-image-user.png" } alt="Owner Image" onError={this.addDefaultSrcForUser} className='img-fluid' alt={propertyDetails.owner_name} width='80' height='80' />
                                            </div>
                                            <div className='owner-content'>
                                                <h6>{propertyDetails.owner_name}</h6>
                                                <a href={'mailto:'+propertyDetails.owner_email} className='owner-id'>{propertyDetails.owner_email}</a>
                                                <a href={'tel:'+propertyDetails.owner_phone_number}>{propertyDetails.owner_phone_number}</a>
                                            </div>
                                        </div>
                                        <div className='owner-info'>
                                            <h4 className='info-title'>SeaTime Ratings</h4>
                                            <div className='star-rating'>
                                                <p className='rating-count'>{propertyDetails.rating}</p>
                                                <div className='rating-block'>
                                                    <span className={ this.ratingsClassname(propertyDetails.rating) }></span>
                                                    <span className={ this.ratingsClassname(propertyDetails.rating) }></span>
                                                    <span className={ this.ratingsClassname(propertyDetails.rating) }></span>
                                                    <span className={ this.ratingsClassname(propertyDetails.rating) }></span>
                                                    <span className={ this.ratingsClassname(propertyDetails.rating) }></span>
                                                </div>
                                                <p className='rating-count'>{'('+propertyDetails.reviews+' Reviews)'}</p>
                                            </div>
                                        </div>
                                        <div className='owner-info'>
                                            <h4 className='info-title'>Price</h4>
                                            <div className='price-block'>
                                            <p><span>${propertyDetails.price}</span> {(propertyDetails.price_unit ? 'Per '+propertyDetails.price_unit : 'Total Price')}</p>
                                            </div>
                                            {/* <p className='note'>Documentation charges excluded. *</p> */}
                                        </div>
                                    </div>
                                    {/* <div className='col-md-4'>
                                        
                                        <div className='owner-info'>
                                            <h4 className='info-title'>Area</h4>
                                            <p>Super Built up area 950 Sq.Ft.</p>
                                        </div>
                                    </div> */}
                                    <div className='col-xl-8 row'>
                                    {propertyDetails.attributes && propertyDetails.attributes.map((attribute, key) => (
                                      <div className='col-md-6' key={key}>
                                        { attribute.value ? 
                                          <div>
                                            {(attribute.type == 'textbox' || attribute.type == 'dropdown') && (
                                              <div className='row owner-info'>
                                                <h4 className='info-title'>{attribute.title}</h4>
                                                {attribute.value.map((value, key1) => (
                                                  <div className='col-md-12' key={key1}>
                                                    <div className=" mb-10 text-left">
                                                      <label className='mb-0'>{value.title}</label>
                                                      <p className='text-dark'>{value.value}</p>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            )}

                                            {(attribute.type == 'checkbox' || attribute.type == 'radio') && (
                                              <div className='row owner-info'>
                                                <h4 className='info-title'>{attribute.title}</h4>
                                                {attribute.value.map((value, key2) => {
                                                  let atrval = value.value.split(',')
                                                  return <div className='col-md-12' key={key2}>
                                                    {atrval.map((val, key3) => {
                                                      return <div className="custom-control custom-checkbox mb-10" key={key3}>
                                                        <input type="checkbox" className="custom-control-input" disabled defaultChecked id={'customCheck'+key+'-'+key2}/>
                                                        <label className="custom-control-label" htmlFor={'customCheck'+key+'-'+key2}>{val}</label>
                                                      </div>
                                                    })}
                                                  </div>
                                                })}
                                              </div>
                                            )}

                                            {attribute.type == 'image' && (
                                              <div className='row owner-info'>
                                                <h4 className='info-title'>{attribute.title}</h4>
                                                {attribute.value.map((value, key4) => {
                                                  let atrval = value.value.split(',')
                                                  return <div className='' key={key4}>
                                                  {atrval.map((val, key5) => {
                                                    return <div className="menu-image mb-10" key={key5}>
                                                      <img src={val ? IMAGE_URL + val
                                                                : "assets/images/no-image-user.png"
                                                              }
                                                          alt={attribute.title+' Images'}
                                                          onError={this.addDefaultSrcForUser} className='img-fluid' width='80' height='80' />
                                                      {/* <label className='col-md-6 align-self-center text-left'>{value.title}</label>
                                                      <p>{value.value}</p> */}
                                                    </div>
                                                    })}
                                                  </div>
                                                })}
                                              </div>
                                            )}
                                          </div> : null }
                                      </div>
                                    ))}
                                    </div>
                                    {/*<div className='col-md-4'>
                                        <h4 className='info-title'>Specifications</h4>
                                        <div className='row owner-info'>
                                            <div className='col-md-6'>
                                                <div className="custom-control custom-checkbox mb-10">
                                                    <input type="checkbox" className="custom-control-input" checked id="customCheck1"/>
                                                    <label className="custom-control-label" htmlFor="customCheck1">2 Bedrooms</label>
                                                </div>
                                            </div>
                                              <div className='col-md-12'>
                                                <div className="custom-control custom-radio mb-10">
                                                    <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input"/>
                                                    <label className="custom-control-label" htmlFor="customRadio1">Toggle this custom radio</label>
                                                </div>
                                                <div className="custom-control custom-radio mb-10">
                                                    <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input"/>
                                                    <label className="custom-control-label" htmlFor="customRadio2">Toggle this custom radio</label>
                                                </div>
                                            </div> */}
                                            {/* <div className='col-md-12'>
                                                <div className='row form-group'>
                                                    <label className='col-md-6 align-self-center text-left'>Label</label>
                                                    <input type='text' className='form-control col-md-6' />
                                                </div>
                                            </div> 
                                        </div>
                                    </div>*/}
                                </div>
                        </div>
                    </div>
                                                
                    {isOpen && (
                      <Lightbox
                        mainSrc={IMAGE_URL+images[photoIndex].image}
                        nextSrc={IMAGE_URL+images[(photoIndex + 1) % images.length].image}
                        prevSrc={IMAGE_URL+images[(photoIndex + images.length - 1) % images.length].image}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % images.length
                          })
                        }
                      />
                    )}
                
                </div>
            </div>
        </div>
        {this.state.isLoader == true ? <div className='loader-block'>
          <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
    );
  }
}

const mapStateToProps = state => ({
  permissionsList: state.admindata.admindata.rolepermissions
});
export default connect(
  mapStateToProps,
  actions
)(PropertyDetails);
