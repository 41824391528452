import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import AdminLogin from './pages/AdminLogin';
import SignUp from './pages/SignUp';
import ResetPasswordMail from './pages/ResetPasswordMail';

import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';

import ResetPassword from './pages/ResetPassword';
import AdminProfile from './pages/AdminProfile';
import Dashboard from './pages/Dashboard';

import Users from './pages/UserManagement/Users';
import AddUser from './pages/UserManagement/AddUser';
import EditUser from './pages/UserManagement/EditUser';

import Owners from './pages/UserManagement/Owners';
import UpsertOwner from './pages/UserManagement/UpsertOwner';
//import EditOwner from './pages/UserManagement/EditOwner';
// import AddUser from './pages/UserManagement/AddAdminUser';

import categoryList from './pages/categoryManagement/categoryList';
import categoryAddedit from './pages/categoryManagement/categoryAddedit';


import Properties from './pages/PropertyManagement/Properties';
import PropertyDetails from './pages/PropertyManagement/PropertyDetails';
import AddProperty from './pages/PropertyManagement/AddProperty';

import LeadsListing from './pages/LeadManagement/LeadsListing';
import LeadDetails from './pages/LeadManagement/LeadDetails';
import PageNotFound from './PageNotFound';

//import HouseInfo from './pages/PropertyManagement/HouseInfo';
import DetailsSuccess from './pages/PropertyManagement/DetailsSuccess';

import CmsView from './pages/CmsManagement/EditCms.jsx';
import CmsListing from './pages/CmsManagement/CmsListing';
import EmailManagement from './pages/EmailContentManagement/EmailManagement';
import EditEmail from './pages/EmailContentManagement/EditEmail';

import DefaultMeta from './pages/Settings/DefaultMeta';

// import HomeSection from './pages/HomeSectionManagement/HomeSection';
// import EditHomeSection from './pages/HomeSectionManagement/EditHomeSection';
// import DiscountSettings from './pages/Settings/DiscountSettings';
// import NotificationSettings from './pages/Settings/NotificationSettings';
// import PaymentGateWay from './pages/Settings/PaymentGateway';
// import AccessManagement from './pages/RoleAccessManagement/AccessManagement';
// import EditPermissions from './pages/RoleAccessManagement/EditPermissions';
// import ReviewManagement from './pages/ReviewManagement/ReviewManagement';
// import EditReview from './pages/ReviewManagement/EditReview';

// import EmailPreview from './pages/EmailContentManagement/EmailPreview';
// import AdminEmails from './pages/EmailContentManagement/AdminEmails';
// import SMTPsetting from './pages/EmailContentManagement/SMTPsetting';
// import ShippingMethods from './pages/MasterManagement/ShippingMethods.jsx';
// import AddShipping from './pages/MasterManagement/AddShipping.jsx';
// import Suppleirs from './pages/MasterManagement/Suppliers.jsx';
// import AddSuppliers from './pages/MasterManagement/AddSuppliers';
// import States from './pages/MasterManagement/States.jsx';
// import AddStates from './pages/MasterManagement/AddStates';
// import Brand from './pages/MasterManagement/Brand.jsx';
// import AddBrand from './pages/MasterManagement/AddBrand';

import { toast } from 'react-toastify';



toast.configure({
  autoClose: 1000,
  draggable: false,
  //etc you get the idea
});
class App extends Component {
  constructor(props){
    super(props)
    this.state= {
    }
  }
  componentDidMount(){
  }
  Authorization = () => {
    return localStorage.getItem('token') ? true : false
  }
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
         this.Authorization()  ?  
        <Component {...props} /> 
         : <Redirect to='/' />
      )} />
    );

    const LoginCheckRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        this.Authorization()
          ? <Redirect to='/dashboard' />
          : <Component {...props} />
      )} />
    );
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <LoginCheckRoute exact path='/' component={AdminLogin} />
              <LoginCheckRoute exact path='/login' component={AdminLogin} />
              <LoginCheckRoute exact path='/signup' component={SignUp} />
              <LoginCheckRoute exact path="/forgotpassword" component={ForgotPassword} />
              <LoginCheckRoute exact path='/resetpasswordmail' component={ResetPasswordMail} />

              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/changepassword" component={ChangePassword} />
              <PrivateRoute exact path="/editprofile" component={AdminProfile} />
              <PrivateRoute exact path="/resetpassword" component={ResetPassword} />

              <PrivateRoute exact path="/owners" component={Owners} />
              <PrivateRoute exact path="/owners/add" component={UpsertOwner} />
              <PrivateRoute exact path="/owners/edit/:id" component={UpsertOwner} />

              <PrivateRoute exact path="/users/:utype" component={Users} />
              <PrivateRoute exact path="/users/:utype/add" component={AddUser} />
              <PrivateRoute exact path="/users/:utype/edit/:id" component={EditUser} />

              <PrivateRoute exact path="/properties/:c_slug" component={Properties} />
              <PrivateRoute exact path="/properties/:c_slug/:property_id" component={PropertyDetails} />
              <PrivateRoute exact path="/add/:c_slug" component={AddProperty} />
              <PrivateRoute exact path="/edit/:c_slug/:p_id" component={AddProperty} />
            
              <PrivateRoute exact path="/cmspages" component={CmsListing} />
              <PrivateRoute  exact  path="/cmspages/:id"  component={CmsView}  />

              <PrivateRoute exact path="/leads" component={LeadsListing} />
              <PrivateRoute exact path="/leads/:lead_id" component={LeadDetails} />

              <PrivateRoute exact path="/emailcontentlist" component={EmailManagement} />
              <PrivateRoute exact path="/editemailcontent/:id" component={EditEmail} />
              <PrivateRoute exact path="/setting" component={DefaultMeta} />

              <PrivateRoute exact path="/category" component={categoryList} />
              <PrivateRoute exact path="/category/add" component={categoryAddedit} />
              <PrivateRoute exact path="/category/edit/:id" component={categoryAddedit} />
              
              {/* <PrivateRoute exact path="/homesection" component={HomeSection} />
              <PrivateRoute exact path="/edithomesection" component={EditHomeSection} />
              <PrivateRoute exact path="/reviewlist" component={ReviewManagement} />
              <PrivateRoute exact path="/editreview" component={EditReview} />
              <PrivateRoute exact path="/accessmanagementlist" component={AccessManagement} />
              <PrivateRoute exact path="/editrole" component={EditPermissions} />
              <PrivateRoute exact path="/discountsettings" component={DiscountSettings} />
              <PrivateRoute exact path="/notifications" component={NotificationSettings} />
              <PrivateRoute exact path="/paymentGateway" component={PaymentGateWay} />
              <PrivateRoute exact path="/cmsview" component={CmsView} /> 
              <PrivateRoute exact path="/emailpreview" component={EmailPreview} />
              <PrivateRoute exact path="/adminEmails" component={AdminEmails} />
              <PrivateRoute exact path="/smtpSettings" component={SMTPsetting} />
              <PrivateRoute exact path="/shippingMethods" component={ShippingMethods} />
              <PrivateRoute exact path="/addShipping" component={AddShipping} />
              <PrivateRoute exact path="/supplier" component={Suppleirs} />
              <PrivateRoute exact path="/addsuppliers" component={AddSuppliers} />
              <PrivateRoute exact path="/states" component={States} />
              <PrivateRoute exact path="/addstates" component={AddStates} />
              <PrivateRoute exact path="/brand" component={Brand} />
              <PrivateRoute exact path="/addbrand" component={AddBrand} /> */}

              {/* <PrivateRoute exact path="/house-info" component={HouseInfo} /> */}
              <PrivateRoute exact path="/DetailsSuccess" component={DetailsSuccess} />

              {/* <PrivateRoute exact path="/AddDetailsSuccess" component={AddDetailsSuccess} /> */}
              
              
              <PrivateRoute path="/*" component={PageNotFound} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}


export default App
 
// const mapStateToProps = state => ({
//   permissionsList:state.admindata.admindata.rolepermissions,
// });
// export default connect( mapStateToProps, null )(App)