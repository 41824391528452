import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'rc-pagination/assets/index.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import swal from 'sweetalert';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import * as actions from '../../actions';
import * as _ from "lodash";
import 'antd/dist/antd.css';
import { Select } from 'antd';
import Home from '../Home';
import { IMAGE_URL,CSV_URL,EXCEL_URL } from '../../config/configs';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
//const FileDownload = require('js-file-download');

class Properties extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoader: false,
      page: 1,
      pagesize: 10,
      propertyListing: [],
      categoryname: '',
      services: [],
      multipleDelete:[],
      category: '',
      total:0,
      sort: '',
      property_info: '',
      owner_info: '',
      available_for: '',
      status: '',
      changestatus:'Select',
      usersAccess:{}
    }
  }
  
  componentDidMount () {
    let permissions = this.props.permissionsList;
    this.getCategoryName();
    $('.filterlink').click(function () {
      $('#itemlist').hide();
      $('#filterlist').stop().slideToggle();
    });
    $('.listlink').click(function () {
        $('#filterlist').hide();
        $('#itemlist').stop().slideToggle();
    });
    //$('[data-toggle="tooltip"]').tooltip();
    this.propertyListing();
  }

  getCategoryName() {
    let categoryList = this.props.categoryList;
    let category_slug = this.props.match.params.c_slug;
    let category = _.find(categoryList, ['category_slug', category_slug]);
    this.setState({ categoryname: category.name});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.c_slug !== this.props.match.params.c_slug) {
      let category = nextProps.match.params.c_slug;
      
      if(!category) {
        swal('Invalid Details!');
        this.props.history.push('/dashboard');
      }
      this.setState({ category,
        page: 1,
        pagesize: 10,
        propertyListing: [],
        services: [],
        multipleDelete:[],
        total:0,
        sort: '',
        property_info: '',
        owner_info: '',
        available_for: '',
        status: '',
        changestatus:'Select', },
        () => {
          this.propertyListing();
          this.getCategoryName();
        }
      );
      
    }
  }

  //* ********** SORTING ************************//
  onSort = (name , value) => {
    if (value) {
			let sort = {};
      sort[name] = value;
      this.setState({sort: sort},
        () => {
          this.propertyListing();
        }
      )
		}
  }

  //* **********  USER LISTING  ************************//
  propertyListing = () => {
    let category = this.props.match.params.c_slug;
    let usertype = this.props.admindata.data.usertype;
    if(!category) {
      swal('Invalid Details!');
      this.props.history.push('/dashboard');
    }
    
    this.setState({ category: category });
    let url = 'admin/propertyListing';
    let method = 'post';
    let  { page, pagesize, property_info, owner_info, available_for, sort, status } = this.state;
    let data = { page: page, pagesize: pagesize, category, property_info, owner_info, available_for,sort, status, user_type: usertype };
    var body = data;
    var token = localStorage.getItem('token');

    this.setState({ isLoader: true });
    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      let responsedata = response.data;
      let propertydata = response.data.data;
      this.setState({ isLoader: false });
      if(responsedata.status == 0){
        this.setState({ propertyListing: [] });
        toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      } else {
        this.setState({ propertyListing: propertydata.propertyListing, services:propertydata.services, total:propertydata.total, length:propertydata.propertyListing.length});    
      }    
    })
  }

  userStatusChange = () => {
    let delArr = this.state.multipleDelete;
    let category_Name = this.state.categoryname;

    if(this.state.changestatus == 'Select') {
      toast.error('Please select any option!', { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
    } else {
      delArr = delArr.filter( Number );
      if(_.isEmpty(delArr)) {
        toast.error('Please select any '+category_Name+'!', { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });return false;
      }
      let body = { id: delArr, changestatus: this.state.changestatus };
  
      let url = 'admin/updatePropertyStatus';
      let method = 'post';
      let token = localStorage.getItem('token');

      this.setState({ isLoader: true });
      this.props.commonApiCall( url, method, body, token, null, this.props, response => {
        let responsedata = response.data;

        this.setState({ isLoader: false });
        if (responsedata.status == 1) {
          swal(responsedata.message, '', 'success')
          this.propertyListing();
        } else {
          toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
        }
        this.setState({ multipleDelete: [], changestatus:'Select'})
      })
    }
  }

  //* **********  RESET LISTING  ************************//
  resetListing = (e) => {
    this.setState({ 
      property_info: '', 
      owner_info:'', 
      available_for: '', 
      status:'', 
      page:1 },
      () => this.propertyListing()
    );
  }

  //**************************  USER DETAILS  ************************//
  getUserDetails=(Id)=>{
    // let body = { Id };
    // this.props.editDetails(body);
    //this.props.history.push('/edituser/'+Id);
  }

  selectAllcheck=()=>{
    let delarray = this.state.multipleDelete;
    this.onCheckbox();
    let { propertyListing } = this.state;
    propertyListing.map(each=>{
      if (!delarray.includes(each.id)) {
        delarray.push(each.id)
      } else {
        delarray.splice(delarray.indexOf(each.id), 1)
      }
    })
  }

  onCheckbox(id, val) {
    let delarray = this.state.multipleDelete;
    if (!delarray.includes(id)) {
      delarray.push(id);
    } else {
      delarray.splice(delarray.indexOf(id), 1);
    }

    // if (delarray.length != this.state.userListing.length) {
    //   this.setState({ checked: false });
    // }
    if (this)
      this.setState({ multipleDelete: delarray })

  }

  checkArray(id) {
    let { multipleDelete } = this.state;
    if (multipleDelete.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  // ########################### PAGINATION #########################
  paginationChange(page, pagesize){
    this.setState({
      page: page,
      pagesize: pagesize
    } ,() =>this.propertyListing());
  }

  handleChangePageSize = (value) => {
    this.setState({ pagesize: value, page: 1 }, () => this.propertyListing());
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  
  // ########################### Download CSV ######################### //

  downloaduserdetails = (format) => {

    let user_type = this.props.admindata.data.usertype;
    let  { property_info, owner_info, available_for, sort, status, category } = this.state;
    let data = { category, property_info, owner_info, available_for, sort, status, download:format, user_type: user_type};
    let body = data;
    
    let url = 'admin/propertyListing';
    let method = 'post';
    let token = localStorage.getItem('token');
    this.setState({ isLoader: true });
    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      let responsedata = response.data.data;
      this.setState({ isLoader: false });
      if(responsedata.fileName) {
        if(format === 'csv'){
          window.open(CSV_URL + responsedata.fileName,'_blank');
          //FileDownload(CSV_URL  +  data.filePathAndName, data.filePathAndName);
        } else {
          window.open(EXCEL_URL + responsedata.fileName,'_blank');
          //FileDownload(EXCEL_URL  +  data.filePathAndName, data.filePathAndName);
        }
      } else {
        toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      }
    });
  }

  addDefaultSrcForUser(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  addDefaultSrcForProperty(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render () {
    const Option = Select.Option
    let { profile_image, property_info, owner_info, available_for, status, propertyListing, services, pagesize, page, sort, total, length, category, categoryname, changestatus } = this.state;
    return (
      <Home>
        <div className='row'>
          <div className='col-md-12'>
            <div className='page-header border-bottom'>
              <h3>Manage {categoryname}</h3>
              <ul className='breadcrumb '>
                <li className='breadcrumb-item' onClick={()=>this.props.history.push('/dashboard')}>Home</li>
                <li className='breadcrumb-item active'>{categoryname} </li>
              </ul>
            </div>
          </div>
        </div>
       
        <div className='card transparent no-shadow fadeIn'>
     
          <div className="row data-filter justify-content-end card-body">
            <div className="col-12  text-md-right">
              <div className="text-right d-flex flex-wrap justify-content-end">
              <div className=''>
                    <p className='export-text'>Export Data</p>
                    <a className="btn btn-primary btn-image" href="#" onClick={this.downloaduserdetails.bind(this,"excel")}><img src="/assets/images/xls-excel-file.svg" alt="Excel" width="20" height="20"/> Excel</a>
                    <a className="btn btn-primary btn-image ml-2" href="#" onClick={this.downloaduserdetails.bind(this,"csv")}><img src="/assets/images/csv-file.svg" alt="CSV" width="20" height="20"/> CSV</a>
                </div>
              <div className='pl-30 border-left ml-30'>
                <Select
                  showSearch
                  placeholder="select"
                  optionFilterProp="children"
                  className="applyselect"
                  value={changestatus}
                  onSelect={(value) => this.setState({ changestatus: value })}
                >
                  <Option value="Active">Active</Option>
                  <Option value="InActive">InActive</Option>
                  {this.props.permissionsList == 'Admin' ? <Option value="Reject">Reject</Option> : null }
                </Select>

                <button type="button" className="btn btn-primary ml-2 apply_btn" onClick={this.userStatusChange}>Apply</button>
                </div>

                <div className='pl-30 border-left ml-30'>
                  <button type="button" className="btn btn-primary"  onClick={() => this.props.history.push('/add/'+category)}>
                        <i className="fa fa-plus" style={{textTransform: 'capitalize'}}/> Add New
                  </button>
                </div>
                
                
                {/* <div className="dropdown">
                  <button className="nav-link  btn btn-teal dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                        <span>Tools</span>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#" onClick={this.downloaduserdetails.bind(this,"excel")}>Export to Excel</a>
                      <a className="dropdown-item" href="#" onClick={this.downloaduserdetails.bind(this,"csv")}>Export to CSV</a>
                  </div>
                </div> */}
                <div className='pl-30 border-left ml-30'>
                  <button className="nav-link pull-right btn btn-danger filterlink" type="button" >
                    <i className="fa fa-filter mr-0" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
   
          <div className="card fadeIn">
            <div className='card-body'>
      
            <div className="filter-list" id="filterlist">
            <div className='border white-bg br-4 card-body'>
              <div className="row">            
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Property Info</label>
                      <input
                        className="form-control"
                        type="text"
                        name="property_info"
                        placeholder="Enter Property Name or Address"
                        value={property_info}
                        onChange={this.handleChange}
                      />
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                      <label>Owner Info</label>
                      <input
                        className="form-control"
                        type="text"
                        name="owner_info"
                        placeholder="Enter Owner Name or Email Address"
                        value={owner_info}
                        onChange={this.handleChange}
                      />
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Available For</label>
                    <select className="form-control" onChange={(e)=>this.setState({available_for:e.target.value})} value={available_for}>
                      <option value=''>Select</option>
                      {services.map((service, key) => {
                        return ( <option value={service.name} key={key}>{service.name}</option> );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Status</label>
                    
                    <select className="form-control" onChange={(e)=>this.setState({status:e.target.value})} value={status}>
                      <option value=''>Select</option>
                      <option value='1'>Active</option>
                      <option value='0'>InActive</option>
                      <option value='2'>Pending</option>
                      <option value='3'>Reject</option>
                      <option value='4'>Deleted</option>
                    </select>
                  </div>
                </div>
              </div>
             </div>
              
              <div className="pull-right filter-button card-body pr-0">
                <div className="dropdown ml-2">
                </div>
                <button className="nav-link  btn btn-outline-secondary ml-2" type="button" onClick={this.resetListing} > Reset </button>
                <button className="nav-link  btn btn-primary ml-2" type="button" onClick={this.propertyListing} > Apply Filter </button> 
                
                </div>
            </div>

            <div className="table-responsive border br-4 white-bg">
              <table className="table dataTable with-image row-border custom-table table-hover">
                <thead>
                  <tr>
                    <th>
                      <div className='checkbox'>
                      {this.props.permissionsList === 'Admin' ? <label>
                        <input type="checkbox" className="form-check-input" checked={this.checkArray()} onChange={()=>this.selectAllcheck()} />
                          <span />
                          <i className='input-helper' />
                        </label> : null}
                      </div>
                    </th>
                    <th sortable-column="id">ID</th>
                    <th sortable-column="property_name">
                      Property Info
                      {sort["property_name"] == "ASC" ? (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "property_name", "DESC")}
                          className="fa fa-arrow-up"
                        />
                      ) : (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "property_name", "ASC")}
                          className="fa fa-arrow-down"
                        />
                      )}
                    </th>
                    <th sortable-column="property_description">
                      Description
                      {sort["property_description"] == "ASC" ? (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "property_description", "DESC")}
                          className="fa fa-arrow-up"
                        />
                      ) : (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "property_description", "ASC")}
                          className="fa fa-arrow-down"
                        />
                      )}
                    </th>
                    <th sortable-column="owner_name">
                      Owner Info
                      {sort["owner_name"] == "ASC" ? (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "owner_name", "DESC")}
                          className="fa fa-arrow-up"
                        />
                      ) : (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "owner_name", "ASC")}
                          className="fa fa-arrow-down"
                        />
                      )}
                    </th>
                    <th sortable-column="available_for" className='text-center available-property'>
                      Available For
                      {sort["available_for"] == "ASC" ? (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(
                            this,
                            "available_for",
                            "DESC"
                          )}
                          className="fa fa-arrow-up"
                        />
                      ) : (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(
                            this,
                            "available_for",
                            "ASC"
                          )}
                          className="fa fa-arrow-down"
                        />
                      )}
                    </th>
                    <th sortable-column="price">
                      Price
                      {sort["price"] == "ASC" ? (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "price", "DESC")}
                          className="fa fa-arrow-up"
                        />
                      ) : (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "price", "ASC")}
                          className="fa fa-arrow-down"
                        />
                      )}
                    </th>
                    <th sortable-column="status">
                      Status
                      {/* {sort["status"] == "ASC" ? (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(
                            this,
                            "status",
                            "DESC"
                          )}
                          className="fa fa-arrow-up"
                        />
                      ) : (
                        <i
                          aria-hidden="true"
                          onClick={this.onSort.bind(this, "status", "ASC")}
                          className="fa fa-arrow-down"
                        />
                      )} */}
                    </th>
                    {/* <th width="6%" align="center">
                      Actions
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {propertyListing.length > 0 && propertyListing.map((property, Key) => {
                    let filesplit, thumbimage, ownerfilesplit, ownerthumbimg;
                    if(property.property_image) {
                      filesplit = property.property_image.split('.');
                      thumbimage = filesplit[0]+'_thumb.'+filesplit[1];
                    }

                    if(property.owner_image) {
                      ownerfilesplit = property.owner_image.split('.');
                      ownerthumbimg = ownerfilesplit[0]+'_thumb.'+ownerfilesplit[1];
                    }
                    
                    return (
                      <tr
                        key={property.id}
                        key={Key}
                        className="animated fadeIn"
                      >
                        <td>
                          <div className='checkbox'>
                            {(this.props.permissionsList === 'Admin' || property.status == 1 || property.status == 0) && property.status != 4 ? <label>
                            <input type="checkbox" className="form-check-input" checked={(this.checkArray(property.id)) ? true : false} onChange={()=>this.onCheckbox(property.id)} />
                              <span />
                              <i className='input-helper' />
                            </label> : null }
                          </div>
                        </td>
                        <td>{Key + 1 + (page - 1) * pagesize}</td>
                        <td>
                          <a className='block-imagecontent' onClick={() => this.props.history.push( "/properties/"+categoryname.toLowerCase()+"/"+ property.id ) } href='Javascript:;'>
                            <div className="thumb-img">
                              <img src={ property.property_image ? IMAGE_URL + thumbimage : "assets/images/no-image-user.png" } alt="Property Image" onError={this.addDefaultSrcForProperty} />
                            </div>
                            <div className='userinfo-block'>
                              <p className='usename text-blue'>{property.property_name}</p>
                              <p className='usemail'>{property.property_address}</p>
                            </div>
                          </a>
                        </td>
                        <td className='text-muted'>{property.property_description.slice(0,50)} </td>
                        <td>
                        <div className='block-imagecontent'>
                            <div className="thumb-img">
                              <img src={ property.owner_image ? IMAGE_URL + ownerthumbimg : "assets/images/no-image-user.png" } alt="Owner Image" onError={this.addDefaultSrcForUser} />
                            </div>
                            <div className='userinfo-block'>
                              <p className='usename'>{property.owner_name}</p>
                              <p className='usemail'>{property.owner_email}</p>
                            </div>
                          </div>
                        </td>
                        <td className='text-center text-highlighted'>{property.available_for} </td>
                        <td>${property.price}{property.price_unit ? ' / '+property.price_unit : ''}</td>
                        <td>
                        { property.status == '0' ? <span className='badge badge-warning badge-pill'>InActive</span> : null }
                        { property.status == '1' ? <span className='badge badge-success badge-pill'>Active</span> : null }
                        { property.status == '2' ? <span className='badge badge-info badge-pill'>Pending</span> : null }
                        { property.status == '3' ? <span className='badge badge-danger badge-pill'>Rejected</span> : null }
                        { property.status == '4' ? <span className='badge badge-danger badge-pill'>Deleted</span> : null }
                        {/* {property.status == 1 ? 'Active' : 'Inactive'}  */}
                        </td>
                        {/* <td align="center">
                          <button onClick={() => this.props.history.push( "/property-details/" + property.id ) } >
                          <i className="fa fa-edit text-muted" aria-hidden="true" data-toggle="tooltip"  title="View"></i>
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                  { propertyListing.length <= 0 ? <tr ><td text="center" colSpan='8'> 
                    <div className='no-datablock p-30 text-center'>
                    <div className='no-dataimage'><img src="/assets/images/exclamation-mark.png" /></div>
                    <h2 className='no-data'>No Record Found</h2>
                    </div>
                  </td></tr> : null }
                </tbody>
              </table>
            </div>
            <div className="table-footer text-right">
              <label>Showing</label>
              <Select
                showSearch
                placeholder={<b> {total <= 5 ? total : length}</b>}
                optionFilterProp="children"
                onSelect={this.handleChangePageSize.bind(this)}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              >
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
                <Option value={15}>15</Option>
                <Option value={50}>50</Option>
              </Select>
              <label>Out of {total} {categoryname}</label>
              <div className="pagination-list">
                <Pagination
                  className="ant-pagination"
                  pageSize={pagesize}
                  current={page}
                  total={total}
                  onChange={this.paginationChange.bind(this)}
                  locale={localeInfo}
                />
              </div>
            </div>
            </div>
          </div>
        </div>
        {this.state.isLoader == true ? <div className='loader-block'>
          <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
    )
  }
}

const mapStateToProps = state => ({
  admindata: state.admindata.admindata,
  categoryList: state.admindata.admindata.data.categoryList,
  getadmindata: state.user.userdatails,
  permissionsList: state.admindata.admindata.rolepermissions,

});
export default connect( mapStateToProps, actions )(Properties)
