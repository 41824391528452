import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import CanvasJSReact from '../container/canvasjs.react';
import PieChart from 'react-minimal-pie-chart';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import swal from 'sweetalert';

import { IMAGE_URL } from '../config/configs';
import * as actions from "../actions";

import Home from './Home';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      page: 1,
      pagesize: 5,
      propertyListing: [],
      currentMonthEarning: '',
      currentMonthNewLeads: '',
      successLeads: '',
      lostLeads: '',
      pieChartdata: [],
      lineChartdata: [],
      barChartdata: [],
      cat_names: [],
      lineChartOption: 'Year',
      barChartOption: 'Year'
    }

  }
  componentDidMount() {
    var cat_names1 = this.props.categoryList;
    this.setState({ cat_names: cat_names1 })

    this.propertyListing();
    this.getPieChartData();
    this.getLineChartData();
    this.getBarChartData();

  }

  handleChangeLineChart = (value) => {
    this.setState({ lineChartOption: value }, () => this.getLineChartData());
  }

  handleChangeBarChart = (value) => {
    this.setState({ barChartOption: value }, () => this.getBarChartData());
  }

  //* **********  USER LISTING  ************************//
  propertyListing = (e,val) => {
    
    let category = val ? val : 'houses';
    
    if(!category) {
      // swal('Invalid Details!');
      this.props.history.push('/dashboard');
    }
    
    this.setState({ category: category, isLoader: true});
    let url = 'admin/propertyListing';
    let method = 'post';
    let  { page } = this.state;
    let data = { page: page, pagesize: 5, category, status: '2' };
    var body = data;
    var token = localStorage.getItem('token');

    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      let responsedata = response.data
      let propertydata = response.data.data   
      if(responsedata.status == 0){
        this.setState({ 
          propertyListing: [], 
          isLoader: false });
        // toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      } else {
        this.setState({ 
          propertyListing: propertydata.propertyListing, 
          services:propertydata.services, 
          total:propertydata.total, 
          length:propertydata.propertyListing.length, 
          isLoader: false });    
      }    
    })
  }

  //* **********  PIE CHART DATA  ************************//
  getPieChartData = (e,val) => {
    
    let category = val ? val : 'houses';    
    this.setState({ category: category, isLoader: true });
    
    let url = 'admin/dashboardpiechart';
    let method = 'post';
    let data = {  };
    let body = data;
    let token = localStorage.getItem('token');

    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      let responsedata = response.data;
      let dashboarddata = response.data.data;
      if(responsedata.status == 0){
        this.setState({ isLoader: false, pieChartdata:[] });
        // toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      } else {
        this.setState({
          currentMonthEarning: dashboarddata.total_earning_month.total_amount,
          currentMonthNewLeads: dashboarddata.total_earning_month.new_lead,
          successLeads: dashboarddata.total_earning_month.success_lead,
          lostLeads: dashboarddata.total_earning_month.lost_lead,
          pieChartdata: dashboarddata.piedata, 
          isLoader: false
        });    
      }    
    })
  }

  //* **********  LINE CHART DATA  ************************//
  getLineChartData = (e) => {
    let url = 'admin/dashboardlinechart';
    let method = 'post';
    let { lineChartOption } = this.state;
    let data = { option: lineChartOption };
    let token = localStorage.getItem('token');
    this.setState({ isLoader: true });
    this.props.commonApiCall( url, method, data, token, null, this.props, response => {
      let responsedata = response.data
      let dashboarddata = response.data.data   
      if(responsedata.status == 0){
        this.setState({ isLoader: false, lineChartdata:[] });
        // toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      } else {
        this.setState({
          lineChartdata: dashboarddata,
          isLoader: false
        });    
      }    
    })
  }

  //***********************  BAR CHART DATA  ************************//
  getBarChartData = (e) => {
    let url = 'admin/dashboardbarchart';
    let method = 'post';
    let { barChartOption } = this.state;
    let data = { option: barChartOption };
    let token = localStorage.getItem('token');

    this.setState({ isLoader: true });
    this.props.commonApiCall( url, method, data, token, null, this.props, response => {
      let responsedata = response.data;
      let salestrenddata = response.data.data;
      if(responsedata.status == 0){

        this.setState({ isLoader: false, barChartdata:[] });
        // toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      } else {
        this.setState({
          barChartdata: salestrenddata,
          isLoader: false
        });    
      }    
    })
  }

  changepropertystatus = (id, status) => {

    let body = { id: id, changestatus: status };
    let url = 'admin/updatePropertyStatus';
    let method = 'post';
    let token = localStorage.getItem('token');

    
    if(status == 'Reject') {
      swal({
        title: "Are you sure?",
        text: "Once rejected, You will be able to Re-Approve this Listing from the Property Listing page!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.setState({ isLoader: true });
          this.props.commonApiCall( url, method, body, token, null, this.props, response => {
            let responsedata = response.data;
            this.setState({ isLoader: false });
            if (responsedata.status == 1) {
              swal(responsedata.message, {
                icon: "success",
              });
              this.propertyListing();
            } else {
              toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
            }
            this.setState({ multipleDelete: [], changestatus:'Select'})
          })
        }
      });
    } else {
      this.setState({ isLoader: true });
      this.props.commonApiCall( url, method, body, token, null, this.props, response => {
        let responsedata = response.data;
        this.setState({ isLoader: false });
        if (responsedata.status == 1) {
          swal(responsedata.message, '', 'success');
          this.propertyListing();
        } else {
          toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
        }
        this.setState({ multipleDelete: [], changestatus:'Select'})
      })
    }     
  }

  addDefaultSrcForUser(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  addDefaultSrcForProperty(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render() {
    let { cat_names ,propertyListing, pagesize, page, currentMonthEarning, currentMonthNewLeads, successLeads, lostLeads, pieChartdata, lineChartdata, barChartdata, lineChartOption , barChartOption } = this.state;

    const lineChartOptions = {
			animationEnabled: true,
			title:{
				text: ""
			},
			// axisX: {
			// 	valueFormatString: "MMM",
			// },
			axisY: {
				title: "",
				prefix: "",
				includeZero: false
			},
			data: [{
				yValueFormatString: "#,###",
				xValueFormatString: "DD MM",
        type: "line",
        color: "#FD7B1F",
        dataPoints: lineChartdata.lostleads
      },
      {
				yValueFormatString: "#,###",
				xValueFormatString: "DD MM",
        type: "line",
        color: "#4ECE3D",
        dataPoints: lineChartdata.successleads
      }
    ]
    };
    
    const barChartOptions = {
			title: {
				text: ""
      },
			axisY: {
				title: "",
				prefix: "",
				includeZero: false
			},
			data: [
			{
        // Change type to "doughnut", "line", "splineArea", etc.
        yValueFormatString: "#,###",
				//xValueFormatString: "MMMM",
        type: "column",
        color: "#4ECE3D",
        dataPoints: barChartdata

			}
			]
    }
    
    const pieChartOption = pieChartdata;
    const Option = Select.Option;

    return (
      <Home>
        <div className="row">
          <div className="col-md-12">
            <div className="page-header border-bottom">
              <h3>Dashboard</h3>
              <ul className="breadcrumb ">
                <li className="breadcrumb-item active">Home </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-body'>
            <div className='card-row'>

              <div className='card-col50 wow fadeInUp'>
                <div className='white-bg border br-4 border h-100'>
                  <div className='card-header border-bottom card-headblock'>
                    <div className='left-descblock'>
                      <p>Total earning of current month</p>
                    </div>
                    <div className='right-descblock'>
                      <p className='text-blue'>${currentMonthEarning} +</p>
                    </div>
                  </div>
                  <div className='light-grey card-list'>
                    <ul>
                      <li className='card-headblock'>
                        <div className='left-descblock'>
                          <p>New Leads in current month</p>
                          <p className='text-muted'>Number of customers reached out to agents</p>
                        </div>
                        <div className='right-descblock'>
                          <p>{currentMonthNewLeads}</p>
                        </div>
                      </li>
                      <li className='card-headblock'>
                        <div className='left-descblock'>
                          <p>Successive Leads</p>
                          <p className='text-muted'>Number of leads successfully converted</p>
                        </div>
                        <div className='right-descblock'>
                          <p>{successLeads}</p>
                        </div>
                      </li>
                      <li className='card-headblock'>
                        <div className='left-descblock'>
                          <p>Lost Leads</p>
                          <p className='text-muted'>Number of leads which are lost</p>
                        </div>
                        <div className='right-descblock'>
                          <p>{lostLeads}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='card-col50 wow fadeInUp'>
                <div className='white-bg border br-4 border h-100'>
                  <div className='card-header border-bottom card-headblock'>
                    <div className='left-descblock'>
                      <p>Properties, Assets & Services</p>
                    </div>
                    <div className='right-descblock'>
                      {/* <p className='text-blue'>$123,000 +</p> */}
                    </div>
                  </div>
                  <div className='light-grey card-list graph-block asets-blockwrap'>
                    <div className='graph'>
                      {/* <img src='../../assets/images/chart1.png' alt='Graph' width='500' height='300' /> */}
                      <PieChart
                        data={pieChartOption}
                        lineWidth={10}
                        paddingAngle={2}
                      />
                    </div>
                    <div className="graph-descblock">
                      <ul>
                        {pieChartdata.map((piedata, key) => {
                          let piestyle = {
                            'backgroundColor': piedata.color
                          }
                        return <li key={key}><span className='info-dot' style={piestyle}></span> {piedata.value+' '+piedata.title}</li>
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-col50 wow fadeInUp'>
                <div className='white-bg border br-4 border'>
                  <div className='card-header border-bottom card-headblock'>
                    <div className='left-descblock'>
                      <p>Lead Success Vs Lead Lost</p>
                    </div>
                    <div className='right-descblock'>
                      {/* <p className='text-blue'>$123,000 +</p> */}
                      <Select
                        showSearch
                        placeholder="select"
                        optionFilterProp="children"
                        className="applyselect"
                        value={lineChartOption}
                        onSelect={this.handleChangeLineChart.bind(this)}
                      >
                        <Option value="Year">Current Year</Option>
                        <Option value="Month">Current Month</Option>
                        <Option value="Week">Current Week</Option>
                      </Select>
                    </div>
                  </div>
                  <div className='light-grey card-list'>
                    <div className='graph'>
                      {/* <img src='../../assets/images/chart2.png' alt='Graph' width='500' height='300' /> */}
                      <CanvasJSChart options = {lineChartOptions} 
                        /* onRef={ref => this.chart = ref} */
                      />
                    </div>
                  </div>
                </div>
              </div>



              <div className='card-col50 wow fadeInUp'>
                <div className='white-bg border br-4 border h-100'>
                  <div className='card-header border-bottom card-headblock'>
                    <div className='left-descblock'>
                      <p>Sales Trends</p>
                    </div>
                    <div className='right-descblock'>
                      {/* <p className='text-blue'>$123,000 +</p> */}
                      <Select
                        showSearch
                        placeholder="select"
                        optionFilterProp="children"
                        className="applyselect"
                        value={barChartOption}
                        onSelect={this.handleChangeBarChart.bind(this)}
                      >
                        <Option value="Year">Current Year</Option>
                        <Option value="Month">Current Month</Option>
                        <Option value="Week">Current Week</Option>
                      </Select>
                    </div>
                  </div>
                  <div className='light-grey card-list'>
                    <div className='graph'>
                      {/* <img src='../../assets/images/chart3.png' alt='Graph' width='500' height='300' /> */}
                      <CanvasJSChart options = {barChartOptions}
                        /* onRef={ref => this.chart = ref} */
                      />
                    </div>
                  </div>
                </div>
              </div>


            </div>


            <div className='white-bg border br-4 border wow fadeInUp'>

              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {cat_names.length > 0 ?
                  <>{cat_names.map((each, key )=> {

                    return (
  
                      <li className={key == 0 ? 'nav-item active' : 'nav-item'} onClick={(e)=>this.propertyListing(e,each.category_slug)} key={key}>
                        <a className={key == 0 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#house" role="tab" aria-controls="item" aria-selected="false"> <span className='icon'><img src="/assets/images/property.png" alt='' width='14' height='13' /></span> {each.name}</a>
                      </li>
                    )

                  })}
                  </> : null}

              </ul>

              <div className="tab-content p-0" id="myTabContent">
                <div className="fade show active tab-pane" id="house" role="tabpanel" aria-labelledby="itemtab">
                  <div className='table-responsive'>
                    <table className='table with-image dataTable table-hover'>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Property Info</th>
                          <th>Description</th>
                          <th>Owner Info</th>
                          <th className='text-center'>Available for</th>
                          <th className='text-right'>price</th>
                          <th className='text-center'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                      { propertyListing.map((property, Key) => {
                        return <tr  key={property.id}  className="animated fadeIn" >
                         <td>{Key + 1 + (page - 1) * pagesize}</td>
                        <td>
                        <div className='block-imagecontent'>
                            <div className="thumb-img">
                            <img src={ property.property_image ? IMAGE_URL + property.property_image : "assets/images/no-image-user.png" } alt="Property Image" onError={this.addDefaultSrcForProperty} />
                            </div>
                            <div className='userinfo-block'>
                              <p className="usename">{property.property_name}</p>
                              <p className="usemail">{property.property_address}</p>
                            </div>
                          </div>
                        </td>
                        <td className='text-muted'>
                          {property.property_description.slice(0,50)}
                        </td>
                        <td>
                          <div className='block-imagecontent'>
                            <div className="thumb-img">
                            <img src={ property.owner_image ? IMAGE_URL + property.owner_image : "assets/images/no-image-user.png" } alt="Owner Image" onError={this.addDefaultSrcForUser} />
                            </div>
                            <div className='userinfo-block'>
                            <p className='usename'>{property.owner_name}</p>
                              <p className='usemail'>{property.owner_email}</p>
                            </div>
                          </div>
                        </td>
                        <td className='text-highlighted text-center'> {property.available_for} </td>
                        <td className='text-center'>${property.price}{property.price_unit ? ' / '+property.price_unit : ''}</td>
                        <td className='text-center'>
                          <a href='Javascript:;' className='edit p-1' title='Approve' onClick={this.changepropertystatus.bind(this, property.id, 'Active')}><img src='/assets/images/true.png' alt='Edit' width='25' height='25' /></a>
                          <a href='Javascript:;' className='remove p-1' title='Reject' onClick={this.changepropertystatus.bind(this, property.id, 'Reject')}><img src='/assets/images/remove.png' alt='Remove' width='25' height='25' /></a>
                        </td>
                      </tr>
                      })}
                       
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                <div >
                 
                </div>
              </div>

            </div>


          </div>
        </div>
        {this.state.isLoader == true ? <div className='loader-block'>
            <img src="/assets/images/loader2.gif" />
        </div> : null }

      </Home>
    );
  }
}
const mapStateToProps = state => ({
  // categoryList: state.admindata.admindata.categoryList,
  categoryList: state.admindata.admindata.data.categoryList,

  admindata: state.admindata.admindata,
  permissionsList: state.admindata.admindata.rolepermissions,
  editAdminProfileData: state.commonReducers.edited1Details
});
// export default withRouter(Dashboard);
export default connect(mapStateToProps, actions)(Dashboard)