import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminForgotPassword } from '../actions';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { IMAGE_URL } from '../config/configs';

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state ={
      email : '',
      errors: {}
    }
  }
 
  handleChange=(event) => {
    this.setState({[event.target.name]:event.target.value})
  }

  //******************** Forgot password **************************/ 

  // forgotPassword = (e) => {
  //   e.preventDefault();
  //   var body ={ email :this.state.email}
  //   axios({
  //     "method" :"post",
  //     url :API_URL+'/admins/forgotPasswordForAdmin',
  //     headers:{
  //       "Content-type" : "application/json",
  //     },
  //     data :JSON.stringify(body)
  //   })
  //   .then(response => {
  //     console.log('reposne',response)
  //     let {data} =response
  //     if(data.status === 1 ) {
  //       swal ('Please Check Registred Email !','','success');
  //     } else {
  //       swal ('Enter Your Registered Email','','error');
  //     }
  //   })
  //   .catch((err) => console.log('err',err))
  // }

  forgotPassword = (e) => {
    e.preventDefault();
    if(this.validateForm()) {
      var body = { email :this.state.email };
      this.props.adminForgotPassword(body, (response) => {
        let responsedata = response.data;
        if (responsedata.status == 1) {         
          swal({
            title: responsedata.message,
            icon: "success",
            }).then((willDelete) => {
              if (willDelete) {
                this.props.history.push('/');
              }
            });
        } else {
          toast.error(responsedata.message,  { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
        }
      })
    } else {
      return false;
    }
  }

  validateForm () {
    let errors = {};
    let formIsValid = true
    if(!this.state.email) {
      formIsValid = false;
      errors['email'] = 'Please enter email address!';
    } else if (typeof this.state.email !== 'undefined') {
      var pattern = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if(!pattern.test(this.state.email)) {
        formIsValid = false;
        errors['email'] = 'Please enter a valid email address!';
      }
    } else {
      formIsValid = true;
    }
    this.setState({ errors: errors })
    return formIsValid;
  }
  
  render() {
    return (  
      <div className="container-scroller">
        <ToastContainer />
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth login-full-bg">
            <div className="row w-100 margin-l-0">
              <div className="col-lg-6 col-sm-8 mx-auto authentication-form color-white">
                <div className="auth-form-light">
                  <div className='login-header mb-5'>
                    <img src='/assets/images/logo.svg' alt='logo' width='18' height='22' className='logo' />
                    <h1 className='logo-text'>Super Admin</h1>
                    <h2>Forgot Password?</h2>
                  </div>
                  <form  autoComplete="off" onSubmit={this.forgotPassword} className='authenticate-block'>
                  <h3 className="text-center mb-20 subheading">Enter your email address below</h3>
                    <div  className="form-group" >
                      <label>Your email</label>
                      <input className="form-control" type="email" name="email" value={this.state.email} placeholder="Enter Email Address" onChange={this.handleChange} />
                      <span className='error-block'> {this.state.errors.email}</span>
                    </div>
                    
                    <div className="mt-0 submit-button">
                      <button className="btn btn-outline-secondary btn-md" type="submit"  onClick={() => this.props.history.push('/')}>Cancel</button>
                      <button className="btn btn-primary btn-md" type="button"  onClick={this.forgotPassword}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    );
  }
}

export default connect(null, { adminForgotPassword })(withRouter(ForgotPassword));
