import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";
import{ IMAGE_URL } from '../config/configs';
// import CustomScroll from 'react-custom-scroll';
//import { stat } from 'fs';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      emailId: "",
      photo: "",
      adminUsersAccess: {},
      cmsPagesAccess: {},
      emailSettingsAccess: {},
      homeBannerAccess: {},
      ordersAccess: {},
      productCategoryAccess: {},
      productsAccess: {},
      rolesAccess: {},
      usersAccess: {},
      categoryList: []
    };
    this.changeRoute = this.changeRoute.bind(this);
  }

  componentDidMount() {
    var categoryList = this.props.categoryList;
    var permissions = this.props.permissionsList;

    if (this.props.admindata.data) {
      if (
        this.props.editAdminProfileData &&
        this.props.editAdminProfileData.data
      ) {
        var {
          first_name,
          last_name,
          email,
          profile_image,
          categoryList
        } = this.props.editAdminProfileData.data;
        this.setState({
          userName: first_name + " " + last_name,
          emailId: email,
          photo: profile_image,
          categoryList: categoryList
        });
      } else {
        var {
          first_name,
          last_name,
          email,
          profile_image,
          categoryList
        } = this.props.admindata.data;
        this.setState({
          userName: first_name + " " + last_name,
          emailId: email,
          photo: profile_image,
          categoryList: categoryList
        });
      }
    }
  }
  changeRoute(page) {
    this.props.history.push(page);
  }

  addDefaultSrc(ev) {
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render() {
    let _this = this;
    let {
      userName,
      photo,
      categoryList,
      adminUsersAccess,
      cmsPagesAccess,
      emailSettingsAccess,
      homeBannerAccess,
      ordersAccess,
      productCategoryAccess,
      productsAccess,
      rolesAccess,
      usersAccess
    } = this.state;
    let path = this.props.location.pathname;
    let splitpath = path.split('/');
    let permissions = this.props.permissionsList;

    return (
     
        <aside className="sidebar sidebar-offcanvas" id="sidebar">
          {/* <CustomScroll heightRelativeToParent="calc(100% - 20px)"> */}
          <div className="sidebar-wrapper">
          { permissions === 'Admin' ? <ul className="nav panel-group" id="accordionExample">
              <li className={ path === "/dashboard" ? "nav-item active" : "nav-item" } >
                <a className="nav-link" onClick={this.changeRoute.bind(this, "/dashboard")} >
                <span className='icon'><img src="/assets/images/home.png" alt='Dashboard' width='14' height='13' /></span>
                  {/* <i className="fa fa-home" aria-hidden="true" /> */}
                  <span className="menu-title">Dashboard</span>
                </a>
              </li>

              <li className="nav-small-cap">User Management</li>
              {/* //USER */}
              <li className={splitpath[2] === "customer" ? "nav-item active" : "nav-item"}> 
              <Link to={`/users/${"customer"}`} refresh="true" className="nav-link" >
                  <span className='icon'><img src="/assets/images/customers.png" alt='Customers' width='14' height='13' /></span>
                  {/* <i className="fa fa-user-plus" aria-hidden="true" /> */}
                  <span className="menu-title">Customers</span>
                </Link>
              </li>
              <li className={splitpath[2] === "agent" ? "nav-item active" : "nav-item"}>
                <Link to={`/users/${"agent"}`} className="nav-link">
                <span className='icon'><img src="/assets/images/agent.png" alt='Agents' width='14' height='13' /></span>
                  {/* <i className="fa fa-user-plus" aria-hidden="true" /> */}
                  <span className="menu-title">Agents</span>
                </Link>
              </li>
              <li className={splitpath[1] === "owners" ? "nav-item active" : "nav-item"} >
                <a className="nav-link" onClick={this.changeRoute.bind(this, "/owners")} >
                <span className='icon'><img src="/assets/images/owner.png" alt='Owners' width='14' height='13' /></span>
                
                  {/* <i className="fa fa-user-plus" aria-hidden="true" /> */}
                  <span className="menu-title">Owners</span>
                </a>
              </li>

              {/* //Category Pages */}
              <li className="nav-small-cap">property & asset mgmt</li>
              {categoryList.map(function(value, index) {
                return (
                  <li key={index} className={splitpath[2] == value.category_slug ? "nav-item active" : "nav-item" } >
                    <a className="nav-link" onClick={_this.changeRoute.bind( _this, "/properties/" + value.category_slug )} >
                    <span className='icon'><img src="/assets/images/property.png" alt='' width='14' height='13' /></span>
                      {/* <i className="fa fa-building" aria-hidden="true" /> */}
                      <span className="menu-title">{value.name}</span>
                    </a>
                  </li>
                );
              })}

              {/* //CMS */}

              <li className="nav-small-cap">Lead Management</li>
              <li className={ splitpath[1] === "leads" ? "nav-item active" : "nav-item" } >
                <a className="nav-link" onClick={this.changeRoute.bind(this, "/leads")} >
                <span className='icon'><img src="/assets/images/leads.png" alt='All Leads' width='14' height='13' /></span>
                  {/* <i className="fa fa-money" aria-hidden="true" /> */}
                  <span className="menu-title">All Leads</span>
                </a>
              </li>


              <li className="nav-small-cap">Content Management</li>
              
              <li className={ splitpath[1] == "cmspages" ? "nav-item active" : "nav-item" } onClick={this.changeRoute.bind(this, "/cmspages")} >
                <a className="nav-link">
                  <span className='icon'><img src="/assets/images/cms-icon.png" alt='CMS Management' width='14' height='13' /></span>
                  {/* <i className="fa fa-edit" /> */}
                  <span className="menu-title">CMS Management</span>
                </a>
              </li>

              <li className={ splitpath[1] == "category" ? "nav-item active" : "nav-item" } onClick={this.changeRoute.bind(this, "/category")} >
                <a className="nav-link">
                  <span className='icon'><img src="/assets/images/cms-icon.png" alt='CMS Management' width='14' height='13' /></span>
                  {/* <i className="fa fa-edit" /> */}
                  <span className="menu-title">Category Management</span>
                </a>
              </li>

              {/* //SETTINGS */}
              <li className="nav-small-cap">Settings</li>
              <li className={ splitpath[1] == "emailcontentlist" ? "nav-item active" : "nav-item" } onClick={this.changeRoute.bind(this, "/emailcontentlist")} >
                <a className="nav-link">
                <span className='icon'><img src="/assets/images/email.png" alt='Email Template' width='14' height='13' /></span>
                  {/* <i className="fa fa-edit" /> */}
                  <span className="menu-title">Email Template</span>
                </a>
              </li>
              <li className={ splitpath[1] === "setting" ? "nav-item active" : "nav-item" } >
                  <a className="nav-link" onClick={this.changeRoute.bind(this, "/setting")} >
                  <span className='icon'><img src="/assets/images/settings.png" alt='Global Settings' width='14' height='13' /></span>
                    {/* <i className="fa fa-globe" aria-hidden="true" /> */}
                    <span className="menu-title">Global Settings</span>
                  </a>
              </li>
            </ul> : <ul className="nav panel-group" id="accordionExample">
              
              {/* //Category Pages */}
              <li className="nav-small-cap">property & asset mgmt</li>
              {categoryList.map(function(value, index) {
                return (
                  <li key={index} className={splitpath[2] == value.category_slug ? "nav-item active" : "nav-item" } >
                    <a className="nav-link" onClick={_this.changeRoute.bind( _this, "/properties/" + value.category_slug )} >
                    <span className='icon'><img src="/assets/images/settings.png" alt='Global Settings' width='14' height='13' /></span>
                      <span className="menu-title">{value.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul> }

          </div>
          {/* </CustomScroll> */}
      
      </aside>
    );
  }
}

const mapStateToProps = state => ({
  categoryList: state.admindata.admindata.categoryList,
  admindata: state.admindata.admindata,
  permissionsList: state.admindata.admindata.rolepermissions,
  editAdminProfileData: state.commonReducers.edited1Details
});

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(Sidebar)
);
