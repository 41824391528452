import React, { Component } from 'react';

class Footer extends Component {
    render () {
        return (
            <footer className='footer'> 2019 © All rights reserved by <a href='Javascript:;'>SeaTime</a> </footer>
        );
    }
}

export default Footer