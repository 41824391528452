import React, { Component } from 'react';
import Script from 'react-load-script';
import GoogleMapReact from 'google-map-react';
import * as _ from "lodash";
//import SearchBar from 'material-ui-search-bar';
//import Home from '../Home';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class Step1 extends Component {
    constructor(props) {
        super(props);

        // Bind Functions
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        //this.isValidated = this.isValidated.bind(this);
    }

    isValidated = (e) => {
        let { item_info } = this.props;
        let errors = [];
        let formIsValid = true;
        if (!item_info.property_name) {
            formIsValid = false;
            errors["property_name"] = "* Property Name is required!";
        }

        if (!item_info.address) {
            formIsValid = false;
            errors["address"] = "* Address is required!";
        } 

        // if (!item_info.country) {
        //     formIsValid = false;
        //     errors["country"] = "* Country is required!";
        // }

        // if (!item_info.state) {
        //     formIsValid = false;
        //     errors["state"] = "* State is required!";
        // }
        
        // if (!item_info.city) {
        //     formIsValid = false;
        //     errors["city"] = "* City is required!";
        // }

        // if (!item_info.zipcode) {
        //     formIsValid = false;
        //     errors["zipcode"] = "* Zip/Postal code is required!";
        // }

        if (!item_info.description) {
            formIsValid = false;
            errors["description"] = "* Description is required!";
        }
        let stepErrors = this.props.item_info;
        stepErrors['errors']= errors;
		this.setState({ item_info:stepErrors });
        return formIsValid;
    }

    handleScriptLoad = () => {
        // Declare Options For Autocomplete
        var options = {
          types: [],
        };
    
        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById('autocomplete'),
          options,
        );
    
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }
      
    handlePlaceSelect= () => {
    
        // Extract City From Address Object
        let item_info = this.props.item_info;
        let addressObject = this.autocomplete.getPlace();
        let address = addressObject.address_components;
        let state = _.find(address, { 'types': [ "administrative_area_level_1", "political" ] });
        let country = _.find(address, { 'types': [ "country", "political" ] });
        let zipcode = _.find(address, { 'types': [ "postal_code" ] });
        // Check if address is valid
        // console.log(addressObject.geometry.location.lat(), 'addressObject data');
        // console.log(state, 'state data');
        // console.log(country, 'country data');
        // console.log(zipcode, 'zipcode data');
        
        if (address) {
          // Set State
            item_info.address = addressObject.formatted_address ? addressObject.formatted_address : '';
            item_info.city = address[0].long_name ? address[0].long_name : '';
            item_info.state = state && state.long_name ? state.long_name : '';
            item_info.country = country && country.long_name ? country.long_name : '';
            item_info.latitude = addressObject.geometry ? addressObject.geometry.location.lat() : '';
            item_info.longitude = addressObject.geometry ? addressObject.geometry.location.lng() : '';
            item_info.zipcode = zipcode && zipcode.long_name ? zipcode.long_name : '';
            this.setState({ item_info });;
        }
    }

    render(){
        const { item_info, service_id, category_name, handleChangeStep1, handleChangeRadio, handleChangeStep12 } = this.props;
        const { serviceoptions } = item_info;
        //let center = { lat: latitude, lng: longitude };
        return ( 
            <div className='card transparent no-shadow'>
            <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCKfvxf25tXbfE9XOyo8T77GnCU-p4OMlw&libraries=places" onLoad={this.handleScriptLoad} />
            {/*  */}
                <div className='card-body'>
                    <div className='white-bg box-shadow br-4 border-secondary'>
                    <div className='card-header border-bottom'>
                        <p className="mb-0 font-16">Enter {category_name} Information</p>
                    </div>
                 
                        
                        <div className='card-body pt-30 light-grey'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='animated fadeIn mb-2 form-material row'>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">Name <span className="text-danger">*</span> :</label>
                                            <input
                                                className='form-control'
                                                type='text'
                                                name='property_name'
                                                placeholder='Enter Property Name'
                                                value={item_info.property_name}
                                                onChange={handleChangeStep1}
                                                />
                                            <span className="error-block"> {item_info.errors.property_name} </span>
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">Address <span className="text-danger">*</span> :</label>
                                            <input
                                                id="autocomplete"
                                                className='form-control'
                                                type='text'
                                                name='address'
                                                placeholder='Enter Address'
                                                value={item_info.address}
                                                onChange={handleChangeStep1}
                                                onSelect={handleChangeStep12}
                                                />
                                            <span className="error-block"> {item_info.errors.address} </span>
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">Country </label>
                                            <input
                                                id="autocomplete"
                                                className='form-control'
                                                type='text'
                                                name='country'
                                                placeholder='Enter Address'
                                                readOnly
                                                value={item_info.country}
                                                onChange={handleChangeStep1}
                                                />
                                            {/* <span className="error-block"> {item_info.errors.address} </span> */}
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">State </label>
                                            <input
                                                id="autocomplete"
                                                className='form-control'
                                                type='text'
                                                name='state'
                                                readOnly
                                                placeholder='Enter Address'
                                                value={item_info.state}
                                                onChange={handleChangeStep1}
                                                />
                                            {/* <span className="error-block"> {item_info.errors.address} </span> */}
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">City </label>
                                            <input
                                                id="autocomplete"
                                                className='form-control'
                                                type='text'
                                                name='city'
                                                readOnly
                                                placeholder='Enter Address'
                                                value={item_info.city}
                                                onChange={handleChangeStep1}
                                                />
                                            {/* <span className="error-block"> {item_info.errors.address} </span> */}
                                        </div>
                                        {/* <div className='form-group col-md-6'>
                                            <label className="col-form-label">Country<span className="text-danger">*</span> :</label>
                                            <select className='form-control' name='country' value={item_info.country}  onChange={handleChangeStep1} >
                                                <option>Country</option>
                                                <option value='01'>Afghanistan</option>
                                                <option value='02'>Algeria</option>
                                                <option value='03'>Austria</option>
                                                <option value='04'>Bulgaria</option>
                                                <option value='05'>Canada</option>
                                                <option value='06'>Congo</option>
                                                <option value='07'>Dominica</option>
                                                <option value='08'>France</option>
                                                <option value='09'>India</option>
                                                <option value='10'>Libya</option>
                                                <option value='11'>Malaysia</option>
                                                <option value='12'>Nepal</option>
                                            </select>
                                            <span className='error-block'>{item_info.errors.country}</span>
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">State<span className="text-danger"> *</span></label>
                                            <select className='form-control' name='state' value={item_info.state} onChange={handleChangeStep1} >
                                                <option>State</option>
                                                <option value='13'>Andhra Pradesh</option>
                                                <option value='14'>Arunachal Pradesh</option>
                                                <option value='15'>Bihar</option>
                                                <option value='16'>Chhattisgarh</option>
                                                <option value='17'>Goa</option>
                                                <option value='18'>Gujarat</option>
                                                <option value='19'>Haryana</option>
                                                <option value='20'>Himachal Pradesh</option>
                                                <option value='21'>Jammu and Kashmir</option>
                                                <option value='22'>Jharkhand</option>
                                                <option value='23'>Karnataka</option>
                                                <option value='24'>Bengaluru</option>
                                            </select>
                                            <span className='error-block'>{item_info.errors.state}</span>
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">City<span className="text-danger"> *</span></label>
                                            <select className='form-control' name='city' value={item_info.city} onChange={handleChangeStep1} >
                                                <option>City</option>
                                                <option value='24'>Ahmedabad</option>
                                                <option value='25'>Andalusia</option>
                                                <option value='26'>Cullman</option>
                                                <option value='27'>Selma</option>
                                                <option value='28'>Talladega</option>
                                                <option value='29'>Nome</option>
                                                <option value='30'>Kingman</option>
                                                <option value='31'>Winslow</option>
                                                <option value='32'>Walpi</option>
                                                <option value='33'>Tempe</option>
                                                <option value='34'>Oraibi</option>
                                                <option value='35'>Douglas</option>
                                                <option value='36'>Avondale</option>
                                            </select>
                                            <span className='error-block'>{item_info.errors.city}</span>
                                        </div> */}
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">Zip/Postal code</label>
                                            <input
                                                className='form-control'
                                                type='text'
                                                name='zipcode'
                                                placeholder='Enter Zip/Postal code'
                                                readOnly
                                                value={item_info.zipcode}
                                                onChange={handleChangeStep1}
                                                />
                                            <span className="error-block"> {item_info.errors.zipcode} </span>
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">Available for</label>
                                            <div className="custombx-wrapper">
                                                {serviceoptions.length > 0 && 
                                                    serviceoptions.map((service, key) => {
                                                        return (
                                                        <div className="custom-control custom-radio mb-10" key={key} >
                                                            <input 
                                                                type="radio" 
                                                                id={"customRadio"+key}
                                                                checked={service_id == service.id} 
                                                                value={service.id}
                                                                className="custom-control-input"
                                                                onChange={handleChangeRadio}
                                                                />
                                                            <label className="custom-control-label" htmlFor={"customRadio"+key}>{service.name}</label>
                                                        </div>
                                                        )
                                                })}
                                            </div>
                                            
                                            {/* <div className="custom-control custom-radio mb-10">
                                                <input type="radio" id="customRadio2" name="service" className="custom-control-input"/>
                                                <label className="custom-control-label" htmlFor="customRadio2">Toggle this custom radio</label>
                                            </div> */}
                                        </div>
                                        {/* <div className='form-group col-md-6'>
                                            <label className="col-form-label">Name<span className="text-danger">*</span> :</label>
                                            <div className='all-bx d-flex'>
                                                <input type='text' className='form-control' defaultValue='3900' />

                                                <span>Square feet</span>
                                            </div>
                                            <span className='error-block'></span>
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="col-form-label">Furnished</label>
                                            <div className='custombx-wrapper'>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" defaultChecked='checked' />
                                                    <label className="custom-control-label" htmlFor="customRadio1">Yes</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input"/>
                                                    <label className="custom-control-label" htmlFor="customRadio2">No</label>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='form-group col-md-12'>
                                            <label className="col-form-label">Description <span className="text-danger">*</span> </label>
                                            {/* <textarea className='form-control' row='8' defaultValue='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea.'></textarea> */}
                                            <textarea
                                                    row='8'
                                                    className='form-control'
                                                    type='text'
                                                    name='description'
                                                    placeholder='Enter Description'
                                                    value={item_info.description}
                                                    onChange={handleChangeStep1} >
                                            </textarea>
                                            <span className="error-block"> {item_info.errors.description} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='map text-center'>
                                    {/* <GoogleMapReact
                                        // bootstrapURLKeys={{ key: 'AIzaSyDG6rS4-n7Tfe3QsjO0g_IDIQiPMB8Pbrw' }}
                                        // defaultCenter={center}
                                        defaultZoom={11}
                                        >
                                        <AnyReactComponent
                                            lat={latitude}
                                            lng={longitude}
                                            // text={address}
                                        />
                                    </GoogleMapReact> */}
                                    </div>
                                </div>
                            </div> 
                        </div>           
                 
                    </div>
                </div>
            </div>
        )
    }
}

export default Step1;