import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import swal from 'sweetalert';
import axios from 'axios';
import { API_URL, IMAGE_URL } from '../config/configs';

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userName:'',
      emailId:'',
      photo:''
    }
  }

  logout= ()=> {
    localStorage.setItem('token', null)
    this.props.Logout() ;
    this.props.history.push('/')
  }

  componentWillReceiveProps (nextProps) {
    let { user } = nextProps
    this.setState({ user })
  }

  componentDidMount () {
    if (this.props.admindata.data){
        // if (this.props.editAdminProfileData && this.props.editAdminProfileData.data ) {
        //   var { first_name, last_name, email, profile_image } = this.props.editAdminProfileData.data;
        //   this.setState({userName:first_name+" "+last_name,emailId:email,photo:profile_image})
        // } else {
          var { first_name, last_name, email, profile_image } = this.props.admindata.data;
          this.setState({userName:first_name+" "+last_name,emailId:email,photo:profile_image})
            //}
    }
  }

  render () {
    let { userName,emailId,photo } = this.state
    return (
      <nav className='navbar col-lg-12 col-12 p-0 fixed-top d-flex navbar-expand-md flex-row nav-shadow'>
        <div className='d-flex align-items-center w-100'>
        <a id='sidebarCollapse'>
            <img src='/assets/images/toggle-menu.png' alt='menu' />
          </a>
          <div className='text-center navbar-brand-wrapper d-flex align-items-top justify-content-center'>
          
          <a className='navbar-brand brand-logo' href='javascript:;' title='Super Admin'>
          {this.props.permissionsList !== 'Admin' ? <h2>Property <span>Owner</span></h2> : <h2>Super <span>Admin</span></h2>}
            {/*<img src='/assets/images/logo.svg' alt='logo' />*/}
          </a>
        </div>
          <ul className='navbar-nav navbar-nav-right align-items-center'>
            <li><a href="javascript:;" title="Refresh"><img src='/assets/images/reload.svg' alt='Refresh' width="15" height="15" onClick={(e)=>window.location.reload()}/></a></li>
            <li className='nav-item dropdown profile-dropdown'>
            {/* <div className="thumb-img">
                  <img src={IMAGE_URL+photo} alt="/assets/images/no-image-user.png" />
              </div> */}
              <a className='nav-link count-indicator dropdown-toggle' id='messageDropdown' data-toggle='dropdown' aria-expanded='false' >
                {userName}
              </a>
              <div
                className='dropdown-menu nav-dropdown animated bounceInDown'
                aria-labelledby='messageDropdown'
              >
                <div className='profile-bg'>
                  <div className='img-user'>
                    <div className='btn-section'>
                      <span className='btn' onClick={() => this.props.history.push('/editprofile') } >
                        <i className="fa fa-user"></i>  My Profile
                      </span>
                      <span className='btn' onClick={() => this.props.history.push('/changepassword') } >
                       <i className="fa fa-cog"></i> Change Password
                      </span>
                      <div className="dropdown-divider mt-2"></div>
                      <span
                        className='btn'
                        onClick={this.logout.bind(this)}
                      >
                      <i className="fa fa-power-off"></i>  Logout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  admindata:state.admindata.admindata,
  permissionsList: state.admindata.admindata.rolepermissions,
});

export default  withRouter(connect(mapStateToProps,actions)(Header));