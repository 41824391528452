import React, { Component } from "react";
import Home from "../Home";
import { addUser } from "../../actions";
import { connect } from "react-redux";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { IMAGE_URL } from '../../config/configs';

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            isLoader: false,
            fullname: "",
            email: "",
            password: "",
            confirmpassword: "",
            phone_number: "",
            profile_image: "",
            profile_image_url: "/assets/images/no-image-icon-4.png",
            whatsApp_phone: "",
            snapchat_id: "",
            usertype: "",
            status: true,
            errors: {
                fullname: "",
                email: "",
                phone_number: "",
                profile_image: ""
            }
        };
    }
    componentDidMount() {
        let permissions = this.props.permissionsList;
        if (permissions !== "Admin") {
            this.props.history.push("/dashboard");
        }
        let utype = this.props.match.params.utype;
        let usertype;
        if (utype == "customer") {
            usertype = "Customer";
        } else {
            usertype = "Agent";
        }
        this.setState({ usertype: usertype });
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        }
    };
    addUser = e => {
        e.preventDefault();
        if (this.validateForm()) {
            let {
                fullname,
                email,
                password,
                profile_image,
                phone_number,
                whatsApp_phone,
                snapchat_id,
                usertype
            } = this.state;
            let data = {
                fullname,
                email,
                password,
                profile_image,
                phone_number,
                whatsApp_phone,
                snapchat_id,
                usertype
            };
            let body = data;
            this.setState({ isLoader: true });
            this.props.addUser(body, response => {
                let responsedata = response.data;
                this.setState({ isLoader: false });
                if (responsedata.status == 1) {
                    swal({
                        title: responsedata.message,
                        icon: "success"
                    }).then(willDelete => {
                        if (willDelete) {
                            this.props.history.push(`/users/${usertype.toLowerCase()}`);
                        }
                    });
                } else {
                    toast.error(responsedata.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            });
        } else {
            return false;
        }
    };
    validateForm = () => {
        let {
            fullname,
            email,
            password,
            confirmpassword,
            phone_number,
            whatsApp_phone,
            errors
        } = this.state;
        let formIsValid = true;
        let email_pattern = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        let phone_pattern = new RegExp(/^\d{9,13}$/);
        if (!fullname) {
            formIsValid = false;
            errors["fullname"] = "* Name is required!";
        }
        if (!email) {
            formIsValid = false;
            errors["email"] = "* Email Address is required!";
        } else if (typeof email !== "undefined") {
            if (!email_pattern.test(email)) {
                formIsValid = false;
                errors["email"] = "* Enter a valid email address!";
            }
        } else {
            formIsValid = true;
        }
        if (!password) {
            formIsValid = false;
            errors["password"] = "* Password is required!";
        }
        if (!confirmpassword) {
            formIsValid = false;
            errors["confirmpassword"] = "* Confirm Password is required!";
        }
        if (password !== confirmpassword) {
            formIsValid = false;
            errors["confirmpassword"] =
                "* Password and Confirm Password does not match!";
        }
        if (phone_number) {
            if (!phone_pattern.test(phone_number)) {
                formIsValid = false;
                errors["phone_number"] = "* Enter a valid Phone number!";
            }
        }
        if (whatsApp_phone) {
            if (!phone_pattern.test(whatsApp_phone)) {
                formIsValid = false;
                errors["whatsApp_phone"] = "* Enter a valid Whatsapp number!";
            }
        }
        this.setState({ errors });
        return formIsValid;
    };
    fileUploadSubmit = () => {
        var imagefile = document.querySelector("#file");
        this.getBase64(imagefile.files[0], result => {
            this.setState({ profile_image: result });
        });
        this.setState({
            profile_image_url: URL.createObjectURL(imagefile.files[0])
        });
    };
    getBase64(file, callback) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }
    render() {
        let {
            errors,
            profile_image_url,
            fullname,
            email,
            password,
            confirmpassword,
            phone_number,
            whatsApp_phone,
            snapchat_id,
            usertype
        } = this.state;
        return (
            <Home>
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-header border-bottom">
                            <h3>Add New {usertype}</h3>
                            <ul className="breadcrumb ">
                                <li className="breadcrumb-item" onClick={() => this.props.history.push("/dashboard")} >
                                    {" "} Home</li>
                                <li className="breadcrumb-item" onClick={() => this.props.history.push(`/users/${usertype.toLowerCase()}`)}>{ usertype } Management </li>
                                <li className="breadcrumb-item active">Add New {usertype} </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <form className="form-sample card transparent no-shadow">
                    <div className="card-body">
                        <div className="white-bg box-shadow br-4 border-secondary">
                            <div className="card-header border-bottom">
                                <p className="mb-0 font-15">Enter {usertype} Information</p>
                            </div>
                            <div className="card-body pt-30 light-grey">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className=" animated fadeIn mb-2 pl-40 pr-40">
                                            <div className="form-group">
                                                <div className="porfile-info">
                                                    <div className="drop-section user-profile-img">
                                                        <div className="select-image inner-image">
                                                            <div className="product-img">
                                                                <img src={profile_image_url} />
                                                            </div>
                                                        </div>
                                                        <div className="upload-overlay d-flex justify-content-center align-items-center">
                                                            <div className="upload-wrapper upload-file">
                                                                <span className='camera-image'><img src='/assets/images/camera.png' alt='Upload Image' width='36' height='31' /></span>
                                                            </div>
                                                        </div>
                                                        <label className="sr-only" htmlFor="inputFile">
                                                            File Upload
                                 </label>
                                                        <input type="file" className="form-control-file text-primary font-weight-bold" name="image" id="file" accept="image/x-png,image/gif,image/jpeg,image/jpg" multiple data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} />
                                                        <span className="error-block">
                                                            {" "}
                                                            {errors.profile_image}{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className=" animated fadeIn mb-2">
                                            <div className="form-material row user-wrapper">
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label">
                                                        Name<span className="text-danger">*</span> :
                              </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="fullname"
                                                        placeholder="Enter Name"
                                                        value={fullname}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="error-block">
                                                        {" "}
                                                        {errors.fullname}{" "}
                                                    </span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label">
                                                        Email<span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="email"
                                                        placeholder="Enter Email"
                                                        value={email}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="error-block"> {errors.email} </span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label">
                                                        Password<span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        name="password"
                                                        placeholder="Enter Password"
                                                        value={password}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="error-block">
                                                        {" "}
                                                        {errors.password}{" "}
                                                    </span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label">
                                                        Confirm Password
                              <span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        name="confirmpassword"
                                                        placeholder="Confirm Password"
                                                        value={confirmpassword}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="error-block">
                                                        {" "}
                                                        {errors.confirmpassword}{" "}
                                                    </span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label">
                                                        Phone Number<span className="text-danger" />
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="phone_number"
                                                        placeholder="Enter Phone number"
                                                        value={phone_number}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="error-block">
                                                        {" "}
                                                        {errors.phone_number}{" "}
                                                    </span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label">
                                                        Whatsapp Number<span className="text-danger" />
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="whatsApp_phone"
                                                        placeholder="Enter Whatsapp number"
                                                        value={whatsApp_phone}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="error-block">
                                                        {" "}
                                                        {errors.whatsApp_phone}{" "}
                                                    </span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label">
                                                        Snapchat Id<span className="text-danger" />
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="snapchat_id"
                                                        placeholder="Enter Snapchat ID"
                                                        value={snapchat_id}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="error-block">
                                                        {" "}
                                                        {errors.snapchat_id}{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 
                     <div className='form-group row'>
                        <label className='col-lg-2 col-sm-3 col-form-label' htmlFor='material-switch' >Status <span className="text-danger">*</span> :</label>
                        <div className='col-md-8 co-sm-7'>
                           <Toggle
                              checked={status}
                              className='custom-classname'
                              onChange={()=>
                           this.setState({status:!this.state.status})}
                           />
                        </div>
                     </div>
                     */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="card-body">
                    <div className="button-continer text-right">
                        <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() =>
                                this.props.history.push(`/users/${usertype.toLowerCase()}`)
                            }
                        >
                            {" "}
                            Cancel{" "}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary btn-large"
                            onClick={this.addUser}
                        >
                            {" "}
                            <span>Create User</span>{" "}
                        </button>
                    </div>
                </div>
                {this.state.isLoader == true ? <div className='loader-block'>
                    <img src="/assets/images/loader2.gif" />
                </div> : null }
            </Home>
        );
    }
}
const mapStateToProps = state => ({
    permissionsList: state.admindata.admindata.rolepermissions
});
export default connect(
    mapStateToProps,
    { addUser }
)(AddUser);