import React, { Component } from 'react';
import Home from '../Home';

class DetailsSuccess extends Component {
    
    render(){
        return (
            <Home>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='page-header border-bottom'>
                            <h3>Manage Houses</h3>
                            <ul className='breadcrumb '>
                            <li className='breadcrumb-item' onClick={()=>this.props.history.push('/dashboard')}>Home</li>
                            <li className='breadcrumb-item'>Add New House</li>
                            <li className='breadcrumb-item active'>User Management </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='success-page'>
                    <div className='successpage-container'>
                        <div className='image'>
                            <img src='../../assets/images/success.png' alt='Image' width='450' height='260' />
                        </div>
                        <div className='successpage-content'>
                            <h2>House listing is added successfully.</h2>
                            <h3>To see all house listing, go to the House management</h3>
                            <a href='Javascript:;' className='btn btn-primary btn-md' title='Go to House Management'>Go to House Management</a>
                        </div>
                    </div>
                </div>

                
            </Home>
        )
    }
}

    export default DetailsSuccess;