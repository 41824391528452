import { EDIT_DETAILS,EDITADMIN_PROFILE,LOG_OUT } from '../actions/types'
const INITIAL_STATE = {
  // editedDetails: {},
  // edited1Details:{}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDIT_DETAILS:
      //return Object.assign({}, state, { editedDetails: action.payload })
    case EDITADMIN_PROFILE:
      //return Object.assign({}, state, { edited1Details: action.payload })
    case LOG_OUT:
      return INITIAL_STATE
    default:
      return INITIAL_STATE
  }
}
