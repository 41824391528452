import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Pagination from "rc-pagination";
import swal from "sweetalert";
import queryString from "query-string";
import { Select } from "antd";
import Home from "../Home";
import * as actions from "../../actions";
import { IMAGE_URL, CSV_URL, EXCEL_URL } from "../../config/configs";
import "rc-pagination/assets/index.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import "antd/dist/antd.css";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

class LeadsListing extends Component {
  state = {
    isLoader: false,
    page: 1,
    pagesize: 10,
    leadsListing: [],
    total: 0,
    sort: "",
    customer_info: "",
    property_info: "",
    agent_info: "",
    service_name: "",
    status: "",
  };

  componentDidMount() {
    let permissions = this.props.permissionsList;
    if (permissions !== "Admin") {
        this.props.history.push("/dashboard");
    }
    
    $('.filterlink').click(function () {
      $('#itemlist').hide();
      $('#filterlist').stop().slideToggle();
    });
    $('.listlink').click(function () {
        $('#filterlist').hide();
        $('#itemlist').stop().slideToggle();
    });
    // setTimeout(function(){
    //   $('[data-toggle="tooltip"]').tooltip();
    // },1500);
    this.leadsListing();
  }

  //* ********** SORTING ************************//
  onSort = (name, value) => {
    if (value) {
      let sort = {};
      sort[name] = value;
      this.setState({ sort: sort }, () => {
        this.leadsListing();
      });
    }
  };

  //* **********  USER LISTING  ************************//
  leadsListing = () => {
    let url = "admin/leads";
    let method = "post";
    let {
      page,
      pagesize,
      customer_info,
      property_info,
      agent_info,
      service_name,
      sort,
      status
    } = this.state;
    let data = {
      page,
      pagesize,
      customer_info,
      property_info,
      agent_info,
      service_name,
      sort,
      status
    };
    var body = data;
    var token = localStorage.getItem("token");

    this.setState({ isLoader: true });
    this.props.commonApiCall(
      url,
      method,
      body,
      token,
      null,
      this.props,
      response => {
        let responsedata = response.data;
        let userdata = response.data.data;
        this.setState({ isLoader: false });
        if (responsedata.status == 0) {
          this.setState({ leadsListing: [] });
          toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
        } else {
          this.setState({
            leadsListing: userdata.items,
            total: userdata.total,
            length: userdata.items.length
          });
        }
      }
    );
  };

  //* **********  RESET LISTING  ************************//
  resetListing = e => {
    this.setState(
      {
        customer_info: "",
        property_info: "",
        agent_info: "",
        service_name: "",
        status: ""
      },
      () => this.leadsListing()
    );
  };

  // ########################### PAGINATION #########################
  paginationChange(page, pagesize) {
    this.setState(
      {
        page: page,
        pagesize: pagesize
      },
      () => this.leadsListing()
    );
  }

  handleChangePageSize = value => {
    this.setState({ pagesize: value, page: 1 }, () => this.leadsListing());
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // ########################### download CSV #########################
  downloadLeads = format => {
    let {
      customer_info,
      property_info,
      agent_info,
      service_name,
      sort,
      status
    } = this.state;
    let data = {
      customer_info,
      property_info,
      agent_info,
      service_name,
      sort,
      status,
      download: format
    };
    let body = data;
    let url = "admin/leads";
    let method = "post";
    let token = localStorage.getItem("token");
    this.setState({ isLoader: true });
    this.props.commonApiCall(
      url,
      method,
      body,
      token,
      null,
      this.props,
      response => {
        var responsedata = response.data.data;
        this.setState({ isLoader: false });
        if (responsedata.fileName) {
          if (format === "csv") {
            window.open(CSV_URL + responsedata.fileName, "_blank");
            //FileDownload(CSV_URL  +  data.filePathAndName, data.filePathAndName);
          } else {
            window.open(EXCEL_URL + responsedata.fileName, "_blank");
            //FileDownload(EXCEL_URL  +  data.filePathAndName, data.filePathAndName);
          }
        } else {
          toast.error(responsedata.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      }
    );
  };

  addDefaultSrcForUser(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  addDefaultSrcForProperty(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render() {
    const Option = Select.Option;
    let {
      customer_info,
      property_info,
      agent_info,
      service_name,
      status,
      leadsListing,
      pagesize,
      page,
      sort,
      total,
      length
    } = this.state;
    // console.log(typeof leadsListing, "leadsListing");
    return (
      <Home>
        <div className="row">
          <div className="col-md-12">
            <div className="page-header border-bottom">
              <h3>Leads Management</h3>
              <ul className="breadcrumb ">
                <li
                  className="breadcrumb-item"
                  onClick={() => this.props.history.push("/dashboard")}
                >
                  Home
                </li>
                <li className="breadcrumb-item active">Leads Management </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="animated fadeIn card transparent no-shadow">
            <div className="row data-filter justify-content-end card-body">
              <div className="col-md-12 col-lg-7 text-md-right">
                <div className="text-right d-flex flex-wrap justify-content-end">
                    <div>
                    <p className='export-text'>Export Users Data</p>
                      <a className="btn btn-primary btn-image" href="#" onClick={this.downloadLeads.bind(this, "excel")} ><img src="/assets/images/xls-excel-file.svg" alt="Excel" width="20" height="20"/> Excel </a>
                      <a className="btn btn-primary btn-image ml-2" href="#" onClick={this.downloadLeads.bind(this, "csv")} ><img src="/assets/images/csv-file.svg" alt="CSV" width="20" height="20"/> CSV </a>
                    </div>

                  {/* <div className="dropdown">
                    <button className="nav-link  btn btn-teal dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                      <span>Tools</span>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                      <a className="dropdown-item" href="#" onClick={this.downloadLeads.bind(this, "excel")} > Export to Excel </a> 
                      <a className="dropdown-item" href="#" onClick={this.downloadLeads.bind(this, "csv")} > Export to CSV </a>
                    </div>
                  </div> */}
                  <div className='pl-30 border-left ml-30'>
                    <button className="nav-link pull-right btn btn-danger filterlink" type="button" >
                      <i className="fa fa-filter mr-0" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
      
        
          <div className="card-body">
            <div className="filter-list" id="filterlist">
            <div className=' border white-bg br-4 card-body'>
            <div className="row">
              <div className="col-xl-2 col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Customer Info</label>
                  <input
                    className="form-control"
                    type="text"
                    name="customer_info"
                    placeholder="Enter Name or Email"
                    value={customer_info}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Property Info</label>
                  <input
                    className="form-control"
                    type="text"
                    name="property_info"
                    placeholder="Enter Property Name or Address"
                    value={property_info}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Agent Info</label>
                  <input
                    className="form-control"
                    type="text"
                    name="agent_info"
                    placeholder="Enter Agent Name"
                    value={agent_info}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Type</label>
                  <select
                    className="form-control"
                    onChange={e =>
                      this.setState({ service_name: e.target.value })
                    }
                    value={service_name}
                  >
                    <option value="">Select</option>
                    <option value="Booking">Booking</option>
                    <option value="Buy">Buy</option>
                    <option value="Diving">Diving</option>
                    <option value="Port services">Port services</option>
                    <option value="Rent">Rent</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Status</label>
                  <select
                    className="form-control"
                    onChange={e => this.setState({ status: e.target.value })}
                    value={status}
                  >
                    <option value="">Select</option>
                    <option value="Fake">Fake</option>
                    <option value="In-Discussion">In-Discussion</option>
                    <option value="Lost">Lost</option>
                    <option value="Success">Success</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

            <div className="pull-right filter-button card-body pr-0">
              <div className="dropdown ml-2" />
              <button className="nav-link  btn btn-outline-secondary ml-2" type="button" onClick={this.resetListing} > Reset </button>
              <button className="nav-link  btn btn-primary ml-2" type="button" onClick={this.leadsListing} > Apply Filter </button>
            </div>
          </div>

            <div className="table-responsive border br-4 white-bg">
              <table className="table dataTable with-image custom-table table-hover">
                <thead>
                  <tr>
                    <th sortable-column="id">ID</th>
                    <th sortable-column="customer_name"> Customer Info {sort["customer_name"] == "ASC" ? (
                        <i aria-hidden="true" onClick={this.onSort.bind( this, "customer_name", "DESC" )} className="fa fa-arrow-up" />
                      ) : (
                        <i aria-hidden="true" onClick={this.onSort.bind( this, "customer_name", "ASC" )} className="fa fa-arrow-down" />
                      )}
                    </th>
                    <th sortable-column="item_name"> Property Info {sort["item_name"] == "ASC" ? (
                        <i aria-hidden="true" onClick={this.onSort.bind(this, "item_name", "DESC")} className="fa fa-arrow-up" />
                      ) : (
                        <i aria-hidden="true" onClick={this.onSort.bind(this, "item_name", "ASC")} className="fa fa-arrow-down" />
                      )}
                    </th>
                    <th sortable-column="service_name"> Type {sort["service_name"] == "ASC" ? (
                        <i aria-hidden="true" onClick={this.onSort.bind( this, "service_name", "DESC" )} className="fa fa-arrow-up" />
                      ) : (
                        <i aria-hidden="true" onClick={this.onSort.bind( this, "service_name", "ASC" )} className="fa fa-arrow-down" />
                      )}
                    </th>
                    <th sortable-column="agent_name"> Agent Info {sort["agent_name"] == "ASC" ? (
                        <i aria-hidden="true" onClick={this.onSort.bind(this, "agent_name", "DESC")} className="fa fa-arrow-up" />
                      ) : (
                        <i aria-hidden="true" onClick={this.onSort.bind(this, "agent_name", "ASC")} className="fa fa-arrow-down" />
                      )}
                    </th>

                    <th sortable-column="item_price"> Booking Price {sort["item_price"] == "ASC" ? (
                        <i aria-hidden="true" onClick={this.onSort.bind(this, "item_price", "DESC")} className="fa fa-arrow-up" />
                      ) : (
                        <i aria-hidden="true" onClick={this.onSort.bind(this, "item_price", "ASC")} className="fa fa-arrow-down" />
                      )}
                    </th>
                    <th sortable-column="lead_status"> Status {sort["lead_status"] == "ASC" ? (
                        <i aria-hidden="true" onClick={this.onSort.bind( this, "lead_status", "DESC" )} className="fa fa-arrow-up" />
                      ) : (
                        <i aria-hidden="true" onClick={this.onSort.bind(this, "lead_status", "ASC")} className="fa fa-arrow-down" />
                      )}
                    </th>
                    {/* <th width="6%" align="center"> Actions </th> */}
                  </tr>
                </thead>
                <tbody>
                  { leadsListing.length > 0 && leadsListing.map((lead, Key) => {
                    let filesplit, thumbimage, custfilesplit, custthumbimg, agentfilesplit, agentthumbimg;
                    if(lead.item_image) {
                      filesplit = lead.item_image.split('.');
                      thumbimage = filesplit[0]+'_thumb.'+filesplit[1];
                    }

                    if(lead.customer_image) {
                      custfilesplit = lead.customer_image.split('.');
                      custthumbimg = custfilesplit[0]+'_thumb.'+custfilesplit[1];
                    }

                    if(lead.agent_image) {
                      agentfilesplit = lead.agent_image.split('.');
                      agentthumbimg = agentfilesplit[0]+'_thumb.'+agentfilesplit[1];
                    }
                    return (
                      <tr
                        key={lead.unique_lead_id}
                        key={Key}
                        className="animated fadeIn"
                      >
                        <td>{lead.unique_lead_id}</td>
                        <td>
                        <div className='block-imagecontent'>
                            <div className="thumb-img">
                              <img width='50' height='50' src={ lead.customer_image ? IMAGE_URL + custthumbimg : "assets/images/no-image-user.png" } alt="Customer Image" onError={this.addDefaultSrcForUser} />
                            </div>
                            <div className='userinfo-block'>
                                <p className='usename'>{lead.customer_name}</p>
                                <p className='usemail'>{lead.customer_email}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href='Javascript:;' className='block-imagecontent' onClick={() => this.props.history.push( "/leads/" + lead.lead_id ) }>
                            <div className="thumb-img">
                              <img width='50' height='50' src={ lead.item_image ? IMAGE_URL + thumbimage : "assets/images/no-image-user.png" } alt="Property Image" onError={this.addDefaultSrcForProperty} />
                            </div>
                            <div className='userinfo-block'>
                              <p className='usename text-blue'>{lead.item_name}</p>
                              <p className='usemail'>{lead.item_address}</p>
                            </div>
                          </a>
                          
                        </td>
                        <td className='text-blue'>{lead.service_name} </td>
                        <td>
                        <div className='block-imagecontent'>
                          <div className="thumb-img">
                              <img width='50' height='50' src={ lead.agent_image ? IMAGE_URL + agentthumbimg : "assets/images/no-image-user.png" } alt="Agent Image" onError={this.addDefaultSrcForUser} />
                          </div>
                          <div className='userinfo-block'>
                              <p className='usename'>{lead.agent_name}</p>
                            </div>
                        </div>
                          
                          
                        </td>
                        <td>${lead.item_price} onwards</td>
                        <td>{lead.lead_status} </td>
                        {/* <td align="center">
                          <button
                            onClick={() => this.props.history.push( "/lead-details/" + lead.lead_id ) }
                          >
                            <i
                              className="fa fa-eye text-primary"
                              aria-hidden="true"
                              data-toggle="tooltip"
                              title="View"
                            />
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                  { leadsListing.length <= 0 ? <tr ><td text="center" colSpan='8'> 
                    <div className='no-datablock p-30 text-center'>
                    <div className='no-dataimage'><img src="/assets/images/exclamation-mark.png" /></div>
                    <h2 className='no-data'>No Record Found</h2>
                    </div>
                  </td></tr> : null }
                </tbody>
              </table>
            </div>
            <div className="table-footer text-right">
              <label>Showing</label>
              <Select
                showSearch
                placeholder={<b> {total <= 5 ? total : length}</b>}
                optionFilterProp="children"
                onSelect={this.handleChangePageSize.bind(this)}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              >
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
                <Option value={15}>15</Option>
                <Option value={50}>50</Option>
              </Select>
              <label>Out of {total} Leads</label>
              <div className="pagination-list">
                <Pagination
                  className="ant-pagination"
                  pageSize={pagesize}
                  current={page}
                  total={total}
                  onChange={this.paginationChange.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoader == true ? <div className='loader-block'>
          <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
    );
  }
}

const mapStateToProps = state => ({
  getadmindata: state.user.userdatails,
  permissionsList: state.admindata.admindata.rolepermissions
});
export default connect(
  mapStateToProps,
  actions
)(LeadsListing);
