import {USER_DETAILS,HOMEPAGE_DETAILS} from '../actions/types'

const INITIAL_STATE={
    userdatails:{
    },
    homepagedetails:{
    }
}
export default (state=INITIAL_STATE,action)=>{
    
    switch(action.type){
        case USER_DETAILS:
        return Object.assign({},state,{userdatails:action.payload.data})
        case HOMEPAGE_DETAILS :
        return Object.assign({},state,{userdatails:action.payload.data})
        default:
        return state;
    }
}