import React, { Component } from 'react';
import { IMAGE_URL } from '../config/configs';

class ResetPasswordMail extends Component {
    render(){
        return (  
            <div className='container-scroller'>
        <div className='page-body-wrapper full-page-wrapper'>
          <div className='content-wrapper d-flex align-items-center login-full-bg auth'>
            <div className='row w-100 margin-l-0'>
              <div className='col-12 col-lg-6 mx-auto authentication-form color-white'>
                <div className=''>
                <div className='login-header mb-5'>
                  <img src='/assets/images/logo.svg' alt='logo' width='18' height='22' className='logo' />
                  <h1 className='logo-text'>Super Admin</h1>
                  <h2>E-mail sent successfully</h2>
                  <p>We have sent a reset password link to your email jo****@gmail.com <br/>
                    Please open email and click on reset password link to set a new password</p>
                    <div className='pt-30 text-center'>
                      <button className='btn btn-primary font-weight-medium btn-md' type='submit' title='Sign in' > Sign in </button>
                    </div>
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
        {/* page-body-wrapper ends */}
      </div>
        );
    }
}

export default ResetPasswordMail