import React, { Component } from 'react';
//import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import * as _ from "lodash";
import 'antd/dist/antd.css';

import { Select } from 'antd';
import * as actions from '../../actions';
import Home from '../Home';
//import Toggle from 'react-toggle';
//import axios from 'axios';
import { IMAGE_URL, CSV_URL, EXCEL_URL } from '../../config/configs';
import $ from 'jquery';
//import { isEmpty } from "lodash";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
//const FileDownload = require('js-file-download');

class categoryList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoader: false,
      page: 1,
      pagesize: 10,
      userListing: [],
      multipleDelete:[],
      total: 0,
      sort: '',
      first_name: '',
      last_name: '',
      email: '',
      phonenumber: '',
      status: '',
      changestatus:'Select',
      adminusersaccess: {}
    }
  }

  componentDidMount() {
    let permissions = this.props.permissionsList;
    if(permissions !== 'Admin'){
      this.props.history.push('/dashboard');
    }
    this.adminUsersListing();

    $('.filterlink').click(function () {
      $('#itemlist').hide();
      $('#filterlist').stop().slideToggle();
    });
    $('.listlink').click(function () {
        $('#filterlist').hide();
        $('#itemlist').stop().slideToggle();
    });
    // setTimeout(function(){
    //   $('[data-toggle="tooltip"]').tooltip();
    // },1500);
  }

  //************************ SORTING ************************//
  onSort = (name, value) => {
    if (value) {
      let sort = {};
      sort[name] = value;
      this.setState({ sort: sort },
        () => {
          this.adminUsersListing();
        }
      )
    }
  }

  //************************  USER LISTING  ************************//
  adminUsersListing = () => {

    let url = 'admin/getcategories';
    let method = 'post';
    let { page, pagesize, first_name, last_name, email, sort, status } = this.state;
    let data = { page: page, pagesize: pagesize, first_name, last_name, email, sort, status };
    let body = data;
    let token = localStorage.getItem('token');

    this.setState({ isLoader: true });
    this.props.commonApiCall(url, method,body, token, null, this.props, response => {
      let responsedata = response.data;
      console.log(responsedata)

      let userdata = response.data.data;
      this.setState({ isLoader: false });
      if (responsedata.status == 0) {
        this.setState({ userListing: [] });
        toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      } else {
        this.setState({ userListing: userdata, total: userdata.total, length: userdata.length });
      }

    })
  }

  deleteUser = (category_id) => {

  //  alert(category_id)

    let url = 'admin/deleteCategory';
    let method = 'post';
    let { page, pagesize, sort, status } = this.state;
    let data = { page: page, pagesize: pagesize, category_id, sort, status };
    let body = data;
    let token = localStorage.getItem('token');

    this.setState({ isLoader: true });
    this.props.commonApiCall(url, method,body, token, null, this.props, response => {
      let responsedata = response.data;
   //   console.log('shhow'+responsedata)

      let userdata = response.data.data;
      this.setState({ isLoader: false });
      this.adminUsersListing();
      // if (responsedata.status == 0) {
      //   this.setState({ userListing: [] });
      //   toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      // } else {
      //   this.setState({ userListing: userdata, total: userdata.total, length: userdata.length });
      // }

    })
  }

  //************************  CHANGE STATUS  ************************//
  userStatusChange = () => {
    let delArr = this.state.multipleDelete;
    if(this.state.changestatus == 'Select') {
      toast.error('Please select any option!', { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
    } else {
      delArr = delArr.filter( Number );
      if(_.isEmpty(delArr)) {
        toast.error('Please select any Owners!', { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });return false;
      }
      let body = { category_id: delArr, changestatus: this.state.changestatus };
  
      let url = 'admin/changeCategoryStatus';
      let method = 'post';
      let token = localStorage.getItem('token');

      this.setState({ isLoader: true });
      this.props.commonApiCall( url, method, body, token, null, this.props, response => {
        let responsedata = response.data;
        this.setState({ isLoader: false });
        if (responsedata.status == 1) {
          swal(responsedata.message, '', 'success')
          this.adminUsersListing();
        } else {
          toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
        }
        this.setState({ multipleDelete: [], changestatus:'Select'})
      })
    }
  }

  //* **********  SINGLE USER DETAILS  ************************//
  getOwnerDetails = (Id) => {
    this.props.history.push('/category/edit/' + Id)
  }

  //************************  RESET LISTING  ************************//
  resetListing = (e) => {
    let url = 'admin/getcategories';
    let method = 'post';
    this.setState({ first_name: '', last_name: '', email: '', status: '', page: 1 });
    let body = { page: 1, pagesize: this.state.pagesize };
    let token = localStorage.getItem('token');
    this.setState({ isLoader: true });
    this.props.commonApiCall(url, method, body, token, null, this.props, response => {
      let responsedata = response.data;
      let userdata = response.data.data;
      this.setState({ isLoader: false });

      if (responsedata.status === 0) {
        this.setState({ userListing: [] })
      } else {
        this.setState({ userListing: userdata, total: userdata.total, length: userdata.length })
      }
    })
  }

  // ########################### download CSV ######################### //

  downloadadmindetails = (format) => {
    let { first_name, last_name, email, sort, status } = this.state;
    let data = { first_name, last_name, email, sort, status, download: format };
    let body = data;

    let url = 'admin/adminListing';
    let method = 'post';
    let token = localStorage.getItem('token');
    this.setState({ isLoader: true });
    this.props.commonApiCall(url, method, body, token, null, this.props, response => {
      var responsedata = response.data.data;
      this.setState({ isLoader: false });
      if (responsedata.fileName) {
        if (format === 'csv') {
          window.open(CSV_URL + responsedata.fileName, '_blank');
        } else {
          window.open(EXCEL_URL + responsedata.fileName, '_blank');
        }
      } else {
        toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      }
    });
  }

  selectAllcheck=()=>{
    let delarray = this.state.multipleDelete;
    this.onCheckbox();
    let { userListing } = this.state;
    userListing.map(each=>{
      if (!delarray.includes(each.id)) {
        delarray.push(each.id)
      } else {
        delarray.splice(delarray.indexOf(each.id), 1)
      }
    })
  }

  onCheckbox(id, val) {
    let delarray = this.state.multipleDelete;
    if (!delarray.includes(id)) {
      delarray.push(id);
    } else {
      delarray.splice(delarray.indexOf(id), 1);
    }

    // if (delarray.length != this.state.userListing.length) {
    //   this.setState({ checked: false });
    // }
    if (this)
      this.setState({ multipleDelete: delarray })

  }

  checkArray(id) {
    let { multipleDelete } = this.state;
    if (multipleDelete.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  paginationChange(page, pagesize) {
    this.setState({
      page: page,
      pagesize: pagesize
    }, () => this.adminUsersListing());
  }

  handleChangePageSize = (value) => {
    this.setState({ pagesize: value, page: 1 }, () => this.adminUsersListing());
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  addDefaultSrc(ev) {
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render() {
    const Option = Select.Option;
    let { first_name, last_name, email, status, changestatus, userListing, pagesize, page, sort, total, length } = this.state;

    return (
      <Home>
        <div className='row'>
          <div className='col-md-12'>
            <div className='page-header border-bottom'>
              <h3>Admin</h3>
              <ul className='breadcrumb '>
                <li className='breadcrumb-item' onClick={() => this.props.history.push('/dashboard')}>Home</li>
                <li className='breadcrumb-item active'>Category</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='animated fadeIn card'>
        
            <div className="row data-filter justify-content-end card-body">
              <div className="col-12 text-md-right">
                <div className="text-right d-flex flex-wrap justify-content-end">
                  {/* <div>
                    <p className='export-text'>Export Owner Data</p>
                      <a className="btn btn-primary btn-image" href="#" onClick={this.downloadadmindetails.bind(this, "excel")}><img src="/assets/images/xls-excel-file.svg" alt="Excel" width="20" height="20"/> Excel</a>
                      <a className="btn btn-primary btn-image ml-2" href="#" onClick={this.downloadadmindetails.bind(this, "csv")}><img src="/assets/images/csv-file.svg" alt="CSV" width="20" height="20"/> CSV</a>
                  </div> */}
                  <div className='pl-30 border-left ml-30'>
                  <Select
                    showSearch
                    placeholder="select"
                    optionFilterProp="children"
                    className="applyselect"
                    value={changestatus}
                    onSelect={(value) => this.setState({ changestatus: value })}
                  >
                    <Option value="1">Active</Option>
                    <Option value="0">InActive</Option>
                  </Select>

                  <button type="button" className="btn btn-primary ml-2 apply_btn" onClick={this.userStatusChange}>Apply</button>
                  </div>
                <div className='pl-30 border-left ml-30'>
                  <button type="button" className="btn btn-primary btn-image" onClick={() => this.props.history.push('/category/add')}><i className="fa fa-plus" /> <span> Add Category</span></button>
                </div>
                  

                {/* <button type="button" className="nav-link  btn btn-teal listlink" >
                  <i className="fa fa-columns mr-0" aria-hidden="true" />
                </button> */}
                {/* <div className='pl-30 border-left ml-30'>
                  <button className="nav-link pull-right btn btn-danger filterlink" type="button" >
                    <i className="fa fa-filter mr-0" aria-hidden="true" />
                  </button>
                </div> */}
                
              </div>
            </div>
          </div>
   

        <div className='card-body'>

        <div className="filter-list"  id="filterlist">
         <div className=' border white-bg br-4 card-body'>
          <div className="row">            
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
              <div className="form-group">
                <label>First name</label>
                <input
                  className='form-control'
                  type='text'
                  name='first_name'
                  placeholder='Enter Name'
                  value={first_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Last name</label>
                <input
                  className='form-control'
                  type='text'
                  name='last_name'
                  placeholder='Enter Name'
                  value={last_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Email</label>
                <input
                  className='form-control'
                  type='text'
                  name='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Status</label>
                <select className="form-control" onChange={(e)=>this.setState({status:e.target.value})} value={status}>
                  <option value=''>Select</option>
                  {/* <option value='Pending'>Pending</option> */}
                  <option value='1'>Active</option>
                  <option value='0'>InActive</option>
                </select>
              </div>
            </div>
          </div>
       
            </div>
            <div className="pull-right filter-button card-body pr-0">
            <div className="dropdown ml-2">
            </div>
            <button className="nav-link  btn btn-outline-secondary ml-2" type="button" onClick={this.resetListing} > Reset </button>
            <button className="nav-link  btn btn-primary ml-2" type="submit" onClick={this.adminUsersListing} > Apply Filter </button> 
            </div>
        </div>

          <div className='table-responsive border br-4 white-bg'>
            <table className='table dataTable with-image custom-table table-hover'>
              <thead>
                <tr>
                  <th>
                    <div className='checkbox'>
                      <label>
                      <input type="checkbox" className="form-check-input" checked={this.checkArray()} onChange={()=>this.selectAllcheck()} />
                        <span />
                        <i className='input-helper' />
                      </label>
                    </div>
                  </th>
                  <th sortable-column="id">
                    ID
                   
                  </th>
                  <th>Category Name</th>
                  
                  {/* <th > Category Description <i aria-hidden='true'  /> </th>
                  <th> Color code <i aria-hidden='true' /></th> */}
                  <th sortable-column="status"> 
                    Status
                    { (sort['status'] == 'ASC') ? 
                        <i aria-hidden='true' onClick={this.onSort.bind(this, 'status', 'DESC')} className="fa fa-arrow-up" />
                      : <i aria-hidden='true' onClick={this.onSort.bind(this, 'status', 'ASC')} className="fa fa-arrow-down" /> }
                  </th>
                  <th> Actions</th>
                </tr>
              </thead>
              <tbody>
                {userListing.length > 0 && userListing.map((adminuser, key) => {
                   let filesplit, thumbimage;
                   if(adminuser.profile_image) {
                     filesplit = adminuser.profile_image.split('.');
                     thumbimage = filesplit[0]+'_thumb.'+filesplit[1];
                   }
                  return (
                    <tr key={key}>
                      <td>
                        <div className='checkbox'>
                          <label>
                          <input type="checkbox" className="form-check-input" checked={(this.checkArray(adminuser.id)) ? true : false} onChange={()=>this.onCheckbox(adminuser.id)} />
                            <span />
                            <i className='input-helper' />
                          </label>
                        </div>
                      </td>
                      <td>{ key+1 + (page-1) * pagesize}</td>
                      {/* <td>
                          <a href='Javscript:;' className='block-imagecontent' onClick={() => this.getOwnerDetails(adminuser.id)}>
                            <div className="thumb-img">
                              <img width='50' height='50' src={adminuser.profile_image ? IMAGE_URL+thumbimage+'?'+Date.now().toString() : "/assets/images/no-image-user.png"} alt="Profile image" onError={this.addDefaultSrc}/>
                            </div>
                            <div className='userinfo-block'>
                                <p className='usename'>{adminuser.first_name} {adminuser.last_name}</p>
                                <p className='usemail'>{adminuser.email}</p>
                            </div>
                          </a>
                          
                      </td> */}
                      
                      <td className='text-muted'>{adminuser.name ? adminuser.name : 'No Data'}</td>
                      {/* <td className='text-muted'>{adminuser.description ? adminuser.description : 'No Data'}</td>
                      <td className='text-muted'>{adminuser.color_code ? adminuser.color_code : 'No Data'}</td> */}
                      <td>
                      {/* {adminuser.status}  */}
                      {adminuser.status == '1' ? <span className='badge badge-success badge-pill'>Active</span> : <span className='badge badge-warning badge-pill'>Inactive</span>}
                      </td>
                      <td>
                        <button onClick={() => this.getOwnerDetails(adminuser.id)} >
                          <i className="fa fa-edit text-muted" aria-hidden="true" data-toggle="tooltip"  title="Edit"></i>
                        </button>
                        <button onClick={() => this.deleteUser(adminuser.id)}>
                          <i className='fa fa-trash text-danger' aria-hidden='true' data-toggle="tooltip"  title="Delete" />
                        </button>
                      </td>
                    </tr>
                  )
                })}
                { userListing.length <= 0 ? <tr ><td text="center" colSpan='8'> 
                    <div className='no-datablock p-30 text-center'>
                    <div className='no-dataimage'><img src="/assets/images/exclamation-mark.png" /></div>
                    <h2 className='no-data'>No Record Found</h2>
                    </div>
                  </td></tr> : null }
              </tbody>
            </table>
          </div>
          {/* <div className="table-footer text-right">
            <label>Showing</label>
            <Select showSearch placeholder={<b> {total <= 5 ? total : length}</b>} optionFilterProp="children"
              onSelect={this.handleChangePageSize.bind(this)} onFocus={this.handleFocus} onBlur={this.handleBlur} >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={15}>15</Option>
              <Option value={50}>50</Option>
            </Select>
            <label>Out of {total} Category</label>
            <div className="pagination-list">
              <Pagination className="ant-pagination" pageSize={pagesize} current={page} total={total} onChange={this.paginationChange.bind(this)} locale={localeInfo}/>
            </div>
          </div> */}

        </div>
        </div>
        {this.state.isLoader == true ? <div className='loader-block'>
            <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
    )
  }
}

const mapStateToProps = state => ({
  getadmindata: state.user.userdatails,
  permissionsList: state.admindata.admindata.rolepermissions,

});
export default connect(mapStateToProps, actions)(categoryList)
