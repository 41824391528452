import React, { Component } from "react";
import Home from "../Home";

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }

  isValidated = e => {
    let { step4_data } = this.props;
    let errors = [];
    let formIsValid = true;
    let pricepattern = /^[0-9]+$/;

    if (!step4_data.price) {
      formIsValid = false;
      errors["price"] = "* Price is required!";
    } else if (typeof step4_data.price !== "undefined") {
      if (!pricepattern.test(step4_data.price)) {
        formIsValid = false;
        errors["price"] = "* Enter a valid Price!";
      } else if (step4_data.price <= 0) {
        formIsValid = false;
        errors["price"] = "* Price needs to be greater then 0!";
      }
    }

    // if (!step4_data.price_unit) {
    //     formIsValid = false;
    //     errors["price_unit"] = "* Price unit is required!";
    // }

    if (!step4_data.owner_id) {
      formIsValid = false;
      errors["owner_id"] = "* Owner is required!";
    }

    if (!step4_data.remarks) {
      formIsValid = false;
      errors["remarks"] = "* Remarks is required!";
    }

    let stepErrors = this.props.step4_data;
    stepErrors["errors"] = errors;
    this.setState({ step4_data: stepErrors });
    return formIsValid;
  };

  render() {
    const {
      step4_data,
      owneroptions,
      category_name,
      permissions,
      handleChangeStep4
    } = this.props;
    return (
      <div className="card transparent no-shadow step4">
        <div className="card-body">
          <div className="white-bg box-shadow br-4 border-secondary">
            <div className="card-header border-bottom">
              <p className="mb-0 font-16">
                Configure {category_name} Price & Owner
              </p>
            </div>
            <div className="card-body pt-30 light-grey form-material">
              <div className="row">
                {/* <div className="col-md-3 form-group">
                            <label className="col-form-label">Available for</label>
                            <div className='custombx-wrapper'>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio1" name="customRadio" defaultChecked='checked' className="custom-control-input"/>
                                    <label className="custom-control-label" htmlFor="customRadio1">Rent</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input"/>
                                    <label className="custom-control-label" htmlFor="customRadio2">Sale</label>
                                </div>
                            </div>
                        </div> */}
                <div className="col-md-3 form-group">
                  <label className="col-form-label">
                    Price<span className="text-danger">*</span> :
                  </label>
                  <div className="all-bx d-flex">
                    <span>$</span>
                    <input
                      type="text"
                      className="form-control"
                      name="price"
                      value={step4_data.price}
                      onChange={handleChangeStep4}
                    />
                    <span>Per</span>
                    <select
                      className="form-control"
                      name="price_unit"
                      value={step4_data.price_unit}
                      onChange={handleChangeStep4}
                    >
                      <option value="">Select</option>
                      <option value="Month">Month</option>
                      <option value="For one">For one</option>
                      <option value="For two">For two</option>
                    </select>
                  </div>
                  <span className="error-block">{step4_data.errors.price}</span>
                  <span className="error-block">
                    {step4_data.errors.price_unit}
                  </span>
                </div>

                {/* <div className='col-md-3 form-group'>
                        <label className="col-form-label">Maintenance</label>
                            <div className='all-bx d-flex'>
                                <span>$</span>
                                <input type='text' className='form-control' />
                                <span>Per</span>
                                <select className='form-control'>
                                    <option>Month</option>
                                    <option value='01'>2011</option>
                                    <option value='02'>2012</option>
                                    <option value='03'>2013</option>
                                    <option value='04'>2014</option>
                                    <option value='05'>2015</option>
                                    <option value='06'>2016</option>
                                    <option value='07'>2017</option>
                                    <option value='08'>2018</option>
                                    <option value='09'>2019</option>
                                </select>
                            </div>
                        </div>  */}

                <div className="col-md-3 form-group">
                  <label className="col-form-label">
                    Owner<span className="text-danger">*</span> :
                  </label>
                  {permissions == "Admin" ? (
                    <select
                      className="form-control"
                      name="owner_id"
                      value={step4_data.owner_id}
                      onChange={handleChangeStep4}
                    >
                      <option>Owner</option>
                      {owneroptions.length > 0 &&
                        owneroptions.map((owner, key) => {
                          return (
                            <option value={owner.owner_id} key={key}>
                              {owner.owner_name}
                            </option>
                          );
                        })}
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      name="owner_id"
                      value={step4_data.owner_id}
                      onChange={handleChangeStep4}
                      disabled
                    >
                      <option>{step4_data.owner_name}</option>
                    </select>
                  )}
                  <span className="error-block">
                    {step4_data.errors.owner_id}
                  </span>
                </div>

                <div className="col-md-6 form-group">
                  <label className="col-form-label">
                    Remarks<span className="text-danger">*</span> :
                  </label>
                  <textarea
                    className="form-control"
                    row="8"
                    className="form-control"
                    type="text"
                    name="remarks"
                    placeholder="Enter Remarks"
                    value={step4_data.remarks}
                    onChange={handleChangeStep4}
                  ></textarea>
                  <span className="error-block">
                    {" "}
                    {step4_data.errors.remarks}{" "}
                  </span>
                </div>

                {/* <div className='col-md-3 form-group'>
                            <label className="col-form-label">Total Rent Price</label>
                            <span className='text-primary'>$4000 per Month</span>
                        </div>  */}
              </div>
            </div>
            {/* <a href='Javascript:;' className='btn' onClick={() => this.props.history.push('/AddDetailsSuccess/')}>Test</a> */}
          </div>
        </div>
        {/* <div className="footer-buttons text-right button-blocks"> */}
        {/* <button type="button" className="btn btn-prev btn-primary btn-primary" >Save</button> */}
        {/* <button type="button" className="btn btn-primary btn-primary btn-next btn-icon ml-2" onClick={(e) => { this.isValidated(); addProperty(4);}} >Publish</button> */}
        {/* </div> */}
      </div>
    );
  }
}

export default Step4;
