import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'rc-pagination/assets/index.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css'
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import swal from 'sweetalert';
import * as actions from '../../actions';
import {isEmpty} from "lodash";
import 'antd/dist/antd.css';
import { Select } from 'antd';
import Home from '../Home';
import axios from 'axios';
import { API_URL,IMAGE_URL,CSV_URL,EXCEL_URL } from '../../config/configs';
import $ from 'jquery';
import queryString from 'query-string';
import moment from 'moment';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const  FileDownload  =  require('js-file-download')

class EmailManagement extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoader: false,
      page: 1,
      pagesize: 10,
      emailListing: [],
      multipleDelete:[],
      searchItem: '',
      total:0,
      sort: {},
      selectedOption:'Select',
      sortData: { id:false,template_title: false, updated_at: false, status:false },
      ids:true,
      name: true,      
      content: true, 
      status: true, 
      selectedId:'',
      selectedName:'',
      selectedContent:'',
      selectStatus:'',
      length:'',
      cmsPagesAccess:{}
    }
  }
  
  componentDidMount () {
    $('.filterlink').click(function () {
      $('#itemlist').hide();
      $('#filterlist').stop().slideToggle();
    });
    $('.listlink').click(function () {
        $('#filterlist').hide();
        $('#itemlist').stop().slideToggle();
    });
    // setTimeout(function(){
    //   $('[data-toggle="tooltip"]').tooltip();
    // },1500);
    
    this.emailListing();
    // this.getIds()
  }

  //* ********** SORTING ************************//
  onSort = column => {
    let { sortData } = this.state
    var element, value
    for (const key in sortData) {
      if (key == column) {
        sortData[key] = !sortData[key]
        element = key
        value = 'DESC'
        if (sortData[key]) {
          value = 'ASC'
        }
        this.setState(
          {
            sort: { [element]: value }
          },
          () => {
            this.emailListing()
          }
        )
        this.setState({ sortData })
      } else {
        sortData[key] = false
        element = key
        value = 'ASC'
      }
    }
    this.setState({ sortData })
  }

  //* **********  USER LISTING  ************************//


  emailListing = () => {
    var url =  this.props.location.search;
    var params = queryString.parse(url);
    
    var url = 'admin/getemailtemplate'
    var method = 'post'
    var  { page, pagesize,sort,selectedId,selectedName,selectedContent, selectStatus } = this.state
    var data = { page: page, pagesize: pagesize}
    if(!isEmpty(sort)){
      data.sort =sort;
    }
    if(selectedId != ''){
      data.id = selectedId;
    }
    if(selectedName != ''){
      data.name = selectedName;
    }
    if(selectedContent != ''){
      data.email_content = selectedContent;
    }
    if(selectStatus != ''){
      data.status = selectStatus;
    }
    
    var body=data
    var token = localStorage.getItem('token');
    this.setState({ isLoader: true });
    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
        let data1=response.data;
        let data = response.data.data;
        this.setState({ isLoader: false });
        if(data1.status==0){
          this.setState({ emailListing: [] })
        } else {
            this.setState({ emailListing: data.emailListing,total:data.total ,length:data.emailListing.length})    
              this.setState({
                id:true,
                template_title: true,        
                email_content: true,
                email_subject:true,
                updated_at:true, 
                status:true
              })     
        }
    })
  }
  //* **********  RESET LISTING  ************************//
  resetListing = (e) => {
    var url = 'admin/getemailtemplate'
    var method = 'post'
    var { page, pagesize} = this.state
      var body = { page: page, pagesize: pagesize}
     var token = localStorage.getItem('token');
     this.setState({ isLoader: true });
    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      let data1=response.data;
      let data = response.data.data;
      this.setState({ isLoader: false });
    if(data1.status===0){
      this.setState({ emailListing: [] })
    } else {
        this.setState({ emailListing: data.emailListing,total:data.total })

          this.setState({
            id:true,
            template_title: true,        
                email_content: true, 
                email_subject:true,
                updated_at:true, 
                status:true,
            selectedName:'',
            selectedContent:'',
            selectStatus:'',
          })
    }
    })
  }
  // ########################### Set Table Rows #########################

setTableRows =()=>{
  var token = localStorage.getItem('token')
  let {ids, name, content, status}=this.state
  var body={cmsId:ids,name, content:true,status}
  this.setState({ isLoader: true });
  axios({
    url: API_URL + '/user/setUserFilter',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": token
    },
    data:body
  }).then(res=>{
    this.setState({ isLoader: false });
    if(res){
      this.userListing()
      swal('Updated successfully !','','success')
    }
  })
}
  // //* ********** EDIT USER STATUS  ************************//

  // userStatusChange (status, Id) {
  //   var status = !status
  //   var token = localStorage.getItem('token')
  //   var body = { userIds: [Id], status: status }
  //   var urlkey = '/user/changeUserStatus'
  //   this.props.changeStatus(body, token, urlkey, response => {
  //     let { data } = response
  //     if (data.status == 1) {
  //       swal(data.message, '', 'success')
  //       this.userListing()
  //     } 
  //     else if( data.message === "Invalid token"){
  //       this.props.history.push('/')
  //      }
  //     else {
  //       swal(data.message, '', 'error')
  //     }
  //   })
  // }

  //* **********  SINGLE USER DETAILS  ************************//
  gettingEmailDetails=(Id)=>{
  
    this.props.history.push('/editemailcontent/'+Id)   
  }

  //* ********** SEARCH USER   ************************//

  onChangeSearchHandler = event => {
    let { name, value } = event.target
    this.setState({ [name]: value }, () => this.userListing())
  }

  checkArray(_id) {
    let {multipleDelete} =this.state;
    if (multipleDelete.includes(_id)) {
      return true;
    }
    else {
      return false;
    }
  }
  
  onCheckbox(_id, val) {
  
    var delarray = this.state.multipleDelete;
    if (!delarray.includes(_id)) {
      delarray.push(_id);
    }
    else {
      delarray.splice(delarray.indexOf(_id), 1);
    }
    if (delarray.length != this.state.emailListing.length) {
      this.setState({ checked: false });
    }
    if (this)
      this.setState({ multipleDelete: delarray })
  
  }

 // ########################### PAGINATION #########################

paginationChange(page, pagesize){
  this.setState({
    page: page,
    pagesize: pagesize
  } ,() =>this.emailListing());
}
handleChangePageSize = (value) => {
  this.setState({ pagesize: value, page: 1 }, () => this.emailListing());
}

// applyfilter=()=>{
//   var delArr = this.state.multipleDelete
  
//   if(this.state.selectedOption=='Active'){
//     var body = { userIds: delArr,status:true }
//   }
//   if(this.state.selectedOption=='Inactive'){
//     var body = { userIds: delArr,status:false }
//   }
  
//     var url = '/user/changeUserStatus'
//     var method = 'post'
//     var token = localStorage.getItem('token')
//     this.props.commonApiCall( url, method, body, token, null, this.props, response => {
//     let { data } = response
//     this.userListing()
//     this.setState({ multipleDelete: [],selectedOption:'Select here'})
//     swal('Updated Successfully', '', 'success')
//     })
  
// }

selectAllcheck=()=>{
  var delarray = this.state.multipleDelete
  this.onCheckbox()
  var {userListing}=this.state
  userListing.map(each=>{
    // delarray.push(each._id)
    if (!delarray.includes(each._id)) {
      delarray.push(each._id)
    } else {
      delarray.splice(delarray.indexOf(each._id), 1)
    }
  })
}


handleChange  = event => {
  this.setState({ [event.target.name]:  event.target.value });
};

  render () {
    const Option = Select.Option
    let {id, template_title, email_content,email_subject,updated_at ,status, emailListing, pagesize, page, sortData,total,
      selectedId,selectedName,selectStatus, selectedContent,length} = this.state
    return (
      
      <Home>
        <div className='row'>
          <div className='col-md-12'>
            <div className='page-header border-bottom'>
              <h3>Email Content Management</h3>
              <ul className='breadcrumb '>
                <li className='breadcrumb-item' onClick={()=>this.props.history.push('/dashboard')}>Home</li>
                <li className='breadcrumb-item active'>Email Content Management </li>
              </ul>
            </div>
          </div>
        </div>
       
        <div className='animated fadeIn card transparent no-shadow'>
       
        <div className="row data-filter justify-content-end card-body">
              <div className="col-12 text-md-right">
                <div className="text-right d-flex flex-wrap justify-content-end">
                    <div>
                        {/* <a className="dropdown-item" href="#" onClick={this.√.bind(this,"excel")}>Export to Excel</a>
                        <a className="dropdown-item" href="#" onClick={this.downloadCms.bind(this,"csv")}>Export to CSV</a> */}
                        {/* <p className='export-text'>Export Data</p>
                        <a className="btn btn-primary btn-image" href="#" onClick={this.downloadCms.bind(this, "excel")}><img src="../../assets/images/xls-excel-file.svg" alt="Excel" width="20" height="20"/> Excel</a>
                        <a className="btn btn-primary btn-image ml-2" href="#" onClick={this.downloadCms.bind(this, "csv")}><img src="../../assets/images/csv-file.svg" alt="CSV" width="20" height="20"/> CSV</a> */}
                    </div>
                    <div className='pl-30 border-left ml-30'>
                      <button className="nav-link pull-right btn btn-danger filterlink" type="button" >
                        <i className="fa fa-filter mr-0" aria-hidden="true" />
                      </button>
                    </div>
                </div>
            
              </div>
            </div>
          
      
          <div className="item-list mt-3"  id="itemlist">
          <ul className="row  mb-0">
              <li className="col-sm-3 checkbox"><label><input type="checkbox" onChange={()=>this.setState({id:!this.state.id})}   checked={id} /><span></span>Id</label></li>
              <li className="col-sm-3 checkbox"><label><input type="checkbox" onChange={()=>this.setState({selectedName :!this.state.template_title})}   checked={template_title} /><span></span>Name</label></li>
              <li className="col-sm-3 checkbox"><label><input type="checkbox" onChange={()=>this.setState({email_subject:!this.state.email_subject})}   checked={email_subject} /><span></span>Description</label></li>
              <li className="col-sm-3 checkbox"><label><input type="checkbox"  onChange={()=>this.setState({status:!this.state.status})}   checked={status}/><span></span>Status</label></li>
          </ul>
          <hr/>
          <button className="nav-link pull-right btn btn-outline-primary ml-2" type="button" onClick={this.emailListing}>
              Reset
            </button>   
                
          <button className="nav-link pull-right btn btn-primary ml-2" type="button" onClick={()=>this.setState({id:true, name:true, content: true, status:true})}>
              Select All
            </button>
          <button className="nav-link pull-right btn btn-primary ml-2" type="button" onClick={this.setTableRows}>
              Save
            </button>  
          
            </div>
          
          <div className='card animated fadeIn transparent no-shadow'>
          <div className='card-body'>

            <div className="filter-list"  id="filterlist">
            <div className='border white-bg br-4 card-body'>
              <div className="row">
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                          <label>Name</label>
                          <input type="text" placeholder="Enter Name" name="selectedName" value={selectedName} onChange={this.handleChange} className="form-control"/> 
                      </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                          <label>Content</label>
                          <input type="text" placeholder="Enter Content" name="selectedContent" value={selectedContent} onChange={this.handleChange} className="form-control"/> 
                      </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                          <label>Status</label>
                          <select name="selectStatus" className="form-control" onChange={this.handleChange} value={selectStatus}>
                            <option value=''>Select</option>
                            <option value='Active'>Active</option>
                            <option value='Inactive'>InActive</option>
                          </select>
                      </div>
                  </div>
              </div>
          
              </div>
              <div className="pull-right filter-button card-body pr-0">
              <div className="dropdown ml-2">
              {/* <button className="nav-link  btn btn-teal dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                    <span>Tools</span>
              </button> */}
              {/* <div className="dropdown-menu dropdown-menu-center" aria-labelledby="dropdownMenuButton1">
                  <a className="dropdown-item" href="#">Export to Excel</a>
                  <a className="dropdown-item" href="#">Export to CSV</a>
              </div> */}
              </div>
              <button className="nav-link  btn btn-outline-secondary" type="button" onClick={this.resetListing} > Reset </button>
              <button className="nav-link  btn btn-primary ml-2" type="button" onClick={this.emailListing} > Apply Filter </button> 
              
              </div>
              </div>
            <div className='table-responsive border br-4 white-bg'>
              <table className='table dataTable with-image custom-table table-hover'>
                <thead>
                  <tr><th>ID</th>
                  {/* {id ?<th sortable-column="id" className='text-center' onClick={this.onSort.bind(this, 'id')} >
                      id
                      <i aria-hidden='true' className={(sortData['id']) ? "fa fa-arrow-up" : "fa fa-arrow-down"} />
                    </th> :null}   */}
                    {template_title ?<th sortable-column="fullname" onClick={this.onSort.bind(this, 'template_title')} >
                      Information
                      <i aria-hidden='true' className={(sortData['template_title']) ? "fa fa-arrow-up" : "fa fa-arrow-down"} />
                    </th> :null}   
                    {updated_at ?<th sortable-column="fullname" onClick={this.onSort.bind(this, 'updated_at')} >
                      Updated At
                      <i aria-hidden='true' className={(sortData['updated_at']) ? "fa fa-arrow-up" : "fa fa-arrow-down"} />
                    </th> :null}                    
                    {/* {content ?<th sortable-column="email" onClick={this.onSort.bind(this, 'content')}>
                      Description
                      <i aria-hidden='true' className={(sortData['content']) ? "fa fa-arrow-up" : "fa fa-arrow-down"} />
                    </th>:null} */}
                    {status ?<th sortable-column="status" onClick={this.onSort.bind(this, 'status')}> 
                    Status
                    <i aria-hidden='true' className={(sortData['status']) ? "fa fa-arrow-up" : "fa fa-arrow-down"} />
                    </th>:null}
                    
                    <th> Actions</th>
                  </tr>
                </thead>
                <tbody>
                  { emailListing.length > 0 && emailListing.map((user, Key) => {                    
                    return (
                      <tr key={Key}> 
                         <td>{Key + 1 + (page - 1) * pagesize}</td>
                         <td>
                           <a href='Javascript:;' className='block-imagecontent' onClick={() => this.gettingEmailDetails(user.id)}>
                           <div>
                            {template_title ? <p className='usename text-blue' >{user.template_title}</p>  :null}
                            {email_subject ? <p className='usemail'>{(user.email_subject.length > 0) ? user.email_subject.substring(0,50)+'...':""}</p> :null}
                            </div>
                           </a>
                         </td>
                        {/* {name ? <td className='text-blue' onClick={() => this.gettingEmailDetails(user.id)}>{user.name} </td> :null}
                        {content ? <td className='text-muted'> {(user.content.length > 0) ? user.content.substring(0,50)+'...':""} </td> :null} */}
                        {updated_at ? <td> <p className='usemail'>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(user.updated_at))}
                        {/* {moment(user.updated_at).format('MMMM Do YYYY, h:mm:ss a')} */}
                        </p> </td> :null}
                        {status ? <td>
                          {user.status == 'Active' ? <span className='badge badge-success badge-pill'>Active</span> : <span className='badge badge-warning badge-pill'>InActive</span>}
                        {/* {(user.status == 1)? 'Active' : 'Inactive'}  */}
                        </td> :null}
                         <td>
                          
                          <button onClick={() => this.gettingEmailDetails(user.id)}>
                            <i className='fa fa-edit text-muted' aria-hidden='true' data-toggle="tooltip"  title="View" />
                          </button>
                        </td>
                        
                      </tr>
                    )
                  })}

                  { emailListing.length <= 0 ? <tr ><td text="center" colSpan='8'> 
                    <div className='no-datablock p-30 text-center'>
                    <div className='no-dataimage'><img src="/assets/images/exclamation-mark.png" /></div>
                    <h2 className='no-data'>No Record Found</h2>
                    </div>
                  </td></tr> : null }
                </tbody>
              </table>
            </div>
          <div className="table-footer text-right">
          <label>Showing</label>
          <Select showSearch placeholder={<b> {total<=5 ? total:length}</b>} optionFilterProp="children"
            onSelect={this.handleChangePageSize.bind(this)} onFocus={this.handleFocus} onBlur={this.handleBlur} >
            <Option value={5}>5</Option>
            <Option value={10}>10</Option>
            <Option value={15}>15</Option>
            <Option value={50}>50</Option>
          </Select>
          <label>Out of {total} Cms Pages</label>
          <div className="pagination-list">
            <Pagination className="ant-pagination" pageSize={pagesize} current={page} total={total} onChange={this.paginationChange.bind(this)} locale={localeInfo}/>
          </div>
          </div>
          </div>
          </div>
        </div>
        {this.state.isLoader == true ? <div className='loader-block'>
            <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
    )
  }
}

export default connect(null, actions)(EmailManagement);


