import React, { Component } from 'react';
//import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'rc-pagination/assets/index.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import swal from 'sweetalert';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import * as actions from '../../actions';
//import {isEmpty} from "lodash";
import 'antd/dist/antd.css';
import { Select } from 'antd';
import Home from '../Home';
//import Toggle from 'react-toggle';
//import axios from 'axios';
import { IMAGE_URL,CSV_URL,EXCEL_URL } from '../../config/configs';
import $ from 'jquery';
//import queryString from 'query-string';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
//const FileDownload = require('js-file-download');

class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoader: false,
      page: 1,
      pagesize: 10,
      userListing: [],
      usertype: '',
      total:0,
      sort: '',
      fullname: '',      
      email: '', 
      status: '',
      usersAccess:{}
    }
  }
  
  componentDidMount () {
    let permissions = this.props.permissionsList;
    if(permissions !== 'Admin'){
      this.props.history.push('/dashboard');
    }
    this.userListing();
    $('.filterlink').click(function () {
      $('#itemlist').hide();
      $('#filterlist').stop().slideToggle();
    });
    $('.listlink').click(function () {
        $('#filterlist').hide();
        $('#itemlist').stop().slideToggle();
    });
    // setTimeout(function(){
    //   $('[data-toggle="tooltip"]').tooltip();
    // },1500);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.utype !== this.props.match.params.utype) {
      let utype = nextProps.match.params.utype;
      let usertype;
      if(utype == 'customer') {
        usertype = "Customer";
      } else if(utype == 'agent') {
        usertype = "Agent";
      } else {
        swal('Invalid usertype search');
        return false;
      }
      this.setState({ usertype,
        page: 1,
        pagesize: 10,
        userListing: [],
        total:0,
        sort: '',
        fullname: '',      
        email: '', 
        status: '' },
        () => {
          this.userListing();
        }
      );
    }
  }

  //* ********** SORTING ************************//
  onSort = (name , value) => {
    if (value) {
			let sort = {};
      sort[name] = value;
      this.setState({sort: sort},
        () => {
          this.userListing();
        }
      )
		}
  }

  //* **********  USER LISTING  ************************//
  userListing = () => {
    let utype = this.props.match.params.utype;
    let usertype;
    if(utype == 'customer') {
      usertype = "Customer";
    } else if(utype == 'agent') {
      usertype = "Agent";
    } else {
      swal('Invalid usertype search');
      return false;
    }
    
    this.setState({ usertype: usertype, isLoader: true });
    let url = 'admin/userListing';
    let method = 'post';
    let  { page, pagesize, fullname, email, sort, status } = this.state;
    let data = { page: page, pagesize: pagesize, usertype, fullname, email, sort, status };
    var body = data;
    var token = localStorage.getItem('token');

    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      let responsedata = response.data
      let userdata = response.data.data   
      if(responsedata.status == 0){
        this.setState({ userListing: [], isLoader: false })
      } else {
        this.setState({ 
          userListing: userdata.userListing, 
          total:userdata.total, 
          length:userdata.userListing.length,
          isLoader: false
        });    
      }    
    })
  }

  //* **********  RESET LISTING  ************************//
  resetListing = (e) => {
    let url = '/admin/userListing';
    let method = 'post';
    this.setState({ fullname: '', email:'', status:'', page:1, isLoader: true });
    let { pagesize, usertype} = this.state;
    let body = { page: 1, pagesize: pagesize, usertype};
    let token = localStorage.getItem('token');
    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      let responsedata = response.data
      let userdata = response.data.data
      if(responsedata.status === 0){
        this.setState({ userListing: [], isLoader: false })
      }
      else {
        this.setState({ 
          userListing: userdata.userListing, 
          total:userdata.total, 
          length:userdata.userListing.length,
          isLoader: false
        })
      }          
    })
  }

  //**************************  USER DETAILS  ************************//
  getUserDetails=(utype, Id)=>{
    // let body = { Id };
    // this.props.editDetails(body);
    this.props.history.push('/users/'+utype+'/edit/'+Id);
  }

  // ########################### PAGINATION #########################
  paginationChange(page, pagesize){
    this.setState({
      page: page,
      pagesize: pagesize
    } ,() =>this.userListing());
  }

  handleChangePageSize = (value) => {
    this.setState({ pagesize: value, page: 1 }, () => this.userListing());
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  
  // ########################### download CSV #########################

  downloaduserdetails = (format) => {
    let  { fullname, email, sort, status, usertype } = this.state;
    let data = { usertype, fullname, email, sort, status, download:format};
    let body = data;
    
    let url = 'admin/userListing';
    let method = 'post';
    let token = localStorage.getItem('token');
    this.setState({ isLoader: true });
    this.props.commonApiCall( url, method, body, token, null, this.props, response => {
      var responsedata = response.data.data;
      this.setState({ isLoader: false });
      if(responsedata.fileName) {
        if(format === 'csv'){
          window.open(CSV_URL + responsedata.fileName,'_blank');
          //FileDownload(CSV_URL  +  data.filePathAndName, data.filePathAndName);
        } else {
          window.open(EXCEL_URL + responsedata.fileName,'_blank');
          //FileDownload(EXCEL_URL  +  data.filePathAndName, data.filePathAndName);
        }
      } else {
        toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      }
    });
  }

  addDefaultSrc(ev){
    ev.target.src = "/assets/images/no-image-user.png";
  }


  render () {
    const Option = Select.Option
    let { profile_image, fullname, email, status, userListing, pagesize, page, sort, total, length, usertype } = this.state;
    return (
      <Home>
        <div className='row'>
          <div className='col-md-12'>
            <div className='page-header border-bottom'>
              <h3>{usertype} Management</h3>
              <ul className='breadcrumb '>
                <li className='breadcrumb-item' onClick={()=>this.props.history.push('/dashboard')}>Home</li>
                <li className='breadcrumb-item active'>{usertype} Management </li>
              </ul>
            </div>
          </div>
        </div>
       
        <div className="animated fadeIn card transparent no-shadow">
          <div className="row data-filter justify-content-end card-body">
            <div className="col-12 text-md-right">
              <div className="text-right d-flex flex-wrap justify-content-end">
                <div className=''>
                  <p className='export-text'>Export {usertype} Data</p>
                  <a className="btn btn-primary btn-image" href="#" onClick={this.downloaduserdetails.bind(this,"excel")}><img src='/assets/images/xls-excel-file.svg' alt='Excel' width='20' height='20' /> Excel</a>
                  <a className="btn btn-primary btn-image ml-2" href="#" onClick={this.downloaduserdetails.bind(this,"csv")}><img src='/assets/images/csv-file.svg' alt='CSV' width='20' height='20' /> CSV</a>
                </div>

                {/*
                <div className="dropdown">
                  <button className="nav-link  btn btn-teal dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                        <span>Tools</span>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#" onClick={this.downloaduserdetails.bind(this,"excel")}>Export to Excel</a>
                      <a className="dropdown-item" href="#" onClick={this.downloaduserdetails.bind(this,"csv")}>Export to CSV</a>
                  </div>
                </div> */}
                <div className='pl-30 border-left ml-30'>
                  <button type="button" className="btn btn-primary"  onClick={() => this.props.history.push('/users/'+usertype.toLowerCase()+'/add')}>
                        <i className="fa fa-plus" /> Create {usertype}
                  </button>
                </div>

                <div className='pl-30 border-left ml-30'>
                  <button className="nav-link pull-right btn btn-danger filterlink" type="button" >
                    <i className="fa fa-filter mr-0" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card animated fadeIn transparent no-shadow'>
          
          <div className='card-body'>
            <div className="filter-list"  id="filterlist">
              <div className=" border white-bg br-4 card-body">
                <div className="row">            
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className='form-control'
                        type='text'
                        name='fullname'
                        placeholder='Enter Name'
                        value={fullname}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className='form-control'
                        type='text'
                        name='email'
                        placeholder='Enter Email'
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Status</label>
                      <select className="form-control" onChange={(e)=>this.setState({status:e.target.value})} value={status}>
                        <option value=''>Select</option>
                        <option value='Active'>Active</option>
                        <option value='Inactive'>InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
                
                <div className="pull-right filter-button card-body pr-0">
                  <div className="dropdown ml-2">
                  </div>
                  <button className="nav-link  btn btn-outline-secondary ml-2" type="button" onClick={this.resetListing} > Reset </button>
                  <button className="nav-link  btn btn-primary ml-2" type="button" onClick={this.userListing} > Apply Filter </button> 
                  </div>
                </div>

            <div className='table-responsive border br-4 white-bg'>
              <table className='table dataTable with-image custom-table table-hover'>
                <thead>
                  <tr>
                    <th sortable-column="id" className='id text-center'>
                    ID
                    {/* { (sort['id'] == 'ASC' || sort['id'] == '') ? 
                        <i aria-hidden='true' onClick={this.onSort.bind(this, 'id', 'DESC')} className="fa fa-arrow-up" />
                      : <i aria-hidden='true' onClick={this.onSort.bind(this, 'id', 'ASC')} className="fa fa-arrow-down" /> } */}
                    </th>
                    <th sortable-column="fullname">{usertype} Info 
                      { (sort['fullname'] == 'ASC' || sort['fullname'] == '') ? 
                        <i aria-hidden='true' onClick={this.onSort.bind(this, 'fullname', 'DESC')} className="fa fa-arrow-up" />
                      : <i aria-hidden='true' onClick={this.onSort.bind(this, 'fullname', 'ASC')} className="fa fa-arrow-down" /> }
                    </th>
                    {/* <th sortable-column="fullname">
                      Name
                      { (sort['fullname'] == 'ASC' || sort['fullname'] == '') ? 
                        <i aria-hidden='true' onClick={this.onSort.bind(this, 'fullname', 'DESC')} className="fa fa-arrow-up" />
                      : <i aria-hidden='true' onClick={this.onSort.bind(this, 'fullname', 'ASC')} className="fa fa-arrow-down" /> }
                    </th>
                    <th sortable-column="email">
                      Email
                      { (sort['email'] == 'ASC') ? 
                        <i aria-hidden='true' onClick={this.onSort.bind(this, 'email', 'DESC')} className="fa fa-arrow-up" />
                      : <i aria-hidden='true' onClick={this.onSort.bind(this, 'email', 'ASC')} className="fa fa-arrow-down" /> }
                    </th> */}
                    <th > Phone Number <i aria-hidden='true'  /> </th>
                    { usertype.toLowerCase() == 'agent' ? 
                      <th className='text-center'> No. Of Chats <i aria-hidden='true'  /> </th> : 
                      <th className='text-center'> No. Of Inquiries <i aria-hidden='true'  /> </th> }
                    { usertype.toLowerCase() == 'agent' ? 
                      <th className='text-center'> No. Of Leads <i aria-hidden='true'  /> </th> : null }
                    { usertype.toLowerCase() == 'agent' ? 
                      <th className='text-center'> Business Closed <i aria-hidden='true'  /> </th> : 
                      <th className='text-center'> Business Given <i aria-hidden='true'  /> </th> }
                    <th > Created On <i aria-hidden='true'  /> </th>
                    <th sortable-column="status"> 
                      Status
                      { (sort['status'] == 'ASC') ? 
                          <i aria-hidden='true' onClick={this.onSort.bind(this, 'status', 'DESC')} className="fa fa-arrow-up" />
                        : <i aria-hidden='true' onClick={this.onSort.bind(this, 'status', 'ASC')} className="fa fa-arrow-down" /> }
                    </th>
                    <th> Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userListing.length > 0 && userListing.map((user, Key) => {   
                    let filesplit, thumbimage;
                    if(user.profile_image) {
                      filesplit = user.profile_image.split('.');
                      thumbimage = filesplit[0]+'_thumb.'+filesplit[1];
                    }                 
                    return (
                      <tr key={user.id} key={Key} className='animated fadeIn'>
                        <td className='text-center'>{ Key+1 + (page-1) * pagesize}</td>
                        <td>
                          <a href='Javascript:;' className='block-imagecontent' onClick={() => this.getUserDetails(usertype.toLowerCase(), user.id)}>
                            <div className="thumb-img">
                              <img width='50' height='50' src={user.profile_image ? IMAGE_URL+thumbimage+'?'+Date.now().toString() : "/assets/images/no-image-user.png"} alt="Profile image" onError={this.addDefaultSrc}/>
                            </div>
                            <div className='userinfo-block'>
                              <p className='usename'>{user.fullname}</p>
                              <p className='usemail'>{user.email} </p>
                            </div>
                          </a>
                        </td>
                        {/* <td onClick={() => this.getUserDetails(user.id)}> </td>
                         <td></td> */}
                        <td className='text-muted'>{user.phone_number ? user.phone_number : 'N/A'}</td>
                        <td className='text-center'>{user.chat_count ? user.chat_count : 'N/A'}</td>
                        { usertype.toLowerCase() == 'agent' ? <td className='text-center'>{user.lead_count ? user.lead_count : 'N/A'}</td> : null }
                        <td>{user.total_amount ? user.total_amount : 'N/A'}</td>
                        <td className='text-muted'>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(user.created_at))}
                            {/* {user.created_at ? new Intl.DateTimeFormat('en-GB', { 
                                year: 'numeric', 
                                month: 'long', 
                                day: '2-digit'
                            }).format(user.created_at) : 'N/A'} */}
                            </td>
                        <td>
                        {user.status == 'Active' ? <span className='badge badge-success badge-pill'>{user.status}</span> : <span className='badge badge-warning badge-pill'>{user.status}</span>}
                        </td>
                         <td>
                          <button onClick={() => this.getUserDetails(usertype.toLowerCase(),user.id)} >
                            <i className="fa fa-edit text-muted" aria-hidden="true" data-toggle="tooltip"  title="Edit"></i>
                          </button>
                          {/* <button onClick={() => this.deleteUser(user.id)}>
                            <i className='fa fa-trash text-danger' aria-hidden='true' data-toggle="tooltip"  title="Delete" />
                          </button> */}
                        </td>
                      </tr>
                    )
                  })}
                  { userListing.length <= 0 ? <tr ><td text="center" colSpan='8'> 
                    <div className='no-datablock p-30 text-center'>
                    <div className='no-dataimage'><img src="/assets/images/exclamation-mark.png" /></div>
                    <h2 className='no-data'>No Record Found</h2>
                    </div>
                  </td></tr> : null }
                </tbody>
              </table>
            </div>
          <div className="table-footer text-right">
          <label>Showing</label>
          <Select showSearch placeholder={<b> {total<=5 ? total:length}</b>} optionFilterProp="children"
            onSelect={this.handleChangePageSize.bind(this)} onFocus={this.handleFocus} onBlur={this.handleBlur} >
            <Option value={5}>5</Option>
            <Option value={10}>10</Option>
            <Option value={15}>15</Option>
            <Option value={50}>50</Option>
          </Select>
          <label>Out of {total} {usertype}</label>
          <div className="pagination-list">
            <Pagination className="ant-pagination" pageSize={pagesize} current={page} total={total} onChange={this.paginationChange.bind(this)} locale={localeInfo}/>
          </div>
          </div>
          
          </div>
        </div>
        {this.state.isLoader == true ? <div className='loader-block'>
            <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
    )
  }
}

const mapStateToProps = state => ({
  getadmindata: state.user.userdatails,
  permissionsList:state.admindata.admindata.rolepermissions,

});
export default connect( mapStateToProps, actions )(Users)
