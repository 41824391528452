import React, { Component } from 'react';
import * as _ from "lodash";
import { IMAGE_URL } from '../../config/configs';
//import Home from '../Home';

class Step3 extends Component {
  constructor(props) {
      super(props);
      this.isValidated = this.isValidated.bind(this);
    }


    isValidated = (e) => {
        let { selectedAttributes, attributesets } = this.props;
        let errors = [];
        let formIsValid, maximages = false;
        attributesets.forEach((attributes_sets) => {
          Array.prototype.forEach.call(attributes_sets.attributes, attributes => {
            if(attributes.is_required) {
              if(attributes.type == 'textbox') {
                attributes.options.forEach((attributes_options) => {
                  let attributedata = _.find(selectedAttributes.attributes, ['attribute_id', attributes_options.id.toString()]);
                  if(attributedata == undefined ) {
                    attributedata = _.find(selectedAttributes.attributes, ['attribute_id', attributes_options.id]);
                  }
                  if(!attributedata) {
                    formIsValid = false;
                    errors[attributes_options.id] = '* '+attributes_options.option_name+' is required!';
                  }
                });
              } else if(attributes.type == 'image') {
                let files = document.getElementById("file").files;
                errors['images'] = [];
                if(files && files.length > 0 ) {

                  //files.forEach((file) => {
                  Array.prototype.forEach.call(files, file => {
                    let types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
                    if (types.every(type => file.type !== type)) {
                        formIsValid = false;
                        errors.images.push('* ' + file.type + ' is not a supported format for '+file.name+'. Only JPEG, JPG, PNG and GIF is supported!');
                    }
                    if (file.size > 5242880) {
                        formIsValid = false;
                        errors.images.push('* ' + file.name + ' is too large! Maximum image upload size is 5 MB. Please select a smaller file!');
                    }
                    if(selectedAttributes.images.length + selectedAttributes.imagesPreviewUrlsServer.length > 10) {
                        formIsValid = false; 
                        maximages = true;
                    }
                  });
                  if(!selectedAttributes.images || selectedAttributes.images.length <= 0) {
                    formIsValid = false; 
                    errors.images.push('* '+attributes.attribute_name+' is required!');
                  } 
                  if(maximages) {
                    formIsValid = false; 
                    errors.images.push('* You can not upload more then 10 images. Maximun 10 images are allowed!');
                  }
                } else if(selectedAttributes.imagesPreviewUrls.length <= 0  && selectedAttributes.imagesPreviewUrlsServer.length <= 0 ) {
                  formIsValid = false; 
                  errors['images'] = ['* '+attributes.attribute_name+' is required!'];
                }
                
              } else {
                let attributedata = _.find(selectedAttributes.attributes, ['attribute_id', attributes.id.toString()]);
                if(attributedata == undefined ) {
                  attributedata = _.find(selectedAttributes.attributes, ['attribute_id', attributes.id]);
                }
                if(!attributedata) {
                  formIsValid = false;
                  errors[attributes.id] = '* '+attributes.attribute_name+' is required!';
                }
              }
            }
          });
        }); 
        if(errors) {
          selectedAttributes.errors = errors;
        } else {
          selectedAttributes.errors = '';
        }
        this.setState({ selectedAttributes });
        return formIsValid;

    }

    addDefaultSrcForProperty(ev){
      ev.target.src = "/assets/images/no-image-user.png";
    }
      
    render() {
        const { attributesets, selectedAttributes, category_name, handleChangeStep3, removestep3images } = this.props;
        console.log(selectedAttributes, "selectedAttributes");
        return (
          <div className='card transparent no-shadow specification-block'>
            <div className='card-body mb-4'>
              <div className='white-bg border br-6'>
                  <div className='card-header border-bottom'>
                      <p className="mb-0 font-15">Set {category_name} Specifications</p>
                  </div>

                  <div className='card-body pt-30 light-grey form-material'>
                  {attributesets &&
                    attributesets.map((attribute_set, key1) => (
                      <div key={key1}>

                      {attribute_set.attributes.map((attributes, key2) => {
                        if(attributes.type == 'textbox') {
                          return <div key={key2} className='row'>
                              {attributes.attribute_name ? <p className='mb-10 font-15 col-md-12'>{attributes.attribute_name}</p> : null} 
                                {attributes.options.map((optionsdata, key3) => 
                                {
                                  let selecteddata = _.find(selectedAttributes.attributes, {'attribute_id': optionsdata.id.toString()});
                                  if( selecteddata == undefined ) {
                                    selecteddata = _.find(selectedAttributes.attributes, {'attribute_id': optionsdata.id});
                                  }
                                  let textboxdata = selecteddata ? selecteddata.value : '';
                                    return <div className='form-group col-md-4' key={key3}>
                                      <label className="col-form-label">{optionsdata.option_name}</label>
                                      <input
                                          className='form-control'
                                          type='text'
                                          name={optionsdata.id}
                                          placeholder=''
                                          value={textboxdata}
                                          onChange={handleChangeStep3}
                                          />
                                      <span className="error-block">{selectedAttributes.errors[optionsdata.id]}</span>
                                    </div> 
                                  })
                                }
                            </div>
                        }    
                      })}
                        
                        {/* {attribute_set.attributes.map((attributes, key2) => (
                          <div key={key2}>
                          {attributes.type == 'textbox' ? 
                            <div>
                              {attributes.attribute_name ? <h4>{attributes.attribute_name}</h4> : null} 
                                {attributes.options.map((optionsdata, key3) => 
                                {
                                  let selecteddata = _.find(selectedAttributes.attributes, {'attribute_id': optionsdata.id.toString()});
                                  if( selecteddata == undefined ) {
                                    selecteddata = _.find(selectedAttributes.attributes, {'attribute_id': optionsdata.id});
                                  }
                                  let textboxdata = selecteddata ? selecteddata.value : '';
                                    return <div className='form-group col-md-6' key={key3}>
                                      <label className="col-form-label">{optionsdata.option_name}</label>
                                      <input
                                          className='form-control'
                                          type='text'
                                          name={optionsdata.id}
                                          placeholder=''
                                          value={textboxdata}
                                          onChange={handleChangeStep3}
                                          />
                                      <span className="error-block">{selectedAttributes.errors[optionsdata.id]}</span>
                                    </div> 
                                  })
                                }
                            </div> : null }
                          </div>
                        ))} */}

                        {attribute_set.attributes.map((attributes, key2) => {
                          if(attributes.type == 'checkbox') {
                            return <div key={key2}  className='custombx-wrapper form-group'>
                            { attributes.attribute_name ? <p className='mb-10 font-15 pt-0'>{attributes.attribute_name}</p> : null}
                            <div className='custombx-wrapper mt-0'>
                            { attributes.options.map((options, key3) => 
                                {
                                let selecteddata2 = _.find(selectedAttributes.attributes, {'value': options.option_name});
                                selecteddata2 = (selecteddata2) ? selecteddata2 : "";
                                console.log(selecteddata2, "selecteddata2");
                                return <div className='custom-control' key={key3}>
                                    <div className=" custom-checkbox">
                                      <input 
                                        type="checkbox" 
                                        className="custom-control-input"
                                        name={attributes.id}
                                        onChange={handleChangeStep3}
                                        value={options.option_name}
                                        checked={selecteddata2}
                                        id={'customCheck'+key1+'-'+key2+'-'+key3}/>
                                      <label className="custom-control-label" htmlFor={'customCheck'+key1+'-'+key2+'-'+key3}>{options.option_name}</label>
                                    </div>
                                </div>
                              })
                            }
                            </div>
                            <span className="error-block">{selectedAttributes.errors[attributes.id]}</span>
                          </div>
                          } 
                          
                        })}

                        {attribute_set.attributes.map((attributes, key2) => {
                          if(attributes.type == 'radio') {
                            return <div key={key2} className='custombx-wrapper form-group'>
                            { attributes.attribute_name ? <p className='mb-10 font-15 pt-0'>{attributes.attribute_name}</p> : null }
                            <div className='custombx-wrapper mt-0'>
                            { attributes.options.map((options, key3) => 
                              {
                                let selecteddata3 = _.find(selectedAttributes.attributes, {'value': options.option_name});
                                return <div className='custom-control' key={key3}>
                                  <div className=" custom-radio mb-10" >
                                      <input type="radio"
                                        value={options.option_name}
                                        name={attributes.id}
                                        className="custom-control-input" 
                                        checked={selecteddata3} 
                                        onChange={handleChangeStep3}
                                        id={'customRadio'+key1+'-'+key2+'-'+key3} />
                                      <label className="custom-control-label" htmlFor={'customRadio'+key1+'-'+key2+'-'+key3}>{options.option_name}</label>
                                  </div>
                                </div>
                              })
                            }
                            </div>
                            <span className="error-block">{selectedAttributes.errors[attributes.id]}</span>
                            </div>
                          }
                        })}

                        {attribute_set.attributes.map((attributes, key2) => {
                          if(attributes.type == 'image') {
                            return <div key={key2} > 
                            { attributes.attribute_name ? <p className='mb-10 font-15 pt-0'>{attributes.attribute_name}</p> : null }
                            <div className="inner-imagewrapper">
                              {selectedAttributes.imagesPreviewUrls.length <= 0 && selectedAttributes.imagesPreviewUrlsServer.length <= 0 ?
                                  <h3 className='placeholder-caption'>No files has been uploaded yet.</h3>
                              : null } 

                              <div className='inner-image'>
                                  {selectedAttributes.imagesPreviewUrlsServer.length > 0 && selectedAttributes.imagesPreviewUrlsServer.map((priviewimage, key) => (
                                      <div key={key} className='image-wrapper'>   
                                        <img width='240' height='240'  src={priviewimage.value ? IMAGE_URL + priviewimage.value : "/assets/images/no-image-icon-4.png"} alt="Property image" onError={this.addDefaultSrcForProperty}/>
                                        <button onClick={() => removestep3images(key, priviewimage.item_attribute_id)} className='remove-btn btn'><img src='/assets/images/close.png' alt='Remove' width='25' height='25' /></button>
                                      </div>
                                  ))}

                                  {selectedAttributes.imagesPreviewUrls.length > 0 && selectedAttributes.imagesPreviewUrls.map((value, key) => (
                                      <div key={key} className='image-wrapper'>   
                                        <img width='240' height='240'  src={value ? value : "/assets/images/no-image-icon-4.png"} alt="Property image" />
                                        <button onClick={() => removestep3images(key)} className='remove-btn btn'><img src='/assets/images/close.png' alt='Remove' width='25' height='25' /></button>
                                      </div>
                                  ))}
                                   <div className='add-fileblock custom-file-block'>
                                <label>
                                  <input 
                                    type="file" 
                                    multiple 
                                    className="file-input form-control-file text-primary font-weight-bold" 
                                    name={attributes.id} 
                                    id="file" 
                                    accept="image/*" 
                                    data-title="Drag and drop a file" 
                                    onChange={handleChangeStep3}
                                    />
                                </label>   
                              </div>
                              </div>
                             
                            </div> 
                            {selectedAttributes.errors.images && selectedAttributes.errors.images.length > 0 && (
                              <div className='error-wrap'>
                                {selectedAttributes.errors.images.map((error, key) => (
                                    <div key={key}>
                                    <span className="error-block" key={key}> {error} </span>
                                    </div>
                                ))}
                              </div>
                              )} 
                            
                          </div>
                          }
                        })}
                      </div>
                  ))} 
                  </div>           
                </div>
              </div>
            </div>
          );
        }
}

export default Step3;