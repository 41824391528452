import React, { Component } from 'react';
import Home from '../Home';
import { commonApiCall } from '../../actions';
import { connect } from "react-redux";
import { IMAGE_URL } from '../../config/configs';
import { Select } from 'antd';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
class UpsertOwner extends Component {
      constructor(props) {
            super(props)
            this.validator = new SimpleReactValidator();
            this.state = {
                  isLoader: false,
                  user_id: '',
                  usertype: 'Owner',
                  first_name: '',
                  last_name: '',
                  email: '',
                  password: '',
                  confirm_password: '',
                  profile_image: '',
                  profile_image_url: "/assets/images/no-image-icon-4.png",
                  phone_number: '',
                  status: '',
                  changestatus: 'Select',
                  errors: {}
            }
      }
      componentDidMount() {
            let permissions = this.props.permissionsList;
            if (permissions !== 'Admin') {
                  this.props.history.push('/dashboard');
            }
            this.getOwnerDetails();
      }
      getOwnerDetails = () => {
            let id = this.props.match.params.id;
            this.setState({ user_id: id });
            if (id) {
                  var body = { user_id: id };
                  let token = localStorage.getItem('token');
                  var url = 'admin/getadmindetails';
                  var method = 'post';
                  this.setState({ isLoader: true });
                  this.props.commonApiCall(url, method, body, token, null, this.props, (response) => {
                        let responsedata = response.data;
                        this.setState({ isLoader: false });
                        if (responsedata.status == 1) {
                              let ownerdata = responsedata.data[0].userdetails;
                              this.setState({
                                    user_id: ownerdata.id,
                                    first_name: ownerdata.first_name,
                                    last_name: ownerdata.last_name,
                                    email: ownerdata.email,
                                    phone_number: ownerdata.phone_number,
                                    profile_image_url: IMAGE_URL + ownerdata.profile_image,
                                    status: (ownerdata.status == 'Active') ? 'true' : 'false',
                              });
                        } else {
                              toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                        }
                  });
            }
      }
      upsertOwnerUser = (e) => {
            let { user_id } = this.state;
            if (this.validateForm(user_id)) {
                  let { user_id, first_name, last_name, email, password, phone_number, profile_image, usertype, status } = this.state;
                  let data = { user_id, first_name, last_name, email, password, phone_number, profile_image, usertype, status };
                  let body = data;
                  let url = 'admin/upsertonwer';
                  let method = 'post';
                  this.setState({ isLoader: true });
                  let token = localStorage.getItem('token');
                  this.props.commonApiCall(url, method, body, token, null, this.props, response => {
                        let responsedata = response.data;
                        this.setState({ isLoader: false });
                        if (responsedata.status == 1) {
                              swal({
                                    title: responsedata.message,
                                    icon: "success",
                              }).then((willDelete) => {
                                    if (willDelete) {
                                          this.props.history.push(`/owners`);
                                    }
                              });
                        } else {
                              toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                        }
                  });
            } else {
                  return false;
            }
      }
      handleChange = (event) => {
            this.setState({ [event.target.name]: event.target.value })
            if (event.target.value) {
                  this.setState({
                        errors: Object.assign(this.state.errors, { [event.target.name]: "" })
                  });
            }
      }
      validateForm = (id) => {
            let { first_name, last_name, email, password, confirm_password, phone_number, errors } = this.state;
            let formIsValid = true;
            let email_pattern = new RegExp(
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            let phone_pattern = new RegExp(/^\d{9,13}$/);
            let image = document.getElementById("file").files[0];
            let types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
            if(image) {
                  if (types.every(type => image.type !== type)) {
                        formIsValid = false;
                        errors['profile_image'] = '* ' + image.type + ' is not a supported format. Only JPEG, JPG, PNG and GIF is supported!';
                  }
                  if (image.size > 5242880) {
                        formIsValid = false;
                        errors['profile_image'] = '* ' + image.name + ' is too large! Maximum image upload size is 5 MB. Please select a smaller file!';
                  }
            }
           
            if (!first_name) {
                  formIsValid = false
                  errors['first_name'] = '* Name is required!';
            }
            if (!last_name) {
                  formIsValid = false
                  errors['last_name'] = '* Name is required!';
            }
            if (!id) {
                  if (!email) {
                        formIsValid = false
                        errors['email'] = '* Email Address is required!';
                  } else if (typeof email !== 'undefined') {
                        if (!email_pattern.test(email)) {
                              formIsValid = false;
                              errors['email'] = '* Enter a valid email address!';
                        }
                  } else {
                        formIsValid = true;
                  }
                  if (!password) {
                        formIsValid = false
                        errors['password'] = '* Password is required!';
                  }
                  if (!confirm_password) {
                        formIsValid = false
                        errors['confirm_password'] = '* Confirm Password is required!';
                  }
                  if (password !== confirm_password) {
                        formIsValid = false
                        errors['confirm_password'] = '* Password and Confirm Password does not match!';
                  }
            }
            if (phone_number) {
                  if (!phone_pattern.test(phone_number)) {
                        formIsValid = false;
                        errors['phone_number'] = '* Enter a valid Phone number!';
                  }
            }
            this.setState({ errors });
            return formIsValid;
      }
      fileUploadSubmit = (e) => {
            var imagefile = document.querySelector('#file');
            this.getBase64(imagefile.files[0], (result) => {
                  this.setState({ profile_image: result });
            });
            this.setState({ 'profile_image_url': URL.createObjectURL(imagefile.files[0]) });
      }
      getBase64(file, callback) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                  callback(reader.result)
            };
            reader.onerror = function (error) {
                  console.log('Error: ', error);
            };
      }
      addDefaultSrc(ev) {
            ev.target.src = "/assets/images/no-image-user.png";
      }
      render() {
            const Option = Select.Option;
            let { errors, user_id, profile_image_url, first_name, last_name, email, password, confirm_password, phone_number, status } = this.state;
            return (
            <Home>
            <div className='row'>
                  <div className='col-md-12'>
                  <div className='page-header border-bottom'>
                        <h3>Add New Owner</h3>
                        <ul className='breadcrumb '>
                        <li className='breadcrumb-item' onClick={() => this.props.history.push('/dashboard')}> Home</li>
                        <li className='breadcrumb-item' onClick={() => this.props.history.push('/owners')}> Owners</li>
                        <li className='breadcrumb-item active'>{user_id ? 'Edit Owner':'Add New Owner'}</li>
                        </ul>
                  </div>
                  </div>
            </div>
            <form className='form-sample card transparent no-shadow'>
                  <div className='card-body'>
                  <div className='white-bg box-shadow br-4 border-secondary'>
                        <div className='card-header border-bottom'>
                        <p className='mb-0 font-15'>Enter Owner Information</p>
                        </div>
                        <div className='card-body pt-30 light-grey'>
                        <div className="row">
                              <div className="col-md-4">
                              <div className='animated fadeIn mb-2 pl-40 pr-40'>
                                    <div className='form-group'>
                                    <div className='porfile-info'>
                                          <div className="drop-section user-profile-img">
                                          <div className="select-image inner-image">
                                                <div className="product-img"><img src={profile_image_url ? profile_image_url : "/assets/images/no-image-icon-4.png"} alt="Profile image" onError={this.addDefaultSrc}/></div>
                                          </div>
                                          <div className="upload-overlay d-flex justify-content-center align-items-center">
                                                <div className="upload-wrapper upload-file">
                                                <span className='camera-image'><img src='/assets/images/camera.png' alt='Upload Image' width='36' height='31' /></span>
                                                </div>
                                          </div>
                                          <label className="sr-only" htmlFor="inputFile">File Upload</label>
                                          <input type="file" className="form-control-file text-primary font-weight-bold" name='image' id="file" accept="image/x-png,image/gif,image/jpeg,image/jpg" multiple
                                                data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} />
                                          <span className="error-block"> {errors.profile_image} </span>
                                          </div>
                                    </div>
                                    </div>
                              </div>
                              </div>
                              <div className="col-md-8">
                              <div className=' animated fadeIn mb-2'>
                                    <div className='form-material row user-wrapper'>
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>First Name<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='text'
                                          name='first_name'
                                          placeholder='Enter First Name'
                                          value={first_name}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.first_name} </span>
                                    </div>
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Last Name<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='text'
                                          name='last_name'
                                          placeholder='Enter Last Name'
                                          value={last_name}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.last_name} </span>
                                    </div>
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Email<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='text'
                                          name='email'
                                          disabled={user_id}
                                          placeholder='Enter Email'
                                          value={email}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.email} </span>
                                    </div>
                                    {!user_id ?
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Password<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='password'
                                          name='password'
                                          placeholder='Enter Password'
                                          value={password}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.password} </span>
                                    </div>
                                    : null }
                                    {!user_id ?
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Confirm Password<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='password'
                                          name='confirm_password'
                                          placeholder='Confirm Password'
                                          value={confirm_password}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.confirm_password} </span>
                                    </div>
                                    : null }
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Phone Number<span className="text-danger"></span> :</label>
                                          <input
                                          className='form-control'
                                          type='number'
                                          name='phone_number'
                                          placeholder='Enter Phone number'
                                          value={phone_number}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.phone_number} </span>
                                    </div>
                                    </div>
                              </div>
                              </div>
                        </div>
                        </div>
                  </div>
                  </div>
            </form>
            <div className="card-body row">
                  {user_id ?
                  <div className='col-md-5 d-flex align-items-center'>
                  <label className='col-form-label' htmlFor='material-switch'>
                  Status <span className="text-danger">*</span> :
                  </label>
                  <div className='active-toggle ml-1'>
                        {/* 
                        <Toggle
                        checked={status}
                        className='custom-classname'
                        onChange={()=>
                        this.setState({status:!this.state.status})}
                        />  */}
                        <Select
                        placeholder="select"
                        optionFilterProp="children"
                        className="applyselect"
                        value={status}
                        onSelect={(value) =>
                        this.setState({ status: value })}
                        >
                              <Option value='true'>Active</Option>
                              <Option value='false'>InActive</Option>
                        </Select>
                  </div>
                  </div>
                  : null }
                  <div className="col-md-7 button-continer text-right ml-auto">
                  <button type='button' className='btn btn-outline-secondary btn-sm' onClick={() => this.props.history.push(`/owners`)} > Cancel </button>
                  <button type='button' className='btn btn-primary btn-large' onClick={this.upsertOwnerUser}><span>{user_id ? ' Update ':' Save '}</span></button>
                  </div>
            </div>
            {this.state.isLoader == true ? <div className='loader-block'>
                  <img src="/assets/images/loader2.gif" />
            </div> : null }
            {/* 
            <div className='card animated fadeIn mb-2'>
                  <div className="card-header" data-spy="affix" data-offset-top="100">
                  <div className="button-continer text-right">
                        <button type='button' className='btn btn-primary' onClick={this.upsertOwnerUser}><span>{user_id ? ' Update ':' Save '}</span></button>
                        <button type='button' className='btn btn-outline-primary' onClick={() => this.props.history.push(`/owners`)} > Cancel </button>
                  </div>
                  </div>
                  <div className='card-body'>
                  <form className='form-sample'>
                        <div className='form-group row'>
                        <label className='col-sm-3 col-lg-2 col-sm-3 col-form-label'>Profile Image :</label>
                        <div className="drop-section mb-3 col-md-4">
                              <div className="form-group inputDnD mb-0">
                              <div className="upload-overlay d-flex justify-content-center align-items-center">
                                    <div className="upload-wrapper">
                                    <i className="fa fa-upload"></i>
                                    <span><button type="button"  >Choose a file</button>or drag it here</span>
                                    </div>
                              </div>
                              <label className="sr-only" htmlFor="inputFile">File Upload</label>
                              <input type="file" className="form-control-file text-primary font-weight-bold" name='image' id="file" accept="image/x-png,image/gif,image/jpeg,image/jpg" multiple
                                    data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} />
                              </div>
                              <span className="error-block"> {errors.profile_image} </span>
                        </div>
                        <div className="col-md-4">
                              <div className="row justify-content-between my-2">
                              <div className="col-md-12 select-image">
                                    <div className="product-img"><img src={profile_image_url} onError={this.addDefaultSrc}/></div>
                              </div>
                              </div>
                        </div>
                        </div>
                  </form>
                  </div>
            </div>
            */}
            </Home>
            );
      }
}
const mapStateToProps = state => ({
permissionsList:state.admindata.admindata.rolepermissions,
});
export default connect( mapStateToProps, { commonApiCall } )(UpsertOwner)