import React from "react";
import { IMAGE_URL } from "../../config/configs";

const LeadUpdates = ({ latest_updates, addDefaultSrc }) => {
  // console.log(latest_updates);
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="row">
          <div className="col-md-12">

            <div className="owner-info d-flex flex-wrap align-items-center">
              <div className="owner-image">
                <img
                  className="img-fluid"
                  alt="Agent Image"
                  src={
                    latest_updates.customer_image
                      ? IMAGE_URL + latest_updates.customer_image
                      : "assets/images/no-image-user.png"
                  }
                  data-holder-rendered="true"
                  onError={addDefaultSrc}
                  />
              </div>
              <div className="owner-content">
              <h6>
              {latest_updates.customer_name}
                  </h6>
                  <a href={'mailto:'+latest_updates.email} className="card-text mb-1">{latest_updates.email}</a>
                  <a href={'tel:'+latest_updates.phone_number} className="d-inline-block text-primary">
                    {latest_updates.phone_number}
                  </a>
              </div>
              
            </div>

            {/* <div className="card flex-md-row mb-4 box-shadow h-md-250">
              <img
                className="card-img-left flex-auto d-none d-md-block"
                data-src="holder.js/200x250?theme=thumb"
                alt="Thumbnail [200x250]"
                src={
                  latest_updates.customer_image
                    ? IMAGE_URL + latest_updates.customer_image
                    : "assets/images/no-image-user.png"
                }
                data-holder-rendered="true"
                style={{ width: "50px", height: "50px" }}
              />
              <div className="card-body d-flex flex-column align-items-start p-2">
                <h5 className="mb-1">
                  <a className="text-dark" href="#">
                    {latest_updates.customer_name}
                  </a>
                </h5>
                <p className="card-text mb-1">{latest_updates.email}</p>
                <span className="d-inline-block text-primary">
                  {latest_updates.phone_number}
                </span>
              </div>
            </div> */}


          </div>
        </div>
      </div>
      <div className="col-md-9 property-contentblock">
        <div className="row">
          <div className="col-md-9">
            <h5 className="updateblock-title">Updated on: {latest_updates.updated_at}</h5>
          </div>
          <div className="col-md-3 text-right">
            <span className="status">
              {latest_updates.comment_status}
            </span>
          </div>
        </div>

        <p>{latest_updates.comment}</p>
      </div>
    </div>
  );
};

export default LeadUpdates;
