import React, { Component } from 'react'
import swal from 'sweetalert'
import Home from './Home'
import axios from 'axios'
import { API_URL } from '../config/configs'
import { ToastContainer, toast } from 'react-toastify';

const queryString = require('query-string')

class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newPassword: '',
      confirmPassword: '',
      error: {
        newPassword: '',
        confirmPassword: ''
      },
      confirmPasswordValidate: false,
      newPasswordValidate: false
    }
  }


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
    if (this.state.newPassword) {
      var regexp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&]{6,12}$/
      var newPasswordValidate = regexp.test(this.state.newPassword)
      newPasswordValidate
        ? this.setState({ newPasswordValidate: true })
        : this.setState({ newPasswordValidate: false })
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({ confirmPasswordValidate: true })
    } else {
      this.setState({ confirmPasswordValidate: false })
    }
    if (event.target.value) {
      this.setState({
        error: Object.assign(this.state.error, { [event.target.name]: '' })
      })
    } else {
      this.setState({
        error: Object.assign(this.state.error, {
          [event.target.name]: 'Enter  ' + [event.target.name]
        })
      })
    }
  }

  confirmPasswordHandleChange = event => {
    if (event.target.value === this.state.newPassword) {
      this.setState({
        confirmPassword: event.target.value,
        confirmPasswordValidate: true
      })
    } else {
      this.setState({
        confirmPassword: event.target.value,
        confirmPasswordValidate: false
      })
    }
    if (event.target.value) {
      this.setState({
        error: Object.assign(this.state.error, { [event.target.name]: '' })
      })
    } else {
      this.setState({
        error: Object.assign(this.state.error, {
          [event.target.name]: 'Enter  ' + [event.target.name]
        })
      })
    }
  }


  resetpassword=()=>{
    const { newPassword, confirmPassword } = this.state;
    const queryValues = queryString.parse(window.location.search)
    var body = { password: confirmPassword, token: queryValues.token }
    if ( newPassword.trim() === '' || confirmPassword.trim() === '') {
      toast.error("Please Reset Password !",  { position: "bottom-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
      return false
    }
   else if (newPassword !== confirmPassword) {
    toast.error("Please provide confirm password same as password !",  { position: "bottom-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
  } 
  else {
    axios({
      method: 'post',
      url: API_URL + '/admins/resetPasswordForAdmin',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(body)
    })
      .then(function (response) {
        let { data } = response
          if(data.statusCode===433){
            toast.error("Password should have 8 letters,one upperCase letter, one lowerCase letter, one digit and a special characterh !",  { position: "bottom-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
          }
          if(data.statusCode===234){
            swal('You have successfully reset the password','','success')
            // this.props.history.push('/')
          }
          if(data.statusCode===416){
            toast.error("Both passwords are same. Please choose another password !",  { position: "bottom-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
          }
      })
  }
  }

  render () {
    let {newPassword, confirmPassword, error, confirmPasswordValidate, newPasswordValidate } = this.state
    return (
      <div>
       
          <div>
            <div className='card-body'>
              <h4 className='card-title'>Admin Reset Password Form</h4>
              <div className='card-center-block'>
                <hr />
                <form className='form-sample'>
                  <div className='form-group row'>
                    <label className='col-sm-5 col-form-label'>
                      New Password
                    </label>
                    <div className='col-sm-7'>
                      <input
                        className='form-control'
                        type='password'
                        placeholder='Enter New Password'
                        name='newPassword'
                        value={newPassword}
                        onChange={this.handleChange}
                      />
                      {newPasswordValidate || !newPassword ? ( <span className='error-block'> {error.newPassword} </span> ) : null}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <label className='col-sm-5 col-form-label'>
                      Confirm Password
                    </label>
                    <div className='col-sm-7'>
                      <input
                        className='form-control'
                        type="password"
                        placeholder='Enter Confirm Password'
                        id='confirmpassword'
                        name='confirmPassword'
                        value={confirmPassword}
                        required
                        onChange={this.confirmPasswordHandleChange}
                      />
                      {confirmPasswordValidate || !confirmPassword ? ( <span className='error-block'>{error.confirmPassword} </span> ) :
                       ( <span className='error-block'> Password does not match{' '} </span> )}
                    </div>
                  </div>
                  <hr />
                  <div className='form-group row'>
                    <div className='button-group-container'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={this.resetpassword}
                      >
                        <span>Submit</span>
                      </button>
                      <button
                        type='button'
                        className='btn btn-warning'
                        onClick={() => this.props.history.push('/')}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
       
      </div>
    )
  }
}

export default ResetPassword
