import React, { Component } from 'react';
import { connect } from 'react-redux';
import Home from '../Home';
import StepZilla from "react-stepzilla";
import * as _ from "lodash";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
//import Step5 from './Step5';

import * as actions from '../../actions';

const nextText = {
    0: 'Next', 
    1: 'Next',
    2: 'Next', 
    3: 'Publish',
}

class AddProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            filestep: '',
            category_slug: "",
            category_name: "",
            category_id: "",
            property_id: "",
            service_id: "",
            attributesets: {},
            owneroptions: {},
            step1: {
                property_name: '',
                address: '',
                country: '',
                state: '',
                city: '',
                zipcode: '',
                latitude: '',
                longitude: '',
                description: '',
                serviceoptions: {},
                errors: {}
            },
            step2: {
                images: [],
                imagesPreviewUrls: [],
                imagesPreviewUrlsServer: [],
                coverImageKey: 0,
                uploadedimgcoverkey: '',
                errors:[]
            },
            step3: {
                attributes: [],
                images:[],
                imagesPreviewUrls: [],
                imagesPreviewUrlsServer: [],
                errors:[]
            },
            step4: {
                price: '',
                price_unit: '',
                owner_id:'',
                owner_name:'',
                remarks: '',
                errors:{}
            }
        };

    }
    

    componentDidMount() {
        let permissions = this.props.permissionsList;
        if (permissions !== "Admin") {
            let step4 = this.state.step4;
            step4['owner_id'] = this.props.admindata.data.id;
            step4['owner_name'] = this.props.admindata.data.first_name + ' ' +this.props.admindata.data.last_name;
            this.setState({ step4 });
        }
        this.getCategoryName();
        this.getOwnersList();
        this.getPropertyDetails();
    }
    
    getCategoryName = () => {
        let categoryList = this.props.categoryList;
        let category_slug = this.props.match.params.c_slug;
        this.setState({ category_slug: category_slug});
        let category = _.find(categoryList, ['category_slug', category_slug]);
        this.setState({ category_name: category.name, category_id: category.id}, () => this.getServices());
    }
        
    handleChangeStep1 = (event) => {
        let step1 = this.state.step1;
        step1[event.target.name] = event.target.value;

        if (event.target.value) {
            step1['errors'][event.target.name]= '';
        }
        this.setState({ step1 });
    };

    handleChangeStep12 = (event) => {
        let step1 = this.state.step1;
        step1[event.target.name] = event.target.value;

        if (event.target.value) {
            step1['errors'][event.target.name]= '';
        }
        this.setState({ step1 });
    };

    handleChangeService= (event) => {
        this.setState({ service_id: event.target.value });
        let { step3 } = this.state;
        step3['attributes'] = [];
        this.setState({ step3 });
    }

    handleChangeStep2 = (e) => {
        let errors = [];
        let step2 = this.state.step2;
        let files = Array.from(e.target.files);
        let formIsValid, maximages = false;
        
        files.forEach((file) => {
            //step2['images'] = file;
            formIsValid = true;
            let types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
            if (types.every(type => file.type !== type)) {
                formIsValid = false;
                errors.push('* ' + file.type + ' is not a supported format for '+file.name+'. Only JPEG, JPG, PNG and GIF is supported!');
            }
            if (file.size > 5242880) {
                formIsValid = false;
                errors.push('* ' + file.name + ' is too large! Maximum image upload size is 5 MB. Please select a smaller file!');
            }
            if(step2.images.length + step2.imagesPreviewUrlsServer.length >= 10) {
                formIsValid = false;
                maximages = true;
            }

            if(formIsValid) {
                step2.images.push(file);
                step2.imagesPreviewUrls.push(URL.createObjectURL(file));
            }
        });
        if(maximages) {
            errors.push('* You can not upload more then 10 images. Maximun 10 images are allowed!');
        }
        if(errors) {
            step2.errors = errors;
        } else {
            step2.errors = '';
        }
        this.setState({ step2 });
    }

    removeimages = (key, id) => {
        let { step2 } = this.state;
        if(!id) {
            _.remove(step2.images, function (e , k) {
                return k == key;
            });
            _.remove(step2.imagesPreviewUrls, function (e , k) {
                return k == key;
            });
    
            if(key == step2.coverImageKey) {
                step2['coverImageKey'] = 0;
            }
        } else {
            _.remove(step2.imagesPreviewUrlsServer, {id: id});
            let body = { item_id: id, type: "second" };
            let token = localStorage.getItem('token');
            let url = 'admin/removePropertyImage';
            let method = 'post';
            this.props.commonApiCall(url, method, body, token, null, this.props, (response) => {
                let responsedata = response.data;
                if (responsedata.status == 1) {
                } else {
                    toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                }
            });

            if(id == step2.uploadedimgcoverkey) {
                if(step2.imagesPreviewUrlsServer.length > 0) {
                    step2['uploadedimgcoverkey'] = step2.imagesPreviewUrlsServer[0].id
                } else {
                    step2['coverImageKey'] = 0;
                    step2['uploadedimgcoverkey'] = '';
                }
            }
        }
        this.setState({ step2 });
    }

    setAsCover = (key, id) => {
        let { step2 } = this.state;
        if(!id) {
            step2['coverImageKey'] = key;
            step2['uploadedimgcoverkey'] = '';
        } else {
            step2['uploadedimgcoverkey'] = id;
        }
        this.setState({ step2 });
    }
    
    handleChangeStep3 = (event) => {
        let step3 = this.state.step3;
        let handledata;
        if(event.target.type == 'checkbox' ) {
            let ishandledata = _.find(step3.attributes, { 'attribute_id': event.target.name, 'value': event.target.value });
            if(ishandledata == undefined) {
                ishandledata = _.find(step3.attributes, { 'attribute_id': parseInt(event.target.name), 'value': event.target.value });
            }
            if(!ishandledata) {
                handledata = {attribute_id: event.target.name, value: event.target.value};
                step3.attributes.push(handledata);
            } else {
                _.remove(step3.attributes, {value: event.target.value});
            }
        } else if(event.target.type == 'file') {
            let errors = [];
            let files = Array.from(event.target.files);
            let formIsValid, maximages = false;
            files.forEach((file) => {
                formIsValid = true;
                let types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
                if (types.every(type => file.type !== type)) {
                    formIsValid = false;
                    errors.push('* ' + file.type + ' is not a supported format for '+file.name+'. Only JPEG, JPG, PNG and GIF is supported!');
                }
                if (file.size > 5242880) {
                    formIsValid = false;
                    errors.push('* ' + file.name + ' is too large! Maximum image upload size is 5 MB. Please select a smaller file!');
                }

                if(step3.images.length + step3.imagesPreviewUrlsServer.length >= 10) {
                    formIsValid = false;
                    maximages = true;
                }

                if(formIsValid) {
                    handledata = {attribute_id: event.target.name, value: file};
                    step3.images.push(handledata);
                    step3.imagesPreviewUrls.push(URL.createObjectURL(file));
                }
            });
            if(maximages) {
                errors.push('* You can not upload more then 10 images. Maximun 10 images are allowed!');
            }
            if(errors) {
                step3.errors['images'] = errors;
            } else {
                step3.errors['images'] = '';
            }
        } else {
            let ishandledata = _.find(step3.attributes, ['attribute_id', event.target.name]);
            if(ishandledata == undefined) {
                ishandledata = _.find(step3.attributes, ['attribute_id', parseInt(event.target.name)]);
            }
            if(ishandledata) {
                handledata = {attribute_id: event.target.name, value: event.target.value};
                var index = _.indexOf(step3.attributes, ishandledata);
                step3.attributes.splice(index, 1, handledata);
            } else {
                handledata = {attribute_id: event.target.name, value: event.target.value};
                step3.attributes.push(handledata);
            }
        }
        if (event.target.value) {
            step3['errors'][event.target.name]= '';
        }

        this.setState({ step3 });
    };

    removestep3images = (key, id) => {
        let { step3 } = this.state;
        if(!id) {
            _.remove(step3.images, function (e , k) {
                return k == key;
            });
            _.remove(step3.imagesPreviewUrls, function (e , k) {
                return k == key;
            });
        } else {
            _.remove(step3.imagesPreviewUrlsServer, {item_attribute_id: id});
            _.remove(step3.attributes, {item_attribute_id: id});
            let body = { item_attribute_id: id, type: "third" };
            let token = localStorage.getItem('token');
            let url = 'admin/removePropertyImage';
            let method = 'post';
            this.props.commonApiCall(url, method, body, token, null, this.props, (response) => {
                let responsedata = response.data;
                if (responsedata.status == 1) {
                    // this.setState({
                    //     attributesets: responsedata.data
                    // });
                } else {
                    toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                }
            });
        }
        this.setState({ step3 });
    }

    handleChangeStep4 = (event) => {
        let step4 = this.state.step4;
        step4[event.target.name] = event.target.value;

        if (event.target.value) {
            step4['errors'][event.target.name]= '';
        }
        this.setState({ step4 });
    };

    getPropertyDetails = () => {
        let p_id = this.props.match.params.p_id;
        
        if (p_id) {
            this.setState({ property_id: p_id, isLoader: true });
            let body = { property_id: p_id };
            let token = localStorage.getItem('token');
            let url = 'admin/propertyDetails';
            let method = 'post';
            let { step1, step2, step3, step4, service_id, category_slug } = this.state;
            this.props.commonApiCall(url, method, body, token, null, this.props, (response) => {
                let responsedata = response.data;
                this.setState({ isLoader: false });
                if (responsedata.status == 1) {
                    if(responsedata.data.status == '4')  {
                        this.props.history.push(`/dashboard`);
                    } else {
                        let propertydata = responsedata.data;
                        let isCover = _.find(propertydata.image, ['is_default', 1]);
                        let isImages = _.filter(propertydata.attributes, { 'type': 'image' });
                        step1['property_name'] = propertydata.property_name;
                        step1['address'] = propertydata.property_address;
                        step1['description'] = propertydata.property_description;
                        service_id = propertydata.service_id;
                        step2['images'] = [];
                        step2['imagesPreviewUrls'] = [];
                        step2['imagesPreviewUrlsServer'] = propertydata.image;
                        if(isCover) {
                            step2['uploadedimgcoverkey'] = isCover.id;
                        }
                        for (let j in propertydata.attributes) {
                            delete propertydata.attributes[j].type;
                            delete propertydata.attributes[j].item_attribute_id;
                        }
                        step3['attributes'] = propertydata.attributes;
                        step3['imagesPreviewUrlsServer'] = isImages;
                        
                        step4['price'] = propertydata.price;
                        step4['price_unit'] = propertydata.price_unit;
                        step4['owner_id'] = propertydata.owner_id;
                        step4['remarks'] = propertydata.owner_remarks;

                        this.setState({ step1, step2, step3, step4, service_id });
                    }

                } else {
                    toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                }
            });
        }
    }

    addProperty = (step) => {
        let data, body, url, method;
        let { filestep, step1, step2, step3, step4, category_id, category_slug, service_id, property_id, category_name } = this.state;
        step2.errors = '';
        this.setState({ step2, filestep: step });
        if(step == 1) {
            data = { property_name: step1.property_name, address: step1.address, description: step1.description, latitude: step1.latitude, longitude: step1.longitude, category_id, service_id, property_id, owner_id: this.props.admindata.data.id };
            body = data;
            url = 'admin/addProperty';
            method = 'post';
            this.getAttributesets();
        } else if(step == 2) {
            const formData = new FormData();
            step2.images.forEach((file) => {
                formData.append('file', file);
            });
            formData.append('coverkey', step2.coverImageKey);
            formData.append('uploadedimgcoverkey', step2.uploadedimgcoverkey);
            formData.append('property_id', property_id);
            body = formData;
            url = 'admin/multipleImageUpload'; 
            method = 'formdata';
        } else if(step == 3) {
            const formData = new FormData();
            
            step3.images.forEach((att) => {
                formData.append('image' , att.value);
                // att.forEach((att1) => {
                formData.append('image_id', att.attribute_id);
                
            });
            formData.append('attribute',JSON.stringify(step3.attributes));
            formData.append('property_id', property_id);
            body = formData;
            url = 'admin/savePropertyThird'; 
            method = 'formdata';
        } else if(step == 4) {
            data = { property_id, owner_id: step4.owner_id, price: step4.price, price_unit: step4.price_unit, remarks: step4.remarks, service_id };
            body = data;
            url = 'admin/savePropertyFour';
            method = 'post';
        }
        
        if(url) {
            let token = localStorage.getItem('token');
            this.setState({ isLoader: true });
            this.props.commonApiCall( url, method, body, token, null, this.props, (response) => {
            let responsedata = response.data;
            this.setState({ isLoader: false });
            if (responsedata.status == 1) {
                if(step == 1) {
                    if(responsedata.data.property_id) {
                        this.setState({
                            property_id: responsedata.data.property_id
                        });
                    }
                } else if(step == 2) {
                    let { step2 } = this.state;
                    step2.images = [];
                    if(!_.isEmpty(responsedata.data)) {
                        step2.imagesPreviewUrls = [];
                        step2['imagesPreviewUrlsServer'] = responsedata.data;
                    }
                    this.setState({ step2 });
                } else if(step == 3) {
                    let { step3 } = this.state;
                    step3.images = [];
                    
                    if(!_.isEmpty(responsedata.data)) {
                        step3.imagesPreviewUrls = [];
                        step3.imagesPreviewUrlsServer.push.apply(step3.imagesPreviewUrlsServer, responsedata.data);
                        //step3.imagesPreviewUrlsServer.push(JSON.parse(JSON.stringify(responsedata.data)));
                    }
                    this.setState({ step3 });
                } else if(step == 4) {
                    swal({
                        title: category_name+' details has been saved successfully!',
                        icon: "success",
                    }).then((willDelete) => {
                        if (willDelete) {
                            this.props.history.push(`/properties/${category_slug}`);
                        }
                    });
                } 
                return true;
            } else {
                toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                return false;
            } 
            });
        }
    };

    getAttributesets = () => {
        let { category_id, service_id } = this.state;
        if (service_id && category_id) {
            var body = { category_id, service_id };
            let token = localStorage.getItem('token');
            var url = 'admin/getAttribute';
            var method = 'post';
            this.setState({ isLoader: true });
            this.props.commonApiCall(url, method, body, token, null, this.props, (response) => {
                let responsedata = response.data;
                this.setState({ isLoader: false });
                if (responsedata.status == 1) {
                    this.setState({
                        attributesets: responsedata.data
                    });
                } else {
                    toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                }
            });
        }
    }

    getServices = () => {
        let { category_id } = this.state;
        let data = { category_id };
        let body = data;
        let url = 'admin/servicecategories'; 
        let token = localStorage.getItem('token');
        var method = 'post';
        let { step1, service_id } = this.state;
        this.setState({ isLoader: true });
        this.props.commonApiCall( url, method, body, token, null, this.props, (response) => {
            let responsedata = response.data;
            this.setState({ isLoader: false });
            if (responsedata.status == 1) {
                step1['serviceoptions'] = responsedata.data;
                this.setState({
                    service_id:responsedata.data[0].id,
                    step1
                });
                return true;
            } else {
                toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                return false;
            } 
        });        
    }

    getOwnersList = () => {
        let body;
        let url = 'admin/getOwnerList'; 
        let token = localStorage.getItem('token');
        var method = 'post';
        this.setState({ isLoader: true });
        this.props.commonApiCall( url, method, body, token, null, this.props, (response) => {
            let responsedata = response.data;
            this.setState({ isLoader: false });
            if (responsedata.status == 1) {
                this.setState({
                    owneroptions:responsedata.data
                });
                return true;
            } else {
                toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                return false;
            } 
        });        
    }

    render(){
        let { category_name, category_slug, attributesets, owneroptions, service_id, step1, step2, step3, step4 } = this.state;
        const steps =
        [
            {name: category_name+' Info', component: <Step1 item_info={ step1 || {}} service_id={service_id} category_name={category_name} handleChangeStep1={this.handleChangeStep1} handleChangeRadio={this.handleChangeService} handleChangeStep12={this.handleChangeStep12}/>},
            {name: 'Upload Photos', component: <Step2 images={ step2 || []} category_name={category_name} handleChangeStep2={this.handleChangeStep2} removeimages={this.removeimages} setAsCover={this.setAsCover} />},
            {name: category_name+' Specifications', component: <Step3 attributesets={ attributesets || []} selectedAttributes={ step3 || [] } category_name={category_name} handleChangeStep3={this.handleChangeStep3} removestep3images={this.removestep3images}/>},
            {name: 'Configure Price & Owner', component: <Step4 step4_data={ step4 || [] } owneroptions={ owneroptions || []} category_name={category_name} permissions={this.props.permissionsList} handleChangeStep4={this.handleChangeStep4}/>}
        ]
        return (
            <Home>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='page-header border-bottom'>
                            <h3>Manage {category_name}</h3>
                            <ul className='breadcrumb '>
                            <li className='breadcrumb-item' onClick={()=>this.props.history.push('/dashboard')}>Home</li>
                            <li className='breadcrumb-item' onClick={()=>this.props.history.push('/properties/'+category_slug)}>{category_name}</li>
                            <li className='breadcrumb-item active'>Add New {category_name}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className='step-progress property-infoblock'>
                    <StepZilla steps={steps} nextButtonCls='btn btn-primary btn-primary btn-next btn-icon' backButtonCls='btn btn-prev btn-primary btn-primary' preventEnterSubmission={false} nextButtonText={nextText[this.state.filestep]} onStepChange={(step) => this.addProperty(step)} ></StepZilla>
                    {/* hocValidationAppliedTo={[1]} */}
                </div>
                {this.state.isLoader == true ? <div className='loader-block'>
                    <img src="/assets/images/loader2.gif" />
                </div> : null }
            </Home>
        )
    }
}

//export default HouseInfo;
const mapStateToProps = state => ({
  admindata: state.admindata.admindata,
  categoryList: state.admindata.admindata.data.categoryList,
  permissionsList: state.admindata.admindata.rolepermissions,
});
export default connect( mapStateToProps, actions )(AddProperty)