import axios from "axios";
import { API_URL } from "../config/configs";
import { CHANGE_PASSWORD, ADMIN_LOGIN, EDITADMIN_PROFILE, FORGOT_PASSWORD } from "./types";
import swal from 'sweetalert';

/*************************** ADMIN LOGIN ***************************/
export const adminLogin = (body, callback) => async dispatch => {
  try {
    let response = await axios.post(API_URL+'admin/login', body);
    let responsedata = response.data;
    if(responsedata.status == 1){
      localStorage.setItem("token",responsedata.data.token);
      
      let payload = {
        access_token : responsedata.data.token,
        data:responsedata.data,
        rolepermissions: responsedata.data.usertype 
      }

      dispatch({ type: ADMIN_LOGIN, payload });
    } 
    callback(response);
  } catch (error) {
    throw error;
  }
};

/*************************** Owner SignUp ***************************/
export const signUp = (body, callback) => async dispatch => {
  try {
    let response = await axios.post(API_URL+'admin/register', body);
    callback(response);
  } catch (error) {
    throw error;
  }
};

/*************************** ADMIN FORGOT PASSWORD ***************************/
export const adminForgotPassword = (body, callback) => async dispatch => {
  try {
    let response = await axios.post(API_URL+'admin/forgotpassword', body);
    let responsedata = response.data; 
      
    if(responsedata.code === 401){
      swal(responsedata.message, '', 'error').then((willDelete) => {
        if (willDelete) {
          localStorage.setItem('token', null);
          this.history.push('/');
        }
      });
    } else {
      let payload = {
        data:responsedata.data,
      }
      dispatch({ type: FORGOT_PASSWORD, payload });
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};

/*************************** UPDATE ADMIN PROFILE DETAILS ***************************/
export const updateAdminProfile = (body, callback) => async dispatch => {
  try {
    const token= localStorage.getItem("token");
    let response = await axios({
      method: 'post',
      url: API_URL +'admin/updatedetails',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      data: JSON.stringify(body),
    });
    let responsedata = response.data;
    if(responsedata.code === 401){
      swal(responsedata.message, '', 'error').then((willDelete) => {
        if (willDelete) {
          localStorage.setItem('token', null);
          this.history.push('/');
        }
      });
    } else {
      let payload = {
        access_token : responsedata.data.token,
        data:responsedata.data,
        rolepermissions: responsedata.data.usertype
      }
      dispatch({ type: EDITADMIN_PROFILE, payload });
      callback(response);
    } 
  } catch (error) {
    throw error;
  }
}

/*************************** ADMIN CHANGE PASSWORD ***************************/
export const changePassword = (body, callback) => async dispatch => {
  try {
    const token= localStorage.getItem("token");
    let response = await axios({
      method: 'post',
      url: API_URL +'admin/changepassword',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      data: JSON.stringify(body),
    });
    let responsedata = response.data;
    if(responsedata.code === 401){
      swal(responsedata.message, '', 'error').then((willDelete) => {
        if (willDelete) {
          localStorage.setItem('token', null);
          this.history.push('/');
        }
      });
    } else {
      let payload = {
        data:responsedata.data
      }
      dispatch({ type: CHANGE_PASSWORD, payload });
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};


