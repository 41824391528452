import React, { Component } from 'react';
class Untitled extends Component {
  constructor(props){
    super(props)
    this.  state = {  }

  }
  render() { 
    return ( 
      <div>
        <center><img src="/assets/images/pagenotfound.jpg" alt=""/></center>
        {/* <button onClick={()=>this.props.history.push('/')}>BACK TO DASHBOARD</button> */}
      </div>
     );
  }
}
 
export default Untitled;

// import React, { Component } from 'react';
// import './App.css';
// import { Provider } from 'react-redux';

// import { PersistGate } from 'redux-persist/integration/react';
// import {store, persistor} from './store';

// import {BrowserRouter as Router ,Route ,Switch,Redirect} from 'react-router-dom' ;

// import Login from './pages/Login';
// import ForgotPassword from './pages/ForgotPassword';

// import Dashboard from './pages/Dashboard';
// import AdminProfile from './pages/AdminProfile';
// import AdminChangePassword from './pages/AdminChangePassword';

// import UserManagement from './pages/UserManagement/UserManagement';
// import UserDetails from './pages/UserManagement/UserDetails';

// import QuestionaireManagement from './pages/QuestionaireManagement/QuestionaireManagement'
// import QuestionsManagement from './pages/QuestionaireManagement/QuestionsManagement';
// import LinkQuestions from './pages/QuestionaireManagement/LinkQuestions'

// import FeedbackManagement from './pages/FeedbackManagement/FeedbackManagement';

// import ConsultantManagement from './pages/ConsultantManagement/ConsultantManagement'
// import Consultant from './pages/ConsultantManagement/consultant';

// import TransactionManagement from './pages/TransactionsManagement/TransactionsManagement'

// import ReportManagement from './pages/ReportManagement/ReportManagement'
// import ViewReportedUser from './pages/ReportManagement/viewReportedUser'

// import SubscriptionsManagement from './pages/SubscriptionManagement/SubscriptionsManagement'

// import Configurations from './pages/Configurations/ConfigurationManagement'
// import CmsManagement from './pages/Configurations/CmsManagement/CmsManagement'
// import AddCms from './pages/Configurations/CmsManagement/Cms'
// import EmailManagement from './pages/Configurations/EmailManagement/EmailManagement'
// import AddEmail from './pages/Configurations/EmailManagement/Email'
// import Notifications from './pages/Configurations/Notifications'

// import SpeedDatingManagement from './pages/EventManagement/SpeedDatingEvent/SpeedDatingManagement'
// import AddSpeedDatingEventManagement from './pages/EventManagement/SpeedDatingEvent/SpeedDating'
// import AddWebinar from './pages/EventManagement/WebinarEvent/Webinar'
// import WebinarManagement from './pages/EventManagement/WebinarEvent/WebinarManagement'

// import Nomatch from './pages/NoMatch'

// import ResetPassword from './pages/ResetPassword';
// // import Test from './components/Test';

// class App extends Component {

//   constructor(props) {
//     super (props);
//     this.state = {
      
//     }
//   }

//   Authorization = () => {
//     return localStorage.getItem('access_token') ? true : false
    
//   }
 
//   render() {
//     const PrivateRoute = ({ component: Component, ...rest }) => (
//       <Route {...rest} render={(props) => (
//          this.Authorization()  ?  
//         <Component {...props} /> 
//          : <Redirect to='/' />
//       )} />
//     );

//     const LoginCheckRoute = ({ component: Component, ...rest }) => (
//       <Route {...rest} render={(props) => (
//         this.Authorization()
//           ? <Redirect to='/dashboard' />
//           : <Component {...props} />
//       )} />
//     );
//     const ResetPasswordCheckRoute = ({ component: Component, ...rest }) => (
//       <Route {...rest} render={(props) => (
//         this.Authorization()
//           ? <Redirect to='/dashboard' />
//           : <Component {...props} />
//       )} />
//     );
//     return (
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <Router>
//             <div>
//               <Switch>
//                <Route  path='/forgotpassword' component={ForgotPassword} />
//                 <ResetPasswordCheckRoute path='/resetpassword' component={ResetPassword} />
                
//                 <LoginCheckRoute exact path='/' component={Login} />
//                 <LoginCheckRoute exact path='/login' component={Login} />

//                 <PrivateRoute path='/dashboard' component={Dashboard} />
//                 <PrivateRoute exact path='/adminprofile' component={AdminProfile} />
//                 <PrivateRoute exact path='/changepassword' component={AdminChangePassword} />

//                 <PrivateRoute exact path='/userlist' component={UserManagement} />
//                 <PrivateRoute exact path='/userdetails' component={UserDetails} />

//                 <PrivateRoute path='/questionslist' component={QuestionaireManagement} />
//                 <PrivateRoute path='/editquestion' component={QuestionsManagement} />
//                 <PrivateRoute path='/addquestion' component={QuestionsManagement} />
//                 <PrivateRoute path='/linkquestions' component={LinkQuestions} />

//                 <PrivateRoute path='/feedbackmanagement' component={FeedbackManagement} />


//                 <PrivateRoute path='/consultantmanagement' component={ConsultantManagement} />
//                 <PrivateRoute path='/consultant' component={Consultant} />


//                 <PrivateRoute path='/transactionhistory' component={TransactionManagement} />

//                 <PrivateRoute path='/reportslist' component={ReportManagement} />
//                 <PrivateRoute path='/viewreporteduser' component={ViewReportedUser} />

//                 <PrivateRoute path='/subscriptionlist' component={SubscriptionsManagement} />

//                 <PrivateRoute path='/configurations' component={Configurations} />
//                 <PrivateRoute path='/cmslist' component={CmsManagement} />
//                 <PrivateRoute path='/addcms' component={AddCms} />
//                 <PrivateRoute path='/editcms' component={AddCms} />
//                 <PrivateRoute path='/emaillist' component={EmailManagement} />
//                 <PrivateRoute path='/addemail' component={AddEmail} />
//                 <PrivateRoute path='/editemail' component={AddEmail} />
//                 <PrivateRoute path='/notifications' component={Notifications} />

//                 <PrivateRoute path='/speedDatingEventlist' component={SpeedDatingManagement} />
//                 <PrivateRoute path='/addspeedDatingevent' component={AddSpeedDatingEventManagement} />
//                 <PrivateRoute path='/editspeedDatingevent' component={AddSpeedDatingEventManagement} />

//                 <PrivateRoute path='/webinarlist' component={WebinarManagement} />
//                 <PrivateRoute path='/addwebinar' component={AddWebinar} />
//                 <PrivateRoute path='/editwebinar' component={AddWebinar} />
//                 {/* <Route path='/test' component={Test} /> */}

// 	            	<PrivateRoute path='/*' component = {Nomatch} />

//               </Switch>
//             </div>
//           </Router>
//         </PersistGate>
//       </Provider>

//     );
//   }
// }

// export default App;  
