import { CHANGE_PASSWORD, ADMIN_LOGIN, EDITADMIN_PROFILE } from '../actions/types'
const INITIAL_STATE = {
  admindata: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return state;
    case EDITADMIN_PROFILE:
      return  Object.assign({},state,{admindata:action.payload});
    case ADMIN_LOGIN:
      return  Object.assign({},state,{admindata:action.payload});
    default:
      return state;
  }
}
