import { USER_DETAILS } from "./types";
import axios from "axios";
import { API_URL } from "../config/configs";
import swal from "sweetalert";

//**************************** USER DETAILS********************************************//
export const getUserDetails = (body, callback) => async dispatch => {
  try {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "post",
      url: API_URL + "admin/getuserdetails",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: JSON.stringify(body)
    });

    let responsedata = response.data;
    if (responsedata.code === 401) {
      swal(responsedata.message, "", "error").then(willDelete => {
        if (willDelete) {
          localStorage.setItem("token", null);
          this.history.push("/");
        }
      });
    } else {
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};

/*************************** ADD USER ***************************/
export const addUser = (body, callback) => async dispatch => {
  try {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "post",
      url: API_URL + "admin/adduser",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: JSON.stringify(body)
    });

    let responsedata = response.data;
    if (responsedata.code === 401) {
      swal(responsedata.message, "", "error").then(willDelete => {
        if (willDelete) {
          localStorage.setItem("token", null);
          this.history.push("/");
        }
      });
    } else {
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};

/*************************** UPDATE USER ***************************/
export const updateUser = (body, callback) => async dispatch => {
  try {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "post",
      url: API_URL + "admin/updateuser",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: JSON.stringify(body)
    });

    let responsedata = response.data;
    if (responsedata.code === 401) {
      swal(responsedata.message, "", "error").then(willDelete => {
        if (willDelete) {
          localStorage.setItem("token", null);
          this.history.push("/");
        }
      });
    } else {
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};

/*************************** ADD OWNER ***************************/
export const addOwner = (body, callback) => async dispatch => {
  try {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "post",
      url: API_URL + "admin/addOwner",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: JSON.stringify(body)
    });

    let responsedata = response.data;
    if (responsedata.code === 401) {
      swal(responsedata.message, "", "error").then(willDelete => {
        if (willDelete) {
          localStorage.setItem("token", null);
          this.history.push("/");
        }
      });
    } else {
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};

/*************************** UPDATE OWNER ***************************/
export const updateOwner = (body, callback) => async dispatch => {
  try {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "post",
      url: API_URL + "admin/updateowner",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: JSON.stringify(body)
    });

    let responsedata = response.data;
    if (responsedata.code === 401) {
      swal(responsedata.message, "", "error").then(willDelete => {
        if (willDelete) {
          localStorage.setItem("token", null);
          this.history.push("/");
        }
      });
    } else {
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};

/*************************** ADD Lead Updates ***************************/
export const addLeadUpdate = (body, callback) => async dispatch => {
  try {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "post",
      url: API_URL + "admin/add-lead-updates",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: JSON.stringify(body)
    });

    let responsedata = response.data;
    if (responsedata.code === 401) {
      swal(responsedata.message, "", "error").then(willDelete => {
        if (willDelete) {
          localStorage.setItem("token", null);
          this.history.push("/");
        }
      });
    } else {
      callback(response);
    }
  } catch (error) {
    throw error;
  }
};
