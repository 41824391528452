import { combineReducers } from 'redux';
import adminprofile from './adminprofile';
import user from './user'
import commonReducers from './commonReducers'


export default combineReducers({
  admindata : adminprofile,
  user: user,
  commonReducers:commonReducers
});
