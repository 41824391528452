import React from "react";
import { IMAGE_URL } from "../../config/configs";
const CustomerInfo = ({ customer_info, addDefaultSrc }) => {
  return (
    <div className="owner-info d-flex flex-wrap align-items-center">
      <div className="owner-image">
        <img className="img-fluid"
            alt="Customer Image"
            src={
              customer_info.profile_image
                ? IMAGE_URL + customer_info.profile_image
                : "assets/images/no-image-user.png"
            }
            data-holder-rendered="true"
            style={{ width: "100px", height: "100px" }}
            onError={addDefaultSrc}
          />
      </div>
      <div className="owner-content">
        <h6>
          {customer_info.fullname}
        </h6>
        <a href={'mailto:'+customer_info.email} className="card-text mb-1">{customer_info.email}</a>
        <a href={'tel:'+customer_info.phone_number} className="d-inline-block text-primary">
          {customer_info.phone_number}
        </a>
      </div> 
    </div>
  );
};

export default CustomerInfo;
