import React from "react";
import { IMAGE_URL } from "../../config/configs";

const AgentInfo = ({ agent_info, addDefaultSrc }) => {
  return (
    <div className="owner-info d-flex flex-wrap align-items-center">
      <div className="owner-image">
        <img
          className="img-fluid"
          alt="Agent Image"
          src={
          agent_info.profile_image
            ? IMAGE_URL + agent_info.profile_image
            : "assets/images/no-image-user.png"
          }
          data-holder-rendered="true"
          style={{ width: "100px", height: "100px" }}
          onError={addDefaultSrc}
        />
      </div>
      <div className="owner-content">
      <h6>
      {agent_info.fullname}
          </h6>
          <a href={'mailto:'+agent_info.email} className="card-text mb-1">{agent_info.email}</a>
          <a href={'tel:'+agent_info.phone_number} className="d-inline-block text-primary">
            {agent_info.phone_number}
          </a>
      </div>
      
    </div>



    // <div className="card flex-md-row mb-4 box-shadow h-md-250">
    //   <img
    //     className="card-img-left flex-auto d-none d-md-block"
    //     data-src="holder.js/200x250?theme=thumb"
    //     alt="Thumbnail [200x250]"
    //     src={
    //       agent_info.profile_image
    //         ? IMAGE_URL + agent_info.profile_image
    //         : "assets/images/no-image-user.png"
    //     }
    //     data-holder-rendered="true"
    //     style={{ width: "100px", height: "100px" }}
    //   />
    //   <div className="card-body d-flex flex-column align-items-start">
    //     <h5 className="mb-1">
    //       <a className="text-dark" href="#">
    //         {agent_info.fullname}
    //       </a>
    //     </h5>
    //     <p className="card-text mb-1">{agent_info.email}</p>
    //     <span className="d-inline-block text-primary">
    //       {agent_info.phone_number}
    //     </span>
    //   </div>
    // </div>
  );
};

export default AgentInfo;
