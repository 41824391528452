module.exports = {
  // STAGING
  // API_URL: "http://seatimeapi.node.indianic.com/api/",
  // IMAGE_URL: "http://seatimeapi.node.indianic.com/",
  // CSV_URL: "http://seatimeapi.node.indianic.com/downloads/csv/",
  // EXCEL_URL: "http://seatimeapi.node.indianic.com/downloads/excel/",
  // PORT: 4180

  API_URL: "https://api.seastime.com/api/",
  IMAGE_URL: "https://api.seastime.com/",
  CSV_URL: "https://api.seastime.com/downloads/csv/",
  EXCEL_URL: "https://api.seastime.com/downloads/excel/",
  PORT: 4180,

  // DIV
  // API_URL: "http://10.2.2.47:5020/api/",
  // IMAGE_URL:"http://10.2.2.47:5020/",
  // CSV_URL:"http://10.2.2.47:5020/downloads/csv/",
  // EXCEL_URL:"http://10.2.2.47:5020/downloads/excel/",
  // PORT: 5155

  // SHUBH
  // API_URL: "http://10.2.4.127:5020/api/",
  // IMAGE_URL:"http://10.2.4.127:5020",
  // CSV_URL:"http://10.2.4.127:5020/downloads/csv/",
  // EXCEL_URL:"http://10.2.4.127:5020/downloads/excel/",
  // PORT: 5155
};
