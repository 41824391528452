import React, { Component } from 'react';
import Home from '../pages/Home';
import { changePassword } from '../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';

class ChangePassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
			isLoader: false,
      old_password: '',
      new_password: '',
      confirm_password: '',
      error: {
        old_password: '',
        new_password: '',
        confirm_password: ''
      },
      confirmPasswordValidate: false,
      newPasswordValidate: false
    }
    this.changePassword = this.changePassword.bind(this);
  }

  // ################## Edit AdminData ###############
	changePassword = (e) => {
    e.preventDefault();		
      if(this.state.new_password !== this.state.confirm_password) {
        swal("New Password and Confirm Password does not match!", '', 'error')
        return false;
      }
			let body = { old_password: this.state.old_password, password: this.state.new_password };
			this.setState({ isLoader: true });
			this.props.changePassword(body, (response) => {
				let responsedata = response.data;
        this.setState({ isLoader: false });
				if (responsedata.status == 1) {
				swal({
					title: responsedata.message,
					icon: "success",
					}).then((willDelete) => {
						if (willDelete) {
							this.props.history.push('/dashboard');
						}
					});
				} else {
					toast.error(responsedata.message,  { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
				}
			});
	}

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  confirmPasswordHandleChange = event => {
    if (event.target.value === this.state.new_password) {
      this.setState({
        confirm_password: event.target.value,
        confirmPasswordValidate: true
      })
    } else {
      this.setState({
        confirm_password: event.target.value,
        confirmPasswordValidate: false
      })
    }
    if (event.target.value) {
      this.setState({
        error: Object.assign(this.state.error, { [event.target.name]: '' })
      })
    } else {
      this.setState({
        error: Object.assign(this.state.error, {
          [event.target.name]: 'Enter Confirm Password!'
        })
      })
    }
  }

  render () {
    return (
      <div>
        <Home>
				<ToastContainer />
          <div className='row'>
            <div className='col-md-12'>
              <div className='page-header border-bottom'>
                <h3>Change Password</h3>
                <ul className='breadcrumb '>
                  <li className='breadcrumb-item'>Home</li>
                  <li className='breadcrumb-item active'>Change Password</li>
                </ul>
              </div>
            </div>
          </div>
          <form className='form-sample card transparent no-shadow'>
        
            <div className='card-body'>
                <div className='white-bg box-shadow br-4 border-secondary'>
                  <div className='card-header'>
                  <p className='mb-0 font-15'>Admin Password Update Form</p>
                </div>
                <div className='card-body pt-30 light-grey'>
                  <div className=' animated fadeIn mb-2'>
                    <div className='form-material row user-wrapper setting-block'>
                      <div className='form-group col-md-4'>
                        <label className='col-form-label '>Old Password</label>
                       
                          <input
                            className='form-control'
                            type='password'
                            name='old_password'
                            value={this.state.old_password}
                            placeholder='Enter Old Password'
                            id='old_password'
                            required
                            onChange={this.handleChange}
                          />
                          <span className='error-block'> {this.state.error.old_password}</span>
                     
                      </div>
                      <div className='form-group col-md-4'>
                        <label className='col-form-label'>New Password</label>
                    
                          <input
                            className='form-control'
                            type='password'
                            name='new_password'
                            value={this.state.new_password}
                            placeholder='Enter New Password'
                            id='new_password'
                            required
                            onChange={this.handleChange}
                          />
                          {this.state.newPasswordValidate || !this.state.new_password ? (
                            <span className='error-block'>
                              {' '}
                              {this.state.error.new_password}
                            </span>
                          ) : null}
                 
                      </div>
                      <div className='form-group col-md-4'>
                        <label className='col-form-label'>
                          Confirm Password
                        </label>
                       
                          <input
                            className='form-control'
                            type='password'
                            name='confirm_password'
                            value={this.state.confirm_password}
                            placeholder='Enter Confirm Password'
                            id='confirm_password'
                            required
                            onChange={this.confirmPasswordHandleChange}
                          />
                          {this.state.confirmPasswordValidate || !this.state.confirm_password ? (
                            <span className='error-block'>
                              {this.state.error.confirm_password}
                            </span>
                          ) : (
                            <span className='error-block'>
                              Password does not match!
                            </span>
                          )}
                        
                      </div>
                    </div>
                  </div>
                </div>
             
                
                </div>
            </div>

            <div className='card-body'>
                <div className='button-continer text-right'>
                  <button type='button' className='btn btn-outline-secondary btn-sm' onClick={() => this.props.history.push('/dashboard')} > Cancel </button>
                  <button type='button' className='btn btn-primary btn-large' disabled={!this.state.value && !this.state.confirmPasswordValidate} onClick={this.changePassword} > <span>Submit</span> </button>
                </div>
              </div>
          </form>
					{this.state.isLoader == true ? <div className='loader-block'>
						<img src="/assets/images/loader2.gif" />
					</div> : null }
        </Home>
      </div>
    )
  }
}

export default withRouter(connect(null, { changePassword })(ChangePassword))
