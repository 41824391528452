import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp } from '../actions';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { IMAGE_URL } from '../config/configs';

class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phonenumber: '',
      password: '',
      confirmpassword: '',
      errors: {}
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  signup = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let { firstname, lastname, email, phonenumber, password } = this.state;
      var body = { first_name: firstname, last_name:lastname, email, phone_number:phonenumber, password };
      this.props.signUp(body, (response) => {
        let responsedata = response.data;
        if (responsedata.status == 1) {
          swal({
            title: responsedata.message,
            icon: "success",
          }).then((willDelete) => {
            if (willDelete) {
              this.props.history.push('/login');
            }
          });
        } else {
          toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
        }
      })
    } else {
      return false;
    }
  }

  validateForm() {
    let errors = {};
    let formIsValid = true;
    let emailpattern = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let phone_pattern = new RegExp(/^\d{9,13}$/);

    if (!this.state.firstname) {
      formIsValid = false
      errors['firstname'] = '* First name is required!';
    }

    if (!this.state.lastname) {
      formIsValid = false
      errors['lastname'] = '* Last name is required!';
    }

    if (!this.state.email) {
      formIsValid = false;
      errors['email'] = '* Email address is required!';
    } else if (typeof this.state.email !== 'undefined') {
      if (!emailpattern.test(this.state.email)) {
        formIsValid = false;
        errors['email'] = '* Enter a valid Email address!';
      }
    }

    if (!this.state.phonenumber) {
      formIsValid = false;
      errors['phonenumber'] = '* Phone number is required!';
    } else if (typeof this.state.phonenumber !== 'undefined') {
      if (!phone_pattern.test(this.state.phonenumber)) {
        formIsValid = false;
        errors['phonenumber'] = '* Enter a valid Phone number!';
      }
    }

    if (!this.state.password) {
      formIsValid = false;
      errors['password'] = '* Password is required!';
    }

    if (!this.state.confirmpassword) {
      formIsValid = false;
      errors['confirmpassword'] = '* Confirm Password is required!';
    }

    if (this.state.password !== this.state.confirmpassword) {
      formIsValid = false;
      errors['confirmpassword'] = '* Password and Confirm Password does not match!';
    }

    this.setState({ errors: errors })
    return formIsValid;
  }

  render() {
    return (
      <div className="container-scroller">
        <ToastContainer />
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth login-full-bg">
            <div className="row w-100 margin-l-0">
              <div className="col-lg-6 col-sm-8 mx-auto authentication-form">
                <div className="auth-form-light">
                <div className="login-header mb-5">
                  <img src='/assets/images/logo.svg' alt='logo' width='18' height='22' className='logo' />
                  <h1 className='logo-text'>Super Admin</h1>
                  <h2>Welcome</h2>
                  {/* <h2>Forgot Password</h2> */}
                  <p>Per elit bibbendum ridiculus hendreit proin id senectus vestibulum consectetur sit ad mus</p>
                </div>
           
                  <form autoComplete="off" onSubmit={this.forgotPassword} className='authenticate-block'>
                    <h5 className='text-center mb-20 subheading'> Please fill the required fields </h5>
                    
                    <div className="form-row">
                      <div className="form-group col-md-6 mb-10" >
                        <input className="form-control" type="text" name="firstname" value={this.state.firstname} placeholder="First name" onChange={this.handleChange} />
                        <span className='error-block'> {this.state.errors.firstname}</span>
                      </div>
                      
                      <div className="form-group col-md-6 mb-10" >
                        <input className="form-control" type="text" name="lastname" value={this.state.lastname} placeholder="Last name" onChange={this.handleChange} />
                        <span className='error-block'> {this.state.errors.lastname}</span>
                      </div>
                    </div>
                    
                    
                    <div className="form-group mb-10" >
                      <input className="form-control" type="email" name="email" value={this.state.email} placeholder="Email ID" onChange={this.handleChange} />
                      <span className='error-block'> {this.state.errors.email}</span>
                    </div>

                    <div className="form-group mb-10" >
                      <input className="form-control" type="number" name="phonenumber" value={this.state.phonenumber} placeholder="Phone No." onChange={this.handleChange} />
                      <span className='error-block'> {this.state.errors.phonenumber}</span>
                    </div>

                    <div className="form-group mb-10" >
                      <input className="form-control" type="password" name="password" value={this.state.password} placeholder="Password" onChange={this.handleChange} />
                      <span className='error-block'> {this.state.errors.password}</span>
                    </div>

                    <div className="form-group" >
                      <input className="form-control" type="password" name="confirmpassword" value={this.state.confirmpassword} placeholder="Confirm Password" onChange={this.handleChange} />
                      <span className='error-block'> {this.state.errors.confirmpassword}</span>
                    </div>

                    <div className="text-center">
                      {/* <div className="col-md-6"> <button className="btn btn-block btn-outline-primary btn-lg font-weight-medium" type="submit" onClick={() => this.props.history.push('/')}>cancel</button></div> */}
                      <button className="btn btn-primary font-weight-medium btn-md" type="button" onClick={this.signup}>Sign Up</button>
                    </div>
                  </form>
          
                <div className='login-header color-white mt-5 font-weight-light font-16'>
                  {/*<img src='/assets/images/logo.svg' alt='logo' />*/}
                  Already have an account? <Link  to="/" className='auth-link'> Sign In</Link>
                </div>
                </div>

              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    );
  }
}

export default connect(null, { signUp })(withRouter(SignUp));
