import React, { Component } from 'react';
import { connect } from "react-redux";
import swal from 'sweetalert';
import Home from './Home';
import { updateAdminProfile } from '../actions';
import { IMAGE_URL } from '../config/configs';
import { toast } from 'react-toastify';

class AdminProfile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoader: false,
			userdetails: {
				'first_name': '',
				'last_name': '',
				'email': '',
				'phone_number': '',
				'address': '',
			},
			profile_image_url: '',
			errors: {}
		};
		//this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.setState({ userdetails: this.props.admindata.data });
		let imageURL;
		if (this.props.admindata.data.profile_image) {
			imageURL = IMAGE_URL+ this.props.admindata.data.profile_image;
		} else {
			imageURL = "/assets/images/no-image-icon-4.png";
		}
		this.setState({ profile_image_url: imageURL });
	}

	// ################## Edit AdminData ###############
	editAdminProfile = (e) => {
		e.preventDefault();
		if (this.validateForm()) {
			let body = this.state.userdetails;
			this.setState({ isLoader: true });
			this.props.updateAdminProfile(body, (response) => {
				let responsedata = response.data;
				this.setState({ isLoader: false });
				if (responsedata.status == 1) {
					swal({
						title: responsedata.message,
						icon: "success",
					}).then((willDelete) => {
						if (willDelete) {
							this.props.history.push('/dashboard');
						}
					});
				} else {
					toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
				}
			});
		} else {
			return false;
		}
	}

	validateForm() {
		let errors = {};
		let formIsValid = true;


		let image = document.getElementById("file").files[0];
		let types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
		if (types.every(type => image.type !== type)) {
		  formIsValid = false;
		  toast.error('* '+image.type+' is not a supported format. Only JPEG, JPG, PNG and GIF is supported!', { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
		}
	
		if (image.size > 5242880) {
		  formIsValid = false;
		  toast.error('* '+image.name+' is too large! Maximum image upload size is 5 MB. Please select a smaller file!', { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
		}

		if (!this.state.userdetails.first_name) {
			formIsValid = false;
			errors['first_name'] = 'First Name is required!';
		}

		if (!this.state.userdetails.last_name) {
			formIsValid = false;
			errors['last_name'] = 'Last Name is required!';
		}

		if (this.state.userdetails.phone_number) {
			var pattern = new RegExp(/^\d{9,13}$/);
			if (!pattern.test(this.state.userdetails.phone_number)) {
				formIsValid = false;
				errors['phone_number'] = 'Enter a valid Phone number!';
			}
		}
		this.setState({ errors: errors })
		return formIsValid;
	}

	fileUploadSubmit = () => {
		var imagefile = document.querySelector('#file');
		let userdetails = this.state.userdetails;
		this.getBase64(imagefile.files[0], (result) => {
			userdetails['new_profile_image'] = result;
			this.setState({ userdetails });
		});
		this.setState({ 'profile_image_url': URL.createObjectURL(imagefile.files[0]) });
	}

	getBase64(file, callback) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			callback(reader.result)
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	handleChange = (e) => {
		if (e.target.value) {
			let userdetails = this.state.userdetails;
			userdetails[e.target.name] = e.target.value;
			this.setState({ userdetails });
		}
	}

	addDefaultSrc(ev){
		ev.target.src = "/assets/images/no-image-user.png";
	}

	render() {
		let errors = this.state.errors;
		return (
			<div>
				<Home>
					<div className="row">
						<div className="col-md-12">
							<div className="page-header border-bottom">
								<h3>Profile</h3>
								<ul className="breadcrumb ">
									<li className="breadcrumb-item">Home</li>
									<li className="breadcrumb-item active">Profile</li>
								</ul>
							</div>
						</div>
					</div>
					<form className="form-sample card transparent no-shadow form-material">
					<div className="card-body">
				
						<div className='white-bg box-shadow br-4 border'>
							<div className="card-header border-bottom">
								<p className='mb-0 font-15'>Personal Info</p>
							</div>
							<div className="card-body pt-30 light-grey">
								<div className='row'>
									<div className='col-md-4'>
										<div className=' animated fadeIn mb-2 pl-40 pr-40'>
											<div className="form-group">
												<div className="user-profile-img mr-4">
													<div className="inner-image">
														<img src={this.state.profile_image_url ? this.state.profile_image_url : "/assets/images/no-image-icon-4.png"} alt="Profile image" onError={this.addDefaultSrc}/>
														</div>
													<div className="upload-file">
														<label>
															{/* <input type="file" className="file-input" name='profile_image' id="file" accept="image/x-png,image/gif,image/jpeg,image/jpg" data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} /> */}
															{/* <input className="file-input" type="file" name="profile_image" accept="image/*" id="file" onClick={this.fileUploadSubmit.bind(this)} /> */}
															<input type="file" className="file-input form-control-file text-primary font-weight-bold" name='profile_image' id="file" accept="image/*" data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} />
														</label>
														<a><span className='camera-image'><img src='/assets/images/camera.png' alt='Upload Image' width='36' height='31' /></span></a>
													</div>
												</div>
										</div>
										</div>
									</div>
									<div className='col-md-8 profile-form'>
										<div className='animated fadeIn mb-2'>
											<div className='form-material row user-wrapper'>
												<div className="form-group col-md-6">
													<label className="col-form-label">First Name</label>
													<div className="form-field">
														<input className={errors.first_name ? 'form-control input-error' : 'form-control'} type="text" name="first_name" placeholder="Enter First Name" id="first_name" required value={this.state.userdetails.first_name} onChange={this.handleChange} />
														<span className='error-msg'>{errors.first_name}</span>
													</div>
												</div>
												<div className="form-group col-md-6">
													<label className="col-form-label">Last Name</label>
													<div className="form-field">
														<input className={errors.last_name ? 'form-control input-error' : 'form-control'} type="text" name="last_name" placeholder="Enter Last Name" id="last_name" required value={this.state.userdetails.last_name} onChange={this.handleChange} />
														<span className='error-msg'>{errors.last_name}</span>
													</div>
												</div>

												<div className="form-group col-md-6">
													<label className="col-form-label">Email</label>
													<div className="form-field">
														<input className="form-control" readOnly type="text" name="email" placeholder="Enter email" id="email" required value={this.state.userdetails.email} onChange={this.handleChange} />
														<div className="error-block">
														</div>
													</div>
												</div>
												<div className="form-group col-md-6">
													<label className="col-form-label">Phone number</label>
													<div className="form-field">
														<input className={errors.phone_number ? 'form-control input-error' : 'form-control'} minLength={10} maxLength={10} type="text" name="phone_number" placeholder="Enter Phone number" id="phone_number" required value={this.state.userdetails.phone_number} onChange={this.handleChange} />
														<span className='error-msg'>{errors.phone_number}</span>
													</div>
												</div>

												<div className="form-group col-md-6">
													<label className="col-form-label">Address</label>
													<div className="form-field">
														<input className={errors.address ? 'form-control input-error' : 'form-control'} type="text" name="address" placeholder="Enter Address" id="address" required value={this.state.userdetails.address} onChange={this.handleChange} />
													</div>
												</div>
												
											</div>
										</div>		
									</div>
								</div>
							</div >
						</div>
					</div >
					<div className="card-body">
						<div className="button-continer text-right">
							<button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.props.history.push('/dashboard')}>Cancel</button>
							<button type="button" className="btn btn-primary btn-large" onClick={this.editAdminProfile}> <span>Submit</span> </button>
						</div>
					</div>

					</form>
					{this.state.isLoader == true ? <div className='loader-block'>
						<img src="/assets/images/loader2.gif" />
					</div> : null }
				</Home>
			</div>

		);
	}
}

const mapStateToProps = state => ({
	admindata: state.admindata.admindata,
	editAdminProfileData: state.commonReducers.edited1Details
});

export default connect(mapStateToProps, { updateAdminProfile })(AdminProfile);