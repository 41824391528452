import React, {
    Component
} from 'react';
import {
    connect
} from "react-redux";
import swal from 'sweetalert';
import {
    Select
} from 'antd';
import {
    toast
} from 'react-toastify';
import Home from '../Home';
//import Toggle from 'react-toggle';
//import Select from 'react-select';
import {
    getUserDetails,
    updateUser
} from '../../actions';
import {
    IMAGE_URL
} from '../../config/configs';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
class EditUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoader: false,
            user_id: '',
            fullname: '',
            status: true,
            phone_number: '',
            changestatus: 'Select',
            profile_image: '',
            profile_image_url: '',
            whatsApp_phone: '',
            snapchat_id: '',
            usertype: '',
            email: '',
            errors: {}
        }
    }
    componentDidMount() {
        let permissions = this.props.permissionsList;
        if (permissions !== 'Admin') {
            this.props.history.push('/dashboard');
        }
        this.getDetails();
    }
    getDetails = () => {
        let id = this.props.match.params.id;
        if (id) {
            var body = {
                user_id: id
            }

            this.setState({ isLoader: true });
            this.props.getUserDetails(body, (response) => {
                let responsedata = response.data;
                this.setState({ isLoader: false });
                if (responsedata.status == 1) {
                    this.setState({
                        fullname: responsedata.data.fullname,
                        status: (responsedata.data.status == 'Active') ? 'true' : 'false',
                        phone_number: responsedata.data.phone_number,
                        whatsApp_phone: responsedata.data.whatsApp_phone,
                        snapchat_id: responsedata.data.snapchat_id,
                        usertype: responsedata.data.usertype,
                        profile_image_url: IMAGE_URL + responsedata.data.profile_image,
                        email: responsedata.data.email,
                        user_id: responsedata.data.id,
                    })
                } else {
                    toast.error(responsedata.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            });
        } else {
            return false;
        }
    }
    updateUser = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            let {
                user_id,
                fullname,
                phone_number,
                profile_image,
                whatsApp_phone,
                snapchat_id,
                usertype,
                status
            } = this.state;
            let data = {
                user_id,
                fullname,
                phone_number,
                profile_image,
                whatsApp_phone,
                snapchat_id,
                usertype,
                status
            };
            let body = data;
            this.setState({ isLoader: true });
            this.props.updateUser(body, (response) => {
                let responsedata = response.data;
                this.setState({ isLoader: false });
                if (responsedata.status == 1) {
                    swal({
                        title: responsedata.message,
                        icon: "success",
                    }).then((willDelete) => {
                        if (willDelete) {
                            this.props.history.push(`/users/${usertype.toLowerCase()}`);
                        }
                    });
                } else {
                    toast.error(responsedata.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            });
        } else {
            return false;
        }
    }
    validateForm = () => {
        let {
            fullname,
            phone_number,
            whatsApp_phone,
            errors
        } = this.state;
        let formIsValid = true;
        let phone_pattern = new RegExp(/^\d{9,13}$/);
        if (!fullname) {
            formIsValid = false
            errors['fullname'] = '* Name is required!';
        }
        if (phone_number) {
            if (!phone_pattern.test(phone_number)) {
                formIsValid = false;
                errors['phone_number'] = '* Enter a valid Phone number!';
            }
        }
        if (whatsApp_phone) {
            if (!phone_pattern.test(whatsApp_phone)) {
                formIsValid = false;
                errors['whatsApp_phone'] = '* Enter a valid Whatsapp number!';
            }
        }
        this.setState({
            errors
        });
        return formIsValid;
    }
    fileUploadSubmit = () => {
        let imagefile = document.querySelector('#file');
        this.getBase64(imagefile.files[0], (result) => {
            this.setState({
                'profile_image': result
            });
        });
        this.setState({
            'profile_image_url': URL.createObjectURL(imagefile.files[0])
        });
    }
    getBase64(file, callback) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, {
                    [event.target.name]: ""
                })
            });
        }
    }
    addDefaultSrc(ev) {
        ev.target.src = "/assets/images/no-image-user.png";
    }
    render() {
            const Option = Select.Option;
            let {
                fullname,
                status,
                phone_number,
                profile_image,
                whatsApp_phone,
                snapchat_id,
                profile_image_url,
                usertype,
                email,
                errors
            } = this.state;
            return (
            <Home>
            <div className="row">
                <div className="col-md-12">
                    <div className="page-header border-bottom">
                        <h3>{ usertype } Profile</h3>
                        <ul className="breadcrumb">
                        <li className="breadcrumb-item" onClick={() => this.props.history.push('/dashboard')}>Home</li>
                        <li className="breadcrumb-item" onClick={() => this.props.history.push(`/users/${usertype.toLowerCase()}`)}>{ usertype } Management </li>
                        <li className="breadcrumb-item active">{ usertype } Profile </li>
                        </ul>
                    </div>
                </div>
            </div>
            <form className='form-sample card transparent no-shadow'>
                <div className='card-body'>
                    <div className='white-bg box-shadow br-4 border-secondary'>
                        <div className='card-header border-bottom'>
                        <p className='mb-0 font-15'>Enter {usertype} Information</p>
                        </div>
                        <div className="card-body pt-30 light-grey">
                        <div className="row">
                            <div className="col-md-4">
                                <div className=" animated fadeIn mb-2 pl-40 pr-40">
                                    <div className="form-group">
                                    <div className="porfile-info">
                                        <div className="user-profile-img">
                                            <div className="inner-image">
                                                <img width='240' height='240' src={profile_image_url ? profile_image_url :  "/assets/images/no-image-icon-4.png"} alt="Profile image" onError={this.addDefaultSrc}/>
                                            </div>
                                            <div className="upload-file">
                                                <label>
                                                <input type="file" className="file-input form-control-file text-primary font-weight-bold" name='profile_image' id="file" accept="image/*" data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} />
                                                </label>
                                                <a> <span className='camera-image'><img src='/assets/images/camera.png' alt='Upload Image' width='36' height='31' /></span> </a>
                                            </div>
                                        </div>
                                        {/* 
                                        <h2>{fullname}</h2>
                                        <span>{email}</span> */}
                                        {/* <a href="">{phone_number}</a> */}
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className=' animated fadeIn mb-2'>
                                    <div className='form-material row user-wrapper'>
                                    <div className='form-group col-md-6'>
                                        <label className='col-form-label'>Name<span className="text-danger"> *</span></label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='fullname'
                                            placeholder='Enter Name'
                                            value={fullname}
                                            onChange={this.handleChange}
                                            />
                                        <span className="error-block"> {errors.fullname} </span>
                                    </div>
                                    <div className='form-group col-md-6 mb-4'>
                                        <label className='col-form-label'>Email<span className="text-danger"> *</span> :</label>
                                        <input
                                            className='form-control'
                                            readOnly
                                            type='text'
                                            name='email'
                                            placeholder='Enter Email'
                                            value={email}
                                            />
                                        <span className="error-block"> {errors.email} </span>
                                    </div>
                                    <div className='form-group col-md-6 mb-4'>
                                        <label className='col-form-label'>Phone Number<span className="text-danger"></span> :</label>
                                        <input
                                            className='form-control'
                                            type='number'
                                            name='phone_number'
                                            placeholder='Enter Phone number'
                                            value={phone_number}
                                            onChange={this.handleChange}
                                            />
                                        <span className="error-block"> {errors.phone_number} </span>
                                    </div>
                                    <div className='form-group col-md-6 mb-4'>
                                        <label className='col-form-label'>Whatsapp Number<span className="text-danger"></span> :</label>
                                        <input
                                            className='form-control'
                                            type='number'
                                            name='whatsApp_phone'
                                            placeholder='Enter Whatsapp number'
                                            value={whatsApp_phone}
                                            onChange={this.handleChange}
                                            />
                                        <span className="error-block"> {errors.whatsApp_phone} </span>
                                    </div>
                                    <div className='form-group col-md-6 mb-4'>
                                        <label className='col-form-label'>Snapchat Id<span className="text-danger"></span> :</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='snapchat_id'
                                            placeholder='Enter Snapchat ID'
                                            value={snapchat_id}
                                            onChange={this.handleChange}
                                            />
                                        <span className="error-block"> {errors.snapchat_id} </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        </div >
                    </div>
                </div >
                <div className='card-body row'>
                    <div className="col-md-5 d-flex align-items-center">
                        <label className="col-form-label">Status : </label>
                        <div className="active-toggle ml-1">
                        {/* 
                        <Toggle
                            checked={status}
                            className='custom-classname'
                            onChange={() =>
                        this.setState({ status: !status })}
                        /> */}
                        {/* 
                        <select placeholder="select" className="applyselect form-control" value={status} onChange={() =>
                            this.setState({ status: value })}>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                        */}
                        <Select
                            placeholder="select"
                            optionFilterProp="children"
                            className="applyselect"
                            value={status}
                            onSelect={(value) =>
                            this.setState({ status: value })}
                            >
                            <Option value='true'>Active</Option>
                            <Option value='false'>InActive</Option>
                        </Select>
                        </div>
                    </div>
                    <div className="col-md-7 button-continer text-right">
                        <button type='button' className='btn btn-outline-secondary btn-sm' onClick={() => this.props.history.push(`/users/${usertype.toLowerCase()}`)} > Cancel </button>
                        <button type='button' className='btn btn-primary btn-large' onClick={this.updateUser}> <span>Update</span> </button>
                    </div>
                </div>
            </form>
            {this.state.isLoader == true ? <div className='loader-block'>
                <img src="/assets/images/loader2.gif" />
            </div> : null }
            </Home>
        );
    }
}
const mapStateToProps = state => ({
permissionsList: state.admindata.admindata.rolepermissions,
})
export default connect(mapStateToProps, { getUserDetails, updateUser })(EditUser);