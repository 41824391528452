import React, { Component } from "react";
import { IMAGE_URL } from "../../config/configs";
// import 'react-dropzone-uploader/dist/styles.css';
// import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader';

//import {useDropzone} from 'react-dropzone';
// import FineUploaderTraditional from 'fine-uploader-wrappers';
// import Gallery from 'react-fine-uploader';
// import 'react-fine-uploader/gallery/gallery.css';

//import { API_URL } from "../../config/configs";
//import Home from '../Home';

class Step2 extends Component {
  constructor() {
    super();
  }

  isValidated = e => {
    let { images } = this.props;
    let errors = [];
    let formIsValid,
      maximages = false;

    let files = document.getElementById("file").files;

    let imagesLength = files.length + images.imagesPreviewUrlsServer.length;
    if (files && files.length > 0) {
      Array.prototype.forEach.call(files, file => {
        let types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
        if (types.every(type => file.type !== type)) {
          formIsValid = false;
          errors.push(
            "* " +
              file.type +
              " is not a supported format for " +
              file.name +
              ". Only JPEG, JPG, PNG and GIF is supported!"
          );
        }
        if (file.size > 5242880) {
          formIsValid = false;
          errors.push(
            "* " +
              file.name +
              " is too large! Maximum image upload size is 5 MB. Please select a smaller file!"
          );
        }
        if (imagesLength > 10) {
          formIsValid = false;
          maximages = true;
        }
      });
      if (maximages) {
        formIsValid = false;
        errors.push(
          "* You can not upload more then 10 images. Maximun 10 images are allowed!"
        );
      }
    }

    if (
      images.imagesPreviewUrls.length <= 0 &&
      images.imagesPreviewUrlsServer.length <= 0
    ) {
      formIsValid = false;
      errors.push("* Images is required!");
    }

    let stepErrors = this.props.images;
    stepErrors["errors"] = errors;
    this.setState({ images: stepErrors });
    return formIsValid;
  };

  addDefaultSrcForProperty(ev) {
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render() {
    const {
      images,
      category_name,
      handleChangeStep2,
      removeimages,
      setAsCover
    } = this.props;
    console.log(this.props, "images in step 2");
    return (
      <div className="card transparent no-shadow">
        <div className="card-body mb-4">
          <div className="white-bg border br-6">
            <div className="card-header border-bottom">
              <p className="mb-0 font-15">Upload Photos</p>
            </div>
            <div className="p-30 light-grey">
              <div className="inner-imagewrapper">
                {images.imagesPreviewUrls.length <= 0 &&
                images.imagesPreviewUrlsServer.length <= 0 ? (
                  <h3 className="placeholder-caption">
                    No files has been uploaded yet.
                  </h3>
                ) : null}

                {images.imagesPreviewUrlsServer.length > 0 && (
                  <div className="inner-image preview-image">
                    {images.imagesPreviewUrlsServer.map((previewimage, key) => (
                      <div key={key} className="image-wrapper">
                        <img
                          width="240"
                          height="240"
                          src={
                            previewimage.image
                              ? IMAGE_URL + previewimage.image
                              : "/assets/images/no-image-icon-4.png"
                          }
                          alt="Property image"
                          onError={this.addDefaultSrcForProperty}
                        />
                        <button
                          onClick={() => removeimages(key, previewimage.id)}
                          className="remove-btn btn"
                        >
                          <img
                            src="/assets/images/close.png"
                            alt="Remove"
                            width="25"
                            height="25"
                          />
                        </button>

                        <div className="cover-option">
                          <div className="custom-control custom-radio mb-10">
                            <input
                              type="radio"
                              id={"customRadioPreview" + previewimage.id}
                              checked={
                                previewimage.id == images.uploadedimgcoverkey
                              }
                              value={previewimage.id}
                              className="custom-control-input"
                              onChange={() => setAsCover(key, previewimage.id)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"customRadioPreview" + previewimage.id}
                            >
                              Set as Cover
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {images.imagesPreviewUrls.length > 0 && (
                  <div className="inner-image">
                    {images.imagesPreviewUrls.map((value, key) => (
                      <div key={key} className="image-wrapper">
                        <img
                          width="240"
                          height="240"
                          src={
                            value ? value : "/assets/images/no-image-icon-4.png"
                          }
                          alt="Property image"
                          onError={this.addDefaultSrcForProperty}
                        />
                        <button
                          onClick={() => removeimages(key)}
                          className="remove-btn btn"
                        >
                          <img
                            src="/assets/images/close.png"
                            alt="Remove"
                            width="25"
                            height="25"
                          />
                        </button>

                        <div className="cover-option">
                          <div className="custom-control custom-radio mb-10">
                            <input
                              type="radio"
                              id={"customRadio" + key}
                              checked={
                                images.coverImageKey == key &&
                                (images.uploadedimgcoverkey == "" ||
                                  images.uploadedimgcoverkey == undefined)
                              }
                              value={key}
                              className="custom-control-input"
                              onChange={() => setAsCover(key)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"customRadio" + key}
                            >
                              Set as Cover
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative-block">
                <div className="custom-uploadersection btn">
                  <div className="file-uploadblock">
                    <img
                      src="/assets/images/upload.svg"
                      alt="Upload a file"
                      width="40"
                      height="37"
                    />
                    <h3>
                      <span>Click here to upload Images</span>
                    </h3>
                    <p>
                      Please note that max 10 images can be uploaded and JPEG,
                      PNG image files are allowed.*
                    </p>
                  </div>
                </div>
                <div className="upload-file">
                  <label>
                    <input
                      type="file"
                      multiple
                      className="file-input form-control-file text-primary font-weight-bold"
                      name="profile_image"
                      id="file"
                      accept="image/*"
                      data-title="Drag and drop a file"
                      onChange={handleChangeStep2}
                    />
                  </label>
                </div>
                {images.errors.length > 0 && (
                  <div className="error-wrap">
                    {images.errors.map((error, key) => (
                      <div key={key}>
                        <span className="error-block" key={key}>
                          {" "}
                          {error}{" "}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div className="inner-image">
      //     <img width='240' height='240' src={profile_image_url ? profile_image_url : "/assets/images/no-image-icon-4.png"} alt="Profile image" onError={this.addDefaultSrc}/>
      // </div>
    );
  }

  // render() {
  //     const { images, handleChangeStep2 } = this.props;
  //     let thumbs;

  //     console.log(images, 'images');
  //     if(images.length > 0) {
  //         console.log(images, 'images if');
  //         //images.map(file => URL.revokeObjectURL(file.preview))
  //         // thumbs = images.map(file => (

  //         //     <div key={file.name}>
  //         //       <div >
  //         //         <img width='50' height='50'  src={file.meta.previewUrl} />
  //         //       </div>
  //         //     </div>
  //         // ))
  //     }
  //     //console.log(thumbs, 'thumbs');
  //     const Preview = ({ meta }) => {
  //         console.log(meta);
  //         const { name, percent, status } = meta
  //         return (
  //           <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
  //             {name}, {Math.round(percent)}%, {status}
  //           </span>
  //         )
  //     }

  //     // const thumbs = images.map(file => (
  //     //     <div  key={file.name}>
  //     //       <div >
  //     //         <img width='50' height='50'  src={file.preview} />
  //     //       </div>
  //     //     </div>
  //     // ))

  //     const CustomPreview = () => {
  //         const getUploadParams = () => ({
  //             //url: 'https://httpbin.org/post'
  //         })

  //         // const handleSubmit = (files, allFiles) => {
  //         //   console.log(files.map(f => f.meta))
  //         //   allFiles.forEach(f => f.remove())
  //         // }

  //         return (
  //           <Dropzone
  //             getUploadParams={getUploadParams}
  //             onChangeStatus={handleChangeStep2}
  //             PreviewComponent={Preview}
  //             inputContent="Drop Files (Custom Preview)"
  //             // disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
  //           />
  //         )
  //     }
  //     return (
  //         <CustomPreview />
  //     )

  // }

  // render() {
  //     const ImageAudioVideo = () => {
  //         const getUploadParams = ({ meta }) => {
  //           const url = 'https://httpbin.org/post'
  //           return { url, meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` } }
  //         }

  //         const handleChangeStatus = ({ meta }, status) => {
  //           console.log(status, meta)
  //         }

  //         const handleSubmit = (files, allFiles) => {
  //           console.log(files.map(f => f.meta))
  //           allFiles.forEach(f => f.remove())
  //         }

  //         return (
  //           <Dropzone
  //             getUploadParams={getUploadParams}
  //             onChangeStatus={handleChangeStatus}
  //             onSubmit={handleSubmit}
  //             accept="image/*"
  //             inputContent={(files, extra) => (extra.reject ? 'Image files only' : 'Drag Files')}
  //             styles={{
  //               dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
  //               inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
  //             }}
  //           />
  //         )
  //     }
  //     return (
  //         <ImageAudioVideo />
  //     )
  // }
  // render() {
  //     const { images, handleChangeStep2 } = this.props;
  //     console.log(images, 'images');
  //     const thumbsContainer = {
  //         display: 'flex',
  //         flexDirection: 'row',
  //         flexWrap: 'wrap',
  //         marginTop: 16
  //     };

  //     const thumb = {
  //         display: 'inline-flex',
  //         borderRadius: 2,
  //         border: '1px solid #eaeaea',
  //         marginBottom: 8,
  //         marginRight: 8,
  //         width: 100,
  //         height: 100,
  //         padding: 4,
  //         boxSizing: 'border-box'
  //     };

  //     const thumbInner = {
  //         display: 'flex',
  //         minWidth: 0,
  //         overflow: 'hidden'
  //     };

  //     const img = {
  //         display: 'block',
  //         width: 'auto',
  //         height: '100%'
  //     };

  //     const Previews = (props) => {
  //         const [files, setFiles] = useState([]);
  //         const {getRootProps, getInputProps} = useDropzone({
  //         accept: 'image/*',
  //         onDrop: acceptedFiles => {
  //             setFiles(acceptedFiles.map(file => Object.assign(file, {
  //             preview: URL.createObjectURL(file)
  //             })));
  //         }
  //         });

  //         const thumbs = files.map(file => (
  //         <div style={thumb} key={file.name}>
  //             <div style={thumbInner}>
  //             <img
  //                 src={file.preview}
  //                 style={img}
  //             />
  //             </div>
  //         </div>
  //         ));

  //         useEffect(() => () => {
  //         // Make sure to revoke the data uris to avoid memory leaks
  //         files.forEach(file => URL.revokeObjectURL(file.preview));
  //         }, [files]);

  //         return (
  //         <section className="container">
  //             <div {...getRootProps({className: 'dropzone'})}>
  //             <input {...getInputProps()} onChange={handleChangeStep2}/>
  //             <p>Drag 'n' drop some files here, or click to select files</p>
  //             </div>
  //             <aside style={thumbsContainer}>
  //             {thumbs}
  //             </aside>
  //         </section>
  //         );
  //     }
  //     return (
  //         <Previews />
  //     );
  // }
  // onDrop(files) {
  //     this.setState({
  //       files: files.map(file => Object.assign(file, {
  //         preview: URL.createObjectURL(file)
  //       }))
  //     })

  //     const uploaders = files.map(file => {
  //       const formData = new FormData()
  //       formData.append('file', file);

  //       return axios.post('https://httpbin.org/post', formData, {
  //         headers: { 'X-Requested-With': 'XMLHttpRequest' },
  //       })
  //     })

  //     axios.all(uploaders).then(() => {
  //       // remove files once they've all been uploaded
  //       this.setState({ files: [] })
  //     })
  // }

  // componentWillUnmount() {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
  // }
  // ​
  // render() {
  //     const {files} = this.state
  // ​
  //     const thumbs = files.map(file => (
  //       <div style={thumb} key={file.name}>
  //         <div style={thumbInner}>
  //           <img
  //             src={file.preview}
  //             style={img}
  //           />
  //         </div>
  //       </div>
  //     ))
  // ​
  //     return (
  //       <section>
  //         <Dropzone
  //           onDrop={this.onDrop.bind(this)}
  //           accept="image/*"
  //         >
  //           {({getRootProps, getInputProps}) => (
  //             <div {...getRootProps()}>
  //               <input {...getInputProps()} />
  //               <p>Drop files here</p>
  //             </div>
  //           )}
  //         </Dropzone>
  //         <aside style={thumbsContainer}>
  //           {thumbs}
  //         </aside>
  //       </section>
  //     )
  // }

  // render(){
  //     <Previews />
  //     return (
  //         <div className='card transparent no-shadow'>
  //             <div className='card-body mb-4'>
  //                 <div className='white-bg box-shadow br-6'>
  //                     <div className='card-header border-bottom'>
  //                         <p className="mb-0 font-15">Upload Photos</p>
  //                     </div>
  //                     <div className='p-30 light-grey'>
  //                     <div className='relative-block'>
  //                         <div className="custom-uploadersection btn">
  //                             <div className='file-uploadblock'>
  //                                 <img src='/assets/images/upload.svg' alt='Upload a file' width='40' height='37' />
  //                                 <h3>Drop files here or <span>Click here</span></h3>
  //                                 <p>Please note that max 10 images can be uploaded and JPEG, PNG image files are allowed.*</p>
  //                             </div>
  //                         </div>
  //                         <Gallery uploader={ uploader }/>
  //                     </div>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //     )
  // }
}

export default Step2;
