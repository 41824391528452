import axios from "axios";
import { API_URL } from "../config/configs";
import { LOG_OUT } from "./types";
import swal from 'sweetalert';

// ################################# CHANGE STATUS ###############################
export const Logout = () => async  dispatch => {
  localStorage.removeItem('token', '')
  dispatch({ type: LOG_OUT, payload: " " });
};

export const changeStatus = (body, token, urlkey, callback) => async dispatch => {
  try {
    let response = await axios({
      method: 'post',
      url: API_URL + "/" + urlkey,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      data: JSON.stringify(body)
    });
    let { data } = response;
    callback(response);
  } catch (error) {
    console.log('Error: ', error);
  }
};

export const commonApiCall = (url, method, body, token, TYPE, props, callback) => async  dispatch => {
  /************************ METHOD = "POST" *************************************/
  try {
    
    let response;
    if (method == 'post') {
      response = await axios({
        method: 'post',
        url: API_URL + url,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        data: JSON.stringify(body),
      });
    } else if(method == 'formdata') {
      response = await axios({
        method: 'post',
        url: API_URL + url,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        },
        data: body,
      });
    } else {
      response = await axios({
        method: 'get',
        url: API_URL + url,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      });
    }

    let responsedata = response.data;
    if (responsedata.code === 401) {
      swal(responsedata.message, '', 'error').then((willDelete) => {
        if (willDelete) {
          localStorage.setItem('token', null);
          props.history.push('/');
        }
      });
    } else {
      let payload = {
        data: responsedata.data
      };
      dispatch({ type: TYPE, payload });
      callback(response);
    }
  } catch (error) {
    console.log('Error: ', error);
    // localStorage.clear();
    // props.history.push('/');
  }
}
