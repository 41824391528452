import React, { Component } from 'react';
import Home from '../Home';
import { commonApiCall } from '../../actions';
import { connect } from "react-redux";
import { IMAGE_URL } from '../../config/configs';
import { Select } from 'antd';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
class categoryAddedit extends Component {
      constructor(props) {
            super(props)
            this.validator = new SimpleReactValidator();
            this.state = {
                  isLoader: false,
                  category_id: '',
                  usertype: 'Owner',
                  name: '',
                  description: '',
                  color_code: '',
                  profile_image_url: "/assets/images/no-image-icon-4.png",
                  status: '',
                  changestatus: 'Select',
                  errors: {}
            }
      }
      componentDidMount() {
            let permissions = this.props.permissionsList;
            if (permissions !== 'Admin') {
                  this.props.history.push('/dashboard');
            }
            this.getOwnerDetails();
      }
      getOwnerDetails = () => {
            let id = this.props.match.params.id;
            this.setState({ category_id: id });
            if (id) {
                  var body = { category_id: id };
                  let token = localStorage.getItem('token');
                  var url = 'admin/categoryDetails';
                  var method = 'post';
                  this.setState({ isLoader: true });
                  this.props.commonApiCall(url, method, body, token, null, this.props, (response) => {
                        let responsedata = response.data;
                        this.setState({ isLoader: false });
                        console.log(responsedata)
                        console.log(responsedata.data[0].description)
                        if (responsedata.status == 1) {
                              let ownerdata = responsedata.data[0];
                              this.setState({
                                    category_id: ownerdata.id,
                                    name: ownerdata.name,
                                    
                                    // description: ownerdata.description,
                                    
                                    // color_code: ownerdata.color_code,
                                   // profile_image_url: IMAGE_URL + ownerdata.profile_image,
                                    status: (ownerdata.status == 'Active') ? 'true' : 'false',
                              });
                        } else {
                              toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                        }
                  });
            }
      }


      saveOwnerUser = (e) => {
            let { category_id } = this.state;
            if (this.validateForm(category_id)) {
                  let {  name, description, color_code,   status } = this.state;
                  let data = {  name, description, color_code,  status };
                  let body = data;
                  let url = 'admin/addedCategory';
                  let method = 'post';
                  this.setState({ isLoader: true });
                  let token = localStorage.getItem('token');
                  this.props.commonApiCall(url, method, body, token, null, this.props, response => {
                        let responsedata = response.data;
                        this.setState({ isLoader: false });
                        if (responsedata.status == 1) {
                              swal({
                                    title: responsedata.message,
                                    icon: "success",
                              }).then((willDelete) => {
                                    if (willDelete) {
                                          this.props.history.push(`/category`);
                                    }
                              });
                        } else {
                              toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                        }
                  });
            } else {
                  return false;
            }
      }


      upsertOwnerUser = (e) => {
            let { category_id } = this.state;
            if (this.validateForm(category_id)) {
                  let { category_id, name, description, color_code,   status } = this.state;
                  let data = { category_id, name, description, color_code,  status };
                  let body = data;
                  let url = 'admin/updateCategory';
                  let method = 'post';
                  this.setState({ isLoader: true });
                  let token = localStorage.getItem('token');
                  this.props.commonApiCall(url, method, body, token, null, this.props, response => {
                        let responsedata = response.data;
                        this.setState({ isLoader: false });
                        if (responsedata.status == 1) {
                              swal({
                                    title: responsedata.message,
                                    icon: "success",
                              }).then((willDelete) => {
                                    if (willDelete) {
                                          this.props.history.push(`/category`);
                                    }
                              });
                        } else {
                              toast.error(responsedata.message, { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
                        }
                  });
            } else {
                  return false;
            }
      }
      handleChange = (event) => {
            this.setState({ [event.target.name]: event.target.value })
            if (event.target.value) {
                  this.setState({
                        errors: Object.assign(this.state.errors, { [event.target.name]: "" })
                  });
            }
      }
      validateForm = (id) => {
            let {  name, description, color_code,  errors } = this.state;
            let formIsValid = true;
            
           
            if (!name) {
                  formIsValid = false
                  errors['name'] = '* Name is required!';
            }
            // if (!description) {
            //       formIsValid = false
            //       errors['description'] = '* Description is required!';
            // }
            // if (!color_code) {
            //       formIsValid = false
            //       errors['color_code'] = '* Color code is required!';
            // }
           
           
            this.setState({ errors });
            return formIsValid;
      }
      fileUploadSubmit = (e) => {
            var imagefile = document.querySelector('#file');
            this.getBase64(imagefile.files[0], (result) => {
                  this.setState({ profile_image: result });
            });
            this.setState({ 'profile_image_url': URL.createObjectURL(imagefile.files[0]) });
      }
      getBase64(file, callback) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                  callback(reader.result)
            };
            reader.onerror = function (error) {
                  console.log('Error: ', error);
            };
      }
      addDefaultSrc(ev) {
            ev.target.src = "/assets/images/no-image-user.png";
      }
      render() {
            const Option = Select.Option;
            let { errors, category_id, name, description, color_code,  status } = this.state;
            return (
            <Home>
            <div className='row'>
                  <div className='col-md-12'>
                  <div className='page-header border-bottom'>
                        <h3>{category_id ? 'Edit Category':'Add New Category'}</h3>
                        <ul className='breadcrumb '>
                        <li className='breadcrumb-item' onClick={() => this.props.history.push('/dashboard')}> Home</li>
                        <li className='breadcrumb-item' onClick={() => this.props.history.push('/category')}> Category </li>
                        <li className='breadcrumb-item active'>{category_id ? 'Edit Category':'Add New Category'}</li>
                        </ul>
                  </div>
                  </div>
            </div>
            <form className='form-sample card transparent no-shadow'>
                  <div className='card-body'>
                  <div className='white-bg box-shadow br-4 border-secondary'>
                        <div className='card-header border-bottom'>
                        <p className='mb-0 font-15'>Enter Category Data</p>
                        </div>
                        <div className='card-body pt-30 light-grey'>
                        <div className="row">
                              {/* <div className="col-md-4">
                              <div className='animated fadeIn mb-2 pl-40 pr-40'>
                                    <div className='form-group'>
                                    <div className='porfile-info'>
                                          <div className="drop-section user-profile-img">
                                          <div className="select-image inner-image">
                                                <div className="product-img"><img src={profile_image_url ? profile_image_url : "/assets/images/no-image-icon-4.png"} alt="Profile image" onError={this.addDefaultSrc}/></div>
                                          </div>
                                          <div className="upload-overlay d-flex justify-content-center align-items-center">
                                                <div className="upload-wrapper upload-file">
                                                <span className='camera-image'><img src='/assets/images/camera.png' alt='Upload Image' width='36' height='31' /></span>
                                                </div>
                                          </div>
                                          <label className="sr-only" htmlFor="inputFile">File Upload</label>
                                          <input type="file" className="form-control-file text-primary font-weight-bold" name='image' id="file" accept="image/x-png,image/gif,image/jpeg,image/jpg" multiple
                                                data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} />
                                          <span className="error-block"> {errors.profile_image} </span>
                                          </div>
                                    </div>
                                    </div>
                              </div>
                              </div> */}
                              <div className="col-md-8">
                              <div className=' animated fadeIn mb-2'>
                                    <div className='form-material row user-wrapper'>
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Category Name<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='text'
                                          name='name'
                                          placeholder='Enter Category Name'
                                          value={name}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.name} </span>
                                    </div>
                                    {/* <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Category Description<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='text'
                                          name='description'
                                          placeholder='Enter Category Description'
                                          value={description}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.description} </span>
                                    </div>
                                    <div className='form-group col-md-6'>
                                          <label className='col-form-label'>Color Code<span className="text-danger">*</span> :</label>
                                          <input
                                          className='form-control'
                                          type='text'
                                          name='color_code'
                                         
                                          placeholder='Enter Color Code'
                                          value={color_code}
                                          onChange={this.handleChange}
                                          />
                                          <span className="error-block"> {errors.color_code} </span>
                                    </div> */}
                                   
                                   

                                    </div>
                              </div>
                              </div>
                        </div>
                        </div>
                  </div>
                  </div>
            </form>
            <div className="card-body row">
                 
                  {/* {category_id ?
                  <div className='col-md-5 d-flex align-items-center'>
                  <label className='col-form-label' htmlFor='material-switch'>
                  Status <span className="text-danger">*</span> :
                  </label>
                  <div className='active-toggle ml-1'>
                       
                        <Select
                        placeholder="select"
                        optionFilterProp="children"
                        className="applyselect"
                        value={status}
                        onSelect={(value) =>
                        this.setState({ status: value })}
                        >
                              <Option value='true'>Active</Option>
                              <Option value='false'>InActive</Option>
                        </Select>
                  </div>
                  </div>
                  : null } */}

                  <div className="col-md-7 button-continer text-right ml-auto">
                  <button type='button' className='btn btn-outline-secondary btn-sm' onClick={() => this.props.history.push(`/category`)} > Cancel </button>
               {category_id ?
                  <button type='button' className='btn btn-primary btn-large' onClick={this.upsertOwnerUser}><span> Update </span></button>
                  :
                  <button type='button' className='btn btn-primary btn-large' onClick={this.saveOwnerUser}><span> Save </span></button>
               }
                  </div>
            </div>
            {this.state.isLoader == true ? <div className='loader-block'>
                  <img src="/assets/images/loader2.gif" />
            </div> : null }
            {/* 
            <div className='card animated fadeIn mb-2'>
                  <div className="card-header" data-spy="affix" data-offset-top="100">
                  <div className="button-continer text-right">
                        <button type='button' className='btn btn-primary' onClick={this.upsertOwnerUser}><span>{user_id ? ' Update ':' Save '}</span></button>
                        <button type='button' className='btn btn-outline-primary' onClick={() => this.props.history.push(`/owners`)} > Cancel </button>
                  </div>
                  </div>
                  <div className='card-body'>
                  <form className='form-sample'>
                        <div className='form-group row'>
                        <label className='col-sm-3 col-lg-2 col-sm-3 col-form-label'>Profile Image :</label>
                        <div className="drop-section mb-3 col-md-4">
                              <div className="form-group inputDnD mb-0">
                              <div className="upload-overlay d-flex justify-content-center align-items-center">
                                    <div className="upload-wrapper">
                                    <i className="fa fa-upload"></i>
                                    <span><button type="button"  >Choose a file</button>or drag it here</span>
                                    </div>
                              </div>
                              <label className="sr-only" htmlFor="inputFile">File Upload</label>
                              <input type="file" className="form-control-file text-primary font-weight-bold" name='image' id="file" accept="image/x-png,image/gif,image/jpeg,image/jpg" multiple
                                    data-title="Drag and drop a file" onChange={this.fileUploadSubmit.bind(this)} />
                              </div>
                              <span className="error-block"> {errors.profile_image} </span>
                        </div>
                        <div className="col-md-4">
                              <div className="row justify-content-between my-2">
                              <div className="col-md-12 select-image">
                                    <div className="product-img"><img src={profile_image_url} onError={this.addDefaultSrc}/></div>
                              </div>
                              </div>
                        </div>
                        </div>
                  </form>
                  </div>
            </div>
            */}
            </Home>
            );
      }
}
const mapStateToProps = state => ({
permissionsList:state.admindata.admindata.rolepermissions,
});
export default connect( mapStateToProps, { commonApiCall } )(categoryAddedit)