import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';

class AdminLogin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      rememberme: false,
      loggedin: false,
      errors: {}
    }

    //this.adminLogin = this.adminLogin.bind(this)
    // this.showForgotPassword = this.showForgotPassword.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount () {
    let loggedin = await localStorage.getItem('rememberme')
    if (loggedin === true) {
      this.props.history('/dashboard`')
    }
    let email = await localStorage.getItem('email')
    let password = await localStorage.getItem('password')
    let rememberme = await localStorage.getItem('rememberme')

    if (email && password) {
      this.setState({ email, password, rememberme })
    }
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
    if (event.target.value) {
      this.setState({
      errors:  Object.assign(this.state.errors, { [event.target.name]:  "" })
      });
    }
  }

  adminLogin=(e) => {
    e.preventDefault();
    var self = this;
    let { email, password } = this.state;
   // alert(this.validateForm())
    if(this.validateForm()) {
      // if (email.trim() === '' || password.trim() === '') {
      //   swal('Please provide complete information.', '', 'error')
      //   return false
      // }
      
      let data = { email, password };
      this.props.adminLogin(data, (response) => {
        let responsedata = response.data;
        if (responsedata.status == 1) {
          let permissions = this.props.permissionsList;
          let category_slug = this.props.admindata.data.categoryList[0].category_slug;
          if (permissions == "Admin") {
            self.props.history.push("/dashboard");
          } else {
            self.props.history.push("/properties/"+category_slug);
          }
        } else {
          toast.error(responsedata.message,  { position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true });
        }
      })
    } else {
      return false;
    }
  }

  validateForm () {
    let { email, password } = this.state
    let errors = {}
    let formIsValid = true
    if (!email) {
      formIsValid = false
      errors['email'] = 'Enter your email!'
    } else if (typeof email !== 'undefined') {
      var pattern = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      if (!pattern.test(email)) {
        formIsValid = false
        errors['email'] = 'Enter a valid email'
      }
    } else {
      formIsValid = true
    }

    if (!password) {
      formIsValid = false
      errors['password'] = 'Enter your password'
    }
    else {
      formIsValid = true
    }
    this.setState({ errors: errors })
    return formIsValid
  }

  // ################################ Remember Me ###########################
    rememberMe () {
      var {email,password,rememberme}=this.state
    if (rememberme == false) {
      localStorage.setItem('email', email)
      localStorage.setItem('password', password)
      localStorage.setItem('rememberme', !rememberme)
      this.setState({ rememberme: !rememberme })
    } else {
      localStorage.removeItem('email')
      localStorage.removeItem('password')
      localStorage.removeItem('rememberme')
      this.setState({
        rememberme: !rememberme,
        email: email,
        password:password
      })
    }
  }

  render () {
    let { email, password, errors } = this.state
    return (
      <div className='container-scroller'>
      {/* <ToastContainer /> */}

        <div className='page-body-wrapper full-page-wrapper'>
          <div className='content-wrapper d-flex align-items-center auth login-full-bg'>
            <div className='row w-100 margin-l-0'>
              <div className='col-lg-6 col-sm-8 mx-auto authentication-form color-white'>
                <div className='auth-form-light'>
                <div className='login-header mb-5'>
                  <img src='/assets/images/logo.svg' alt='logo' width='18' height='22' className='logo' />
                  {/* <h1 className='logo-text'>Super Admin</h1> */}
                  <h2>Welcome</h2>
                  {/* <p>Per elit bibbendum ridiculus hendreit proin id senectus vestibulum consectetur sit ad mus</p> */}
                </div>
         
                  <form autoComplete='off' onSubmit={this.adminLogin} className='authenticate-block'>
                    <h5 className='text-center mb-20 subheading'>
                      Sign in to your account
                    </h5>
                    <div className='form-group mb-10'>
                      <input type='text' className={ errors.email ? 'form-control input-error' : 'form-control' } name='email' value={email} id='email' placeholder='Username' onChange={this.handleChange} />
                      <span className='error-msg'>{errors.email}</span>
                    </div>
                    <div className='form-group password-block'>
                      <input className={ errors.password ? 'form-control input-error' : 'form-control' } type='password' name='password' value={password} id='password' placeholder='Password' onChange={this.handleChange} />
                      <span className='show-password'></span>
                      
                      <span className='error-msg'>{errors.password}</span>

                      {/* <input className="form-control" type="password" name="password" value={password} placeholder="Enter Password" id="password" required onChange={this.handleChange} /> */}
                    </div>
                    <div className='mt-0 text-center'>
                      <button
                        className='btn btn-primary font-weight-medium btn-md'
                        type='submit' title='Sign in'
                      >
                        Sign in
                      </button>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='mt-4 text-center'>
                        <Link  to="/forgotpassword" className='auth-link text-gray' >Forgot your password?</Link>
                          {/* <a
                            className='auth-link text-gray'
                            onClick={() =>
                              this.props.history.push()
                            }
                          >
                            Forgot password?
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </form>
         
                <div className='login-header color-white mt-5 font-weight-light font-16'>
                  {/*<img src='/assets/images/logo.svg' alt='logo' />*/}
                  New to SeaTime? <Link  to="/signup" className='auth-link'> Sign Up</Link>
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
        {/* page-body-wrapper ends */}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  admindata: state.admindata.admindata,
  permissionsList: state.admindata.admindata.rolepermissions,
});
export default connect(mapStateToProps, actions)(withRouter(AdminLogin));
