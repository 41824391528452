import React, { Component } from 'react'
import * as actions from '../../actions'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import Home from '../Home'
import axios from 'axios'
import swal from 'sweetalert'
import { API_URL,IMAGE_URL } from '../../config/configs'
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;



class DefaultMeta extends Component {
    constructor(props){
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
          isLoader: false,
          google_map_key:'',
          smtp_username:'',
          smtp_password:'',
          smtp_host:'',
          smtp_port:'',
          smtp_ssl_port:'',
//          imagePreviewUrl: '',
//          fileVisible:false,
//          fileVisible1:false,
//          fileVisible2:false,
//          fileVisible3:false,
//          filename:'',
//          file:'',
          errors:{
            google_map_key:'',
            smtp_username:'',
            smtp_password:'',
            smtp_host:'',
            smtp_port:'',
            smtp_ssl_port:''
          }
        }
    }
    
    handleChange = event => {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.value) {
        this.setState({
            errors:  Object.assign(this.state.errors, { [event.target.name]:  "" })
        });
      }
    }

    validateForm = () => {
      let {google_map_key,smtp_username,smtp_password,smtp_host,smtp_port,smtp_ssl_port, errors } = this.state
      let formIsValid = true
      if (!google_map_key) {
        formIsValid = false
        errors['google_map_key'] = '* Google Map Key is required'
      }
      if (!smtp_username) {
        formIsValid = false
        errors['smtp_username'] = '* Smtp Password is required'
      }
      if (!smtp_password) {
        formIsValid = false
        errors['smtp_password'] = '* Smtp Username is required'
      }
      if (!smtp_host) {
        formIsValid = false
        errors['smtp_host'] = '* Smtp Host is required'
      }
      if (!smtp_port) {
        formIsValid = false
        errors['smtp_port'] = '* Smtp Port is required'
      }
      if (!smtp_ssl_port) {
        formIsValid = false
        errors['smtp_ssl_port'] = '* Smtp SSL Port is required'
      }
      else formIsValid = true
      this.setState({ errors })
      return formIsValid
    }

    componentDidMount(){
      let permissions = this.props.permissionsList;
      if (permissions !== "Admin") {
          this.props.history.push("/dashboard");
      }

      $('#file').bind('change', function () {
        var filename = $("#file").val();
        if (/^\s*$/.test(filename)) {
          $(".drop-section").removeClass('active');

        }
        else {
          $(".drop-section").addClass('active');
          $(".drop-section").text(filename.replace("C:\\fakepath\\", "")); 
        }
      });

      this.getSettingDetails();
    }

    // ########################### Update SettingDetails #########################
    addSettingDetails = e => {
        let {google_map_key,smtp_username,smtp_password,smtp_host,smtp_port,smtp_ssl_port}=this.state
        e.preventDefault();
        if (this.validateForm()) {
            var url =  'admin/updatesettings'
              var method = 'post'
              var data={'google_map_key':google_map_key, 'smtp_username':smtp_username,'smtp_password':smtp_password,'smtp_host':smtp_host,'smtp_port':smtp_port,'smtp_ssl_port':smtp_ssl_port}
              var body = data
              var token = localStorage.getItem('token')
              this.setState({ isLoader: true });
              this.props.commonApiCall( url, method, body, token, null, this.props, response => {
                let { data } = response;
                this.setState({ isLoader: false });
                if(data){
                  swal('Updated Successfully', '', 'success')
                }
              }
            )
        } 
    }

    // ########################### Get SettingDetails #########################
    getSettingDetails = e => {
      var token = localStorage.getItem('token');

      this.setState({ isLoader: true });
      axios({
        method: 'post',
        url: API_URL+'admin/getsettings',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }).then(response => {
        this.setState({ isLoader: false });
        if (response.data.status == "1") {
            let responsedata = response.data.data[0];
            this.setState({
              google_map_key:responsedata.google_map_key,
              smtp_username:responsedata.smtp_username,
              smtp_password:responsedata.smtp_password,
              smtp_host:responsedata.smtp_host,
              smtp_port:responsedata.smtp_port,
              smtp_ssl_port:responsedata.smtp_ssl_port
            });
        } else {
            swal({
                title: response.data.message,
                icon: "error",
            })
            .then((willDelete) => {
            if (willDelete) {
               this.props.history.push('/login');
            }})
        }
      })
    } 

  render () {
    let {google_map_key,smtp_username,smtp_password,smtp_host,smtp_port,smtp_ssl_port,errors}=this.state
   // console.log(this.state.smtpSslPort);
    
    return (
      <Home>
        <div className='row'>
          <div className='col-md-12'>
            <div className='page-header border-bottom'>
              <h3>Global Settings</h3>
              <ul className='breadcrumb '>
                <li className='breadcrumb-item' onClick={()=>this.props.history.push('/dashboard')}>Home</li>
                <li className='breadcrumb-item'>Settings</li>
                <li className='breadcrumb-item active'>Global Settings</li>
              </ul>
            </div>
          </div>
        </div>

        <form className='form-material card transparent no-shadow'>
          <div className='card-body'>
            <div className='white-bg box-shadow br-4 border-secondary'>
              <div className='card-header border-bottom'>
                <p className="mb-0 font-15">General Settings</p>
              </div>
              <div className='card-body pt-30 light-grey'>
                <div className='row user-wrapper setting-block'>
                  <div className='form-group col-md-4'>
                      <label className='col-form-label'> Google Map Key</label>
                      <input className='form-control' type='text' name='google_map_key' placeholder='Enter Google Map Key' value={google_map_key} onChange={this.handleChange} />
                      <span className="error-block"> {errors.google_map_key} </span> 
                  </div>
                  <div className='form-group col-md-4'>
                    <label className='col-form-label'> Smtp Username </label>
                    <input className='form-control' type='text' name='smtp_username' placeholder='Enter Smtp Username' value={smtp_username} onChange={this.handleChange} />
                    <span className="error-block"> {errors.smtp_username} </span> 
                  </div>
                  
                  <div className='form-group col-md-4'>
                    <label className='col-form-label'> Smtp Password </label>
                    <input className='form-control' type='text' name='smtp_password' placeholder='Enter Smtp Password' value={smtp_password} onChange={this.handleChange} />
                    <span className="error-block"> {errors.smtp_password} </span> 
                  </div>
                  
                  <div className='form-group col-md-4'>
                    <label className='col-form-label'> Smtp Host </label>
                    <input className='form-control' type='text' name='smtp_host' placeholder='Enter Smtp Host' value={smtp_host} onChange={this.handleChange} />
                    <span className="error-block"> {errors.smtp_host} </span> 
                  </div>
                  
                  <div className='form-group col-md-4'>
                    <label className='col-form-label'> Smtp Port </label>
                    <input className='form-control' type='text' name='smtp_port' placeholder='Enter Smtp Port' value={smtp_port} onChange={this.handleChange} />
                    <span className="error-block"> {errors.smtp_port} </span> 
                  </div>
                  
                  <div className='form-group col-md-4'>
                    <label className='col-form-label'> Smtp SSL Port </label>
                    <input className='form-control' type='text' name='smtp_ssl_port' placeholder='Enter Smtp SSL Port' value={smtp_ssl_port} onChange={this.handleChange} />
                    <span className="error-block"> {errors.smtp_ssl_port} </span> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body button-continer text-right">
            <button type='button' className='btn btn-primary btn-large' onClick={this.addSettingDetails} > Save </button>
          </div>
          
        </form>
        {this.state.isLoader == true ? <div className='loader-block'>
          <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
    )
  }
}
const mapStateToProps = state => ({
  permissionsList: state.admindata.admindata.rolepermissions
});
// export default DefaultMeta
export default connect( mapStateToProps, actions )(DefaultMeta)

