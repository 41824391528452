import React, { Component } from 'react';
import Home from '../Home'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import queryString from 'query-string'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import SimpleReactValidator from 'simple-react-validator'
import swal from 'sweetalert'



class EditEmail extends Component {
  constructor(props){
    super(props)
    this.validator = new SimpleReactValidator();
    this.state = { 
      isLoader: false,
      email_content:'',
      email_subject:'',
      metaTitle:'',
      metaKeyword:'',
      template_title:'',
      cmsId:'',
      errors:{
        description:'',
        metaDescription:'',
        metaTitle:'',
        metaKeyword:'',
        pageName:'',
      }
     }
  }
    
  handleChange= (event) =>{
    this.setState({ [event.target.name]: event.target.value })
    if (event.target.value) {
      this.setState({
      errors:  Object.assign(this.state.errors, { [event.target.name]:  "" })
      });
    }
  }
  validateForm = () => {
    let { email_content,email_subject,metaTitle,metaKeyword,template_title,errors } = this.state
      
    let formIsValid = true
  
    if (!template_title) {
      formIsValid = false
      errors['template_title'] = '* Email title is required'
    }
    if (!email_content) {
      formIsValid = false
      errors['email_content'] = '* Email content is required'
    }
    
    if (!email_subject) {
      formIsValid = false
      errors['email_subject'] = '* Email subject is required'
    }
    //else formIsValid = true
    this.setState({ errors })
    return formIsValid
  }


  componentDidMount(){
    let permissions = this.props.permissionsList;
    if (permissions !== "Admin") {
        this.props.history.push("/dashboard");
    }
  
    var url =  this.props.location.search;
    var params = queryString.parse(url);
    var id = 
    this.props.match.params.id
    this.setState({email_template_id:id})
  
    if(id) {
      var body = { email_template_id: id };
      let token = localStorage.getItem('token');
      var url = '/admin/getemailtemplatedetail';
      var method = 'post';
      this.setState({ isLoader: true });
      this.props.commonApiCall( url, method, body, token, null, this.props, response => {
          let  {data}  = response.data;
          this.setState({ isLoader: false });
          if(data){
            this.setState({
              email_content:data[0].email_content,
              email_subject:data[0].email_subject,
              // metaTitle:data.data.metaTitle,
              // metaKeyword:data.data.metaKeyword,
              template_title:data[0].template_title
            })
          }
          
      })
  }
  }

  updateEmailContent=()=>{
    if (this.validateForm()) {
      
    var { email_content,email_subject,template_title,email_template_id}=this.state
      var body = {email_content,email_subject,template_title,email_template_id}
      let token = localStorage.getItem('token')
      var url = '/admin/updatetemailtemplate'
      var method = 'post'
      this.setState({ isLoader: true });
      this.props.commonApiCall( url, method, body, token, null, this.props, response => { 
        let {data}=response;
        this.setState({ isLoader: false });
        if(data.status == 1){
          swal({
              title: "Updated Successfully !",
              icon: "success",
              })
              .then((willDelete) => {
              if (willDelete) {
              this.props.history.push('/emailcontentlist')
              }})
          }
        
      })
    }
  }

    render() { 
      var { email_content,email_subject,template_title,errors}=this.state
        return ( 
      <Home>
      <div className='row'>
          <div className='col-md-12'>
            <div className='page-header border-bottom'>
              <h3>Edit Email Content</h3>
              <ul className='breadcrumb '>
                <li className='breadcrumb-item' onClick={() => this.props.history.push('/dashboard')}>Home</li>
                <li className="breadcrumb-item" >Content Management</li>
                <li className='breadcrumb-item' onClick={() => this.props.history.push('/emailcontentlist')}>Email Contetnt Management </li>
                <li className='breadcrumb-item active'>Edit Email Content Page </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="card animated fadeIn mb-2">
          <div className="card-header">
          <h5 className='card-title'>Meta Data</h5>
            </div>
            <div className='card-body'>
            <form className='form-sample'>
              <div className='form-group row'>
                <label className='col-lg-2 col-sm-3 col-form-label'> Meta Title <span className="text-danger">*</span> :</label>
                <div className='col-md-8 co-sm-7'>
                  <input
                    className='form-control'
                    type='text'
                    name='metaTitle'
                    placeholder='Enter Meta Title'
                    value={metaTitle}
                    onChange={this.handleChange}
                  />
                  <span className="notes">Maximum 70 Character is suitable</span>
                  <span className="error-block"> {errors.metaTitle} </span>

                </div>
              </div>
              <div className='form-group row'>
                <label className='col-lg-2 col-sm-3 col-form-label'>
                  Meta Description <span className="text-danger">*</span> :
                </label>
                <div className='col-md-8 co-sm-7'>
                  <textarea
                    className='form-control'
                    rows="10" cols="50"
                    type='text'
                    name='metaDescription'
                    placeholder='Enter Meta Description'
                    value={metaDescription}
                    onChange={this.handleChange}
                  />
                  <span className="notes">Maximum 10 words is suitable</span>
                  <span className="error-block"> {errors.metaDescription} </span>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-lg-2 col-sm-3 col-form-label'>Meta Keyword <span className="text-danger">*</span> :</label>
                <div className='col-md-8 co-sm-7'>
                  <textarea
                    className='form-control'
                    type='text'
                    name='metaKeyword'
                    placeholder='Enter Meta Keyword'
                    value={metaKeyword}
                    onChange={this.handleChange}
                  />
                  <span className="notes">Maximum 156 Character is suitable</span>
                  <span className="error-block"> {errors.metaKeyword} </span>

                </div>
              </div>
            </form>
          </div>
        </div> */}

        <form className='form-material card transparent no-shadow form-sample'>
          <div className='card-body'>
              <div className='white-bg box-shadow br-4 border-secondary'>
                <div className='card-header'>
                  <p className='mb-0 font-15'>Email Content Detail</p>
                </div>
                <div className='card-body pt-30 light-grey'>
                  <div className='row'>
                  <div className='form-group col-md-4'>
                      <label className='col-form-label'> Email Title <span className="text-danger">*</span></label>
                   
                        <input
                          className='form-control'
                          type='text'
                          name='template_title'
                          placeholder='Enter Title Name'
                          value="Plan intelligently"
                          value={template_title}
                          onChange={this.handleChange}
                        />
                        <span className="error-block"> {errors.template_title} </span>
                     
                    </div>
                    <div className='form-group col-md-4'>
                      <label className='col-form-label'> Email Subject <span className="text-danger">*</span></label>
                 
                        <input
                          className='form-control'
                          type='text'
                          name='email_subject'
                          placeholder='Enter subject Name'
                          value="Plan intelligently"
                          value={email_subject}
                          onChange={this.handleChange}
                        />
                        <span className="error-block"> {errors.email_subject} </span>
                    
                    </div>

                    <div className='form-group col-md-12'>
                          <label className='col-form-label'> Email Content <span className="text-danger">*</span></label>
                          
                          <CKEditor
                              className='ck-editor__editable form-control'
                              columns={40}
                              editor={ClassicEditor}
                              data={email_content}
                              onInit={editor => {
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                this.setState({email_content:data})
                              }}
                          /> 
                      
                          <span className="error-block"> {errors.email_content} </span>

                      </div>
                  
                        {/* <div className="form-group">
                          <div className="button-group-container float-right">
                            <button type="button" className="btn btn-primary" onClick={this.updateCmsPage}>
                              <span>Update</span>
                            </button>
                            <button type="button" className="btn btn-outline-primary" onClick={()=>this.props.history.push('/cmsListing')}>
                              <span>Cancel</span>
                            </button>
                          </div>
                        </div> */}
                  </div>
                </div>
              </div>
              
          </div>
          <div className='card-body button-continer text-right'>
                  <button type="button" className="btn btn-outline-secondary btn-sm" onClick={()=>this.props.history.push('/emailcontentlist')}> <span>Cancel</span> </button>   
                  <button type="button" className="btn btn-primary btn-large" onClick={this.updateEmailContent}> <span>Update</span> </button>   
              </div>
        </form>
        {this.state.isLoader == true ? <div className='loader-block'>
            <img src="/assets/images/loader2.gif" />
        </div> : null }
      </Home>
      );
    }
}
 
const mapStateToProps = state => ({
  permissionsList: state.admindata.admindata.rolepermissions
});
export default connect( mapStateToProps, actions )(EditEmail)

