import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Home from "../Home";
import * as actions from "../../actions";
import { IMAGE_URL } from "../../config/configs";
//import ItemImages from "./ItemImages";
import CustomerInfo from "./CustomerInfo";
import OwnerInfo from "./OwnerInfo";
import AgentInfo from "./AgentInfo";
import LeadUpdates from "./LeadUpdates";

import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

class LeadDetails extends Component {
  state = {
    isLoader: false,
    leadsDetails: {},
    lead_id: "",
    collected_commission: "",
    lead_comment: "",
    comment_status: "",
    photoIndex: 0,
    isOpen: false,
    errors: {
      collected_commission: "",
      lead_comment: "",
      comment_status: ""
    }
  };

  componentDidMount() {
    let permissions = this.props.permissionsList;
    if (permissions !== "Admin") {
      this.props.history.push("/dashboard");
    }

    let lead_id = this.props.match.params.lead_id;
    this.setState({ lead_id: lead_id }, () => this.getDetails());
  }

  getDetails = () => {
    let { lead_id } = this.state;
    if (lead_id) {
      let url = "admin/lead-details";
      let method = "post";
      var body = { lead_id };
      var token = localStorage.getItem("token");
      this.setState({ isLoader: true });
      this.props.commonApiCall(
        url,
        method,
        body,
        token,
        null,
        this.props,
        response => {
          let responsedata = response.data;
          let data = response.data.data;
          this.setState({ isLoader: false });
          if (responsedata.status == 0) {
            this.setState({ leadsDetails: [] });
          } else {
            this.setState({
              leadsDetails: data
            });
          }
        }
      );
    } else {
      return false;
    }
  };

  handleChange = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
    if (value) {
      this.setState({
        errors: Object.assign(this.state.errors, { [name]: "" })
      });
    }
  };

  addLeadUpdate = e => {
    e.preventDefault();
    if (this.validateForm()) {
      let {
        collected_commission,
        lead_comment,
        comment_status,
        lead_id
      } = this.state;
      let body = {
        lead_id,
        collected_commission,
        lead_comment,
        comment_status
      };

      this.setState({ isLoader: true });
      this.props.addLeadUpdate(body, response => {
        let responseData = response.data;
        this.setState({ isLoader: false });
        if (responseData.status == 1) {
          swal({
            title: responseData.message,
            icon: "success"
          }).then(willAdd => {
            if (willAdd)
              this.setState(
                {
                  collected_commission: "",
                  lead_comment: "",
                  comment_status: ""
                },
                () => this.getDetails()
              );
          });
        } else {
          toast.error(responseData.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      });
    } else {
      return false;
    }
  };

  validateForm = () => {
    let {
      collected_commission,
      lead_comment,
      comment_status,
      errors
    } = this.state;
    let formIsValid = true;

    // if (!collected_commission) {
    //   formIsValid = false;
    //   errors["collected_commission"] = "* Commission is required!";
    // }
    if (!lead_comment) {
      formIsValid = false;
      errors["lead_comment"] = "* Comment is required!";
    }
    if (!comment_status) {
      formIsValid = false;
      errors["comment_status"] = "* Status is required!";
    }

    this.setState({ errors });
    return formIsValid;
  };

  renderImages = () => {
    let { leadsDetails } = this.state;
    let images = leadsDetails.item_info.images;
    let photoIndex = -1;
    return images.map((imageSrc, i) => {
      photoIndex++;
      const privateKey = i;
      let filesplit = imageSrc.image.split(".");
      let thumbimage = filesplit[0] + "_thumb." + filesplit[1];
      if (i > 4) {
        return (
          <figure className="property last-image" key={i}>
            <img
              src={IMAGE_URL + thumbimage}
              alt="Property Image"
              width="400"
              height="300"
              onError={this.addDefaultSrcForProperty}
            />
            <div
              className="overlay-wrap"
              onClick={() => this.setState({ isOpen: true })}
            >
              <span>+ View More</span>
            </div>
          </figure>
        );
        return true;
      } else {
        return (
          <figure className="property" key={i}>
            <img
              src={IMAGE_URL + thumbimage}
              alt="Property Image"
              width="400"
              height="300"
              onError={this.addDefaultSrcForProperty}
              onClick={() =>
                this.setState({ photoIndex: privateKey, isOpen: true })
              }
            />
          </figure>
        );
      }
    });
  };

  addDefaultSrc(ev) {
    ev.target.src = "/assets/images/no-image-user.png";
  }

  addDefaultSrcForProperty(ev) {
    ev.target.src = "/assets/images/no-image-user.png";
  }

  render() {
    let {
      leadsDetails,
      collected_commission,
      lead_comment,
      comment_status,
      photoIndex,
      isOpen,
      errors
    } = this.state;
    let {
      latest_updates,
      item_info,
      owner_info,
      customer_info,
      agent_info
    } = leadsDetails;
    let {
      item_name,
      address,
      description,
      item_price,
      images: item_images,
      attributes,
      available_for
    } = item_info || {};

    console.log(attributes, "attributes");
    return (
      <Home>
        <div className="row">
          <div className="col-md-12">
            <div className="page-header border-bottom">
              <h3>Lead Details</h3>
              <ul className="breadcrumb">
                <li
                  className="breadcrumb-item"
                  onClick={() => this.props.history.push("/dashboard")}
                >
                  {" "}
                  Home{" "}
                </li>
                <li
                  className="breadcrumb-item"
                  onClick={() => this.props.history.push("/leads")}
                >
                  {" "}
                  Leads Management{" "}
                </li>
                <li className="breadcrumb-item active">Lead Details </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card transparent no-shadow">
          <div className="card-body">
            <div className="white-bg border br-4 mb-30">
              <div className="mdb-lightbox">
                <div className="card-body border-bottom pb-30 pt-30">
                  <div className="available-propertyheader pb-2 d-flex flex-wrap">
                    <div className="property-details">
                      <h4>
                        {item_name}{" "}
                        <img
                          src="/assets/images/verify.png"
                          className="ml-2"
                          alt="Verify"
                          width="30"
                          height="30"
                        />
                      </h4>
                      <p>
                        <img
                          src="/assets/images/map.png"
                          className="mr-2"
                          alt="Verify"
                          width="30"
                          height="30"
                        />
                        {address}
                      </p>
                    </div>
                    <div className="property-label text-right">
                      <span className="badge badge-primary badge-pill">
                        Available for {available_for}
                      </span>
                    </div>
                  </div>

                  <div className="property-gallery mb-2">
                    {item_images ? this.renderImages() : ""}
                    {/* <div className='col-40'>
                            <figure className="property"><img src='/assets/images/img1.jpg' alt='Image' width='400' height='300' /> </figure>
                            <figure className="property"><img src='/assets/images/img2.jpg' alt='Image' width='400' height='300' /> </figure>
                          </div>
                          <div className='col-60'>
                            <figure className="property"><img src='/assets/images/img3.jpg' alt='Image' width='400' height='300' /> </figure>
                          </div>
                          
                          <figure className="property"><img src='/assets/images/img4.jpg' alt='Image' width='400' height='300' /> </figure>
                          <figure className="property"><img src='/assets/images/img5.jpg' alt='Image' width='400' height='300' /> </figure>
                          <figure className="property"><img src='/assets/images/img6.jpg' alt='Image' width='400' height='300' /> </figure>
                          <figure className="property last-image">
                            <img src='/assets/images/img7.jpg' alt='Image' width='400' height='300' /> 
                            <div className='overlay-wrap' onClick={() => this.setState({ isOpen: true })}>
                              <span>+ 10</span>
                            </div>
                          </figure>
                          <figure className="property"><img src='/assets/images/img1.jpg' alt='Image' width='400' height='300' /> </figure>
                          <figure className="property"><img src='/assets/images/img2.jpg' alt='Image' width='400' height='300' /> </figure>
                          <figure className="property"><img src='/assets/images/img3.jpg' alt='Image' width='400' height='300' /> </figure>
                          <figure className="property"><img src='/assets/images/img4.jpg' alt='Image' width='400' height='300' /> </figure> */}
                  </div>

                  <div className="property-contentblock">
                    <p>{description}</p>
                  </div>
                </div>

                <div className="card-body pt-30">
                  <div className="property-info row">
                    <div className="col-xl-4">
                      <div className="owner-info">
                        <h4 className="info-title">Customer Information</h4>
                        <CustomerInfo
                          customer_info={customer_info || {}}
                          addDefaultSrc={this.addDefaultSrc}
                        />
                      </div>
                      <div className="col-md-12">
                        <h4>Owner Information</h4>
                        <OwnerInfo
                          owner_info={owner_info || {}}
                          addDefaultSrc={this.addDefaultSrc}
                        />
                      </div>
                      <div className="col-md-12">
                        <h4>Agent Information</h4>
                        <AgentInfo
                          agent_info={agent_info || {}}
                          addDefaultSrc={this.addDefaultSrc}
                        />
                      </div>
                    </div>
                    <div className="col-xl-8 row">
                      <div className="col-md-6">
                        <div className="owner-info">
                          <h4 className="info-title">Price</h4>
                          <div className="price-block">
                            <p>
                              <span className="text-primary">
                                ${item_price}
                              </span>{" "}
                              onwards
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                          <h4>Price</h4>
                          <h5>
                            <span className="text-primary">${item_price}</span>{" "}
                            onwards
                          </h5>
                        </div> */}
                      {attributes &&
                        attributes.map((attribute, key) => (
                          <div className="col-md-6" key={key}>
                            {/* <h4>{attribute.title}</h4> */}

                            {/* 1 = House Specifications, textbox */}
                            {(attribute.type == "textbox" ||
                              attribute.type == "dropdown") && (
                              <div className="row owner-info">
                                <h4 className="info-title">
                                  {attribute.title}
                                </h4>
                                {attribute.value.map((value, key1) => (
                                  <div className="col-md-12" key={key1}>
                                    <div className=" mb-10 text-left">
                                      <label className="mb-0">
                                        {value.title}
                                      </label>
                                      <p className="text-dark">{value.value}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}

                            {(attribute.type == "checkbox" ||
                              attribute.type == "radio") && (
                              <div className="row owner-info">
                                <h4 className="info-title">
                                  {attribute.title}
                                </h4>
                                {attribute.value.map((value, key2) => {
                                  let atrval = value.value.split(",");
                                  return (
                                    <div className="col-md-12" key={key2}>
                                      {atrval.map((val, key3) => {
                                        return (
                                          <div
                                            className="custom-control custom-checkbox mb-10"
                                            key={key3}
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              disabled
                                              defaultChecked
                                              id={
                                                "customCheck" + key + "-" + key2
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={
                                                "customCheck" + key + "-" + key2
                                              }
                                            >
                                              {val}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            {attribute.type == "image" && (
                              <div className="row owner-info">
                                <h4 className="info-title">
                                  {attribute.title}
                                </h4>
                                {attribute.value.map((value, key4) => {
                                  let atrval = value.value.split(",");
                                  return (
                                    <div className="" key={key4}>
                                      {atrval.map((val, key5) => {
                                        return (
                                          <div
                                            className="menu-image mb-10"
                                            key={key5}
                                          >
                                            <img
                                              src={
                                                val
                                                  ? IMAGE_URL + val
                                                  : "assets/images/no-image-user.png"
                                              }
                                              alt={attribute.title + " Images"}
                                              onError={
                                                this.addDefaultSrcForUser
                                              }
                                              className="img-fluid"
                                              width="80"
                                              height="80"
                                            />
                                            {/* <label className='col-md-6 align-self-center text-left'>{value.title}</label>
                                                      <p>{value.value}</p> */}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            {/* {attribute.type === "textbox" && (
                              <ul className="specification-block">
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <b>{value.title}:</b>
                                    {value.value}
                                  </li>
                                ))}
                              </ul>
                            )} */}

                            {/* 8 = More Information, checkbox */}
                            {/* {attribute.type === "checkbox" && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <input type="checkbox" checked readOnly />{" "}
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            )} */}

                            {/* 7 = Cuisines, checkbox */}
                            {/* {attribute.type === "radio" && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <input type="checkbox" checked readOnly />{" "}
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            )} */}

                            {/* 6 = Opening Hours, textbox */}
                            {/* {attribute.att_set_id === 6 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <b>{value.title}:</b>
                                    {value.value}
                                  </li>
                                ))}
                              </ul>
                            )} */}

                            {/* 7 = Cuisines, checkbox */}
                            {/* {attribute.att_set_id === 7 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <input type="checkbox" checked readOnly />{" "}
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            )} */}

                            {/* 8 = More Information, checkbox */}
                            {/* {attribute.att_set_id === 8 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <input type="checkbox" checked readOnly />{" "}
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            )} */}

                            {/* 9 = Menu, image */}
                            {/* {attribute.type === "image" && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <img src={IMAGE_URL + value} width="50" />
                                  </li>
                                ))}
                              </ul>
                            )} */}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {isOpen && (
                  <Lightbox
                    mainSrc={IMAGE_URL + item_images[photoIndex].image}
                    nextSrc={
                      IMAGE_URL +
                      item_images[(photoIndex + 1) % item_images.length].image
                    }
                    prevSrc={
                      IMAGE_URL +
                      item_images[
                        (photoIndex + item_images.length - 1) %
                          item_images.length
                      ].image
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + item_images.length - 1) %
                          item_images.length
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % item_images.length
                      })
                    }
                  />
                )}
              </div>
            </div>

            <div className="card animated fadeIn mb-2 border br-4 update-block">
              <div className="card-body white-bg border-bottom form-material">
                <div className="white-bg  mb-30">
                  <h4 className="info-title">Log New Updates</h4>

                  <form>
                    <div className="row">
                      <div className="col-md-3">
                        <select
                          className="form-control mb-2 mr-sm-2"
                          id="inlineStatus"
                          name="comment_status"
                          value={comment_status}
                          onChange={this.handleChange}
                        >
                          <option value="">Select Status</option>
                          <option value="Fake">Fake</option>
                          <option value="In-Discussion">In-Discussion</option>
                          <option value="Lost">Lost</option>
                          <option value="Success">Success</option>
                        </select>
                        <span className="error-block">
                          {" "}
                          {errors.comment_status}{" "}
                        </span>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="number"
                          className="form-control mb-2 mr-sm-2"
                          id="inlineComissionAmount"
                          placeholder="Enter Comission Amount"
                          name="collected_commission"
                          value={collected_commission}
                          onChange={this.handleChange}
                        />
                        <span className="error-block">
                          {" "}
                          {errors.collected_commission}{" "}
                        </span>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          className="form-control mb-2 mr-sm-2"
                          id="inlineComments"
                          placeholder="Write Comments Here..."
                          name="lead_comment"
                          value={lead_comment}
                          onChange={this.handleChange}
                        />
                        <span className="error-block">
                          {" "}
                          {errors.lead_comment}{" "}
                        </span>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          className="btn btn-primary mb-2 btn-block"
                          onClick={this.addLeadUpdate}
                        >
                          Update Communication
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* <div className="card-header">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mb-4">Log New Updates</h4>
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <select
                            className="form-control mb-2 mr-sm-2"
                            id="inlineStatus"
                            name="comment_status"
                            value={comment_status}
                            onChange={this.handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="Fake">Fake</option>
                            <option value="In-Discussion">In-Discussion</option>
                            <option value="Lost">Lost</option>
                            <option value="Success">Success</option>
                          </select>
                          <span className="error-block">
                            {" "}
                            {errors.comment_status}{" "}
                          </span>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="number"
                            className="form-control mb-2 mr-sm-2"
                            id="inlineComissionAmount"
                            placeholder="Enter Comission Amount"
                            name="collected_commission"
                            value={collected_commission}
                            onChange={this.handleChange}
                          />
                          <span className="error-block">
                            {" "}
                            {errors.collected_commission}{" "}
                          </span>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control mb-2 mr-sm-2"
                            id="inlineComments"
                            placeholder="Write Comments Here..."
                            name="lead_comment"
                            value={lead_comment}
                            onChange={this.handleChange}
                          />
                          <span className="error-block">
                            {" "}
                            {errors.lead_comment}{" "}
                          </span>
                        </div>
                        <div className="col-md-3">
                          <button
                            type="button"
                            className="btn btn-primary mb-2 btn-block"
                            onClick={this.addLeadUpdate}
                          >
                            Update Communication
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
              <div className="card-body white-bg ">
                <h4 className="info-title">Latest Updates</h4>
                {latest_updates &&
                  latest_updates.map((update, key) => (
                    <LeadUpdates
                      key={key}
                      latest_updates={update}
                      addDefaultSrc={this.addDefaultSrc}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-12">
            <div className="card animated fadeIn mb-2">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    <h2>{item_name}</h2>
                    <p>{address}</p>
                  </div>
                  <div className="col-md-2 text-right">
                    <span className="badge badge-pill badge-primary ">
                      Available for booking
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ItemImages item_images={item_images} />
                <p>{description}</p>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-12">
                        <h4>Customer Information</h4>
                        <CustomerInfo customer_info={customer_info || {}} />
                      </div>
                      <div className="col-md-12">
                        <h4>Owner Information</h4>
                        <OwnerInfo owner_info={owner_info || {}} />
                      </div>
                      <div className="col-md-12">
                        <h4>Agent Information</h4>
                        <AgentInfo agent_info={agent_info || {}} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>Price</h4>
                        <h5>
                          <span className="text-primary">${item_price}</span>{" "}
                          onwards
                        </h5>
                      </div>
                      {attributes &&
                        attributes.map((attribute, key) => (
                          <div className="col-md-6" key={key}>
                            <h4>{attribute.title}</h4>

                            {/* 1 = House Specifications, textbox *
                            {attribute.att_set_id === 1 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <b>{value.title}:</b>
                                    {value.value}
                                  </li>
                                ))}
                              </ul>
                            )}

                            {/* 8 = More Information, checkbox *
                            {attribute.att_set_id === 3 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <input type="checkbox" checked readOnly />{" "}
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            )}

                            {/* 6 = Opening Hours, textbox *
                            {attribute.att_set_id === 6 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <b>{value.title}:</b>
                                    {value.value}
                                  </li>
                                ))}
                              </ul>
                            )}

                            {/* 7 = Cuisines, checkbox *
                            {attribute.att_set_id === 7 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <input type="checkbox" checked readOnly />{" "}
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            )}

                            {/* 8 = More Information, checkbox *
                            {attribute.att_set_id === 8 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <input type="checkbox" checked readOnly />{" "}
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            )}

                            {/* 9 = Menu, image *
                            {attribute.att_set_id === 9 && (
                              <ul>
                                {attribute.value.map((value, key) => (
                                  <li key={key}>
                                    <img src={IMAGE_URL + value} width="50" />
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div> */}
        {this.state.isLoader == true ? (
          <div className="loader-block">
            <img src="/assets/images/loader2.gif" />
          </div>
        ) : null}
      </Home>
    );
  }
}

const mapStateToProps = state => ({
  permissionsList: state.admindata.admindata.rolepermissions
});
export default connect(
  mapStateToProps,
  actions
)(LeadDetails);
